import React, { Suspense, useEffect, useState } from 'react'
import classes from "./VirtualSources.module.css";
import { getCamectsAPI, getSchedules, getSigFoxSensorsAPI, getVirtualRelationshipsAPI } from '../../../../../Api Methods/Api';
import { useSelector } from 'react-redux';
import VirtualSourcesGrid from '../../../../GridList/VirtualSourcesGrid';
import CamectModalForm from './Camect/CamectModalForm';
import SigfoxModalForm from './Sigfox/SigfoxModalForm';
import { Await } from 'react-router-dom';
import Oval from 'react-loading-icons/dist/esm/components/oval';
import GatewayModalForm from './Gateway/GatewayModalForm';

const VirtualSources = ({ iglooLocks, OutPutsPinNames, InputsPinNames, deviceData, setHasDeviceChanged, DeviceDetails }) => {
    let token = useSelector(state => state.auth.token);
    let client = useSelector(state => state.auth.client);
    let DeviceUUID = useSelector(state => state.auth.DeviceUUID);
    const apiData = {
        token,
        client
    }

    let fetch = true

    const [sigfoxSensors, setSigfoxSensors] = useState(null);
    const [efentoSensors, setEfentoSensors] = useState(null);
    const [gateways, setGateways] = useState(null);
    const [camects, setCamects] = useState(null);
    const [IglooLocks, setIglooLocks] = useState(iglooLocks);

    // fetchVirtualSources is called when an camect/sensor/lock is Created, Edited or Deleted.
    const fetchVirtualSources = () => {

        const getSensors = async () => {
            const response = await getVirtualRelationshipsAPI(apiData, DeviceUUID)
            setSigfoxSensors(response[0]["sigfox"])
            setEfentoSensors(response[0]["efento"])
            setGateways(response[0]["gateways"])
            console.log(response)
            // setUpdatedSigFoxSensors(response);
            // console.log("here");
        }

        const getCamects = async () => {
            return await getCamectsAPI(apiData, DeviceUUID);
            // console.log(responseData)
            // setCamects(responseData);
        }

        //setUpdatedSigFoxSensors(getSensors());
        getSensors()
        setCamects(getCamects());
    }

    // Fetch data on mount 
    useEffect(() => {
        if (fetch) {
            fetchVirtualSources();
            fetch = false;
        }

    }, [fetch]);

    useEffect(() => {
        console.log(sigfoxSensors)
        console.log(efentoSensors)
        console.log(gateways)
    }, [sigfoxSensors, efentoSensors, gateways])

    const virtualInputsArray = [1, 2, 3, 4];
    const virtualInputs = InputsPinNames.filter((input, index) => input.pin == virtualInputsArray[index]);
    const [showVirtualSource, setShowVirtualSource] = useState(null);
    const [camectDetails, setcamectDetails] = useState(null);

    //handle which camect form to show 
    const HandleSelectCamectModalForm = (AutomationID, camectDetails) => {
        if (camectDetails != null) {
            setShowVirtualSource(AutomationID);
            setcamectDetails(camectDetails)
        } else {
            setShowVirtualSource(AutomationID);
        }
    }

    return (
        <div>
            <Suspense fallback={
                <div className={classes["loadingSpinner-container"]}>
                    <Oval stroke="var(--Primary-Color)" height={200} width={100} speed={1.5} />
                </div>}>

                <Await resolve={Promise.all([camects, sigfoxSensors, efentoSensors, gateways])}>
                    {([loadedCamects, loadedSigFoxSensors, loadedEfentoSensors, loadedGateways]) => (
                        <VirtualSourcesGrid
                            Camects={loadedCamects}
                            sigfoxSensors={loadedSigFoxSensors}
                            efentoSensors={loadedEfentoSensors}
                            gateways={loadedGateways}
                            iglooLocks={IglooLocks}
                            OutPutsPinNames={OutPutsPinNames}
                            InputsPinNames={InputsPinNames}
                            fetchVirtualSources={fetchVirtualSources}
                            HandleSelectCamectModalForm={HandleSelectCamectModalForm}
                            setShowVirtualSource={setShowVirtualSource}
                            firmware={DeviceDetails.firmware}
                            camectIntegrationType={DeviceDetails.camectIntegrationType}
                        />
                    )}
                </Await>
            </Suspense>
            {showVirtualSource == 0 && <CamectModalForm setShowVirtualSource={setShowVirtualSource} title={"Add"} subTitle={"What are the camect Details"} placeholders={{ placeholder1: "IP/Domain Name...", placeholder2: "Enter Password" }} fetchVirtualSources={fetchVirtualSources} />}
            {showVirtualSource == 3 && <CamectModalForm setShowVirtualSource={setShowVirtualSource} title={"Edit"} subTitle={"What are the camect Details"} placeholders={{ placeholder1: "IP/Domain Name...", placeholder2: "Enter Password" }} camect={camectDetails} fetchVirtualSources={fetchVirtualSources} />}

            {showVirtualSource == 1 && <SigfoxModalForm setShowVirtualSource={setShowVirtualSource} inputs={virtualInputs} virtualType={"SIGFOX RE:SURE"} fetchVirtualSources={fetchVirtualSources} />}
            {showVirtualSource == 4 && <SigfoxModalForm setShowVirtualSource={setShowVirtualSource} inputs={virtualInputs} virtualType={"IOTA"} fetchVirtualSources={fetchVirtualSources} />}
            {showVirtualSource == 5 && <GatewayModalForm setShowVirtualSource={setShowVirtualSource} inputs={virtualInputs} virtualType={"Gateway"} fetchVirtualSources={fetchVirtualSources} />}

        </div>
    )
}

export default VirtualSources;
