import { useState, useEffect, useRef } from "react";
import styles from "../../../ResetPin/resetPin.module.css"; // Import CSS module
import { useDispatch } from "react-redux";
import knowioLogo from '../../../assets/knowio-logo.png'
import parfasLogo from '../../../assets/parfas-logo.png'
import portalLogo from '../../../assets/portal-logo.png'
import magnumLogo from '../../../assets/magnum-logo.png'
import resureLogo from '../../../assets/resure-logo.png'
import { useNavigate, useSearchParams } from "react-router-dom";
import hidePass from '../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/hidePass.png'
import showPass from '../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/showPass.png'
import {changePortalPassword} from '../../../Api Methods/Api'

const ResetPortalPassword = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setConfirmShowPassword] = useState(false)
    const id = searchParams.get("id");
    const appNameToPass = searchParams.get("appName")
    let appName = searchParams.get("appName");

    if (appName == "RE:SURE"){
        appName = "RESURE"
    }
    if (appName == "Magnum Security"){
        appName = "Magnum"
    }

    let logoMapping = [
        knowioLogo, 
        resureLogo, 
        parfasLogo, 
        magnumLogo, 
        portalLogo
    ]

    let icon;

    switch (appName){
        case "RESURE":
            icon = logoMapping[1]
        break;
        case "KNOWIO":
            icon = logoMapping[0]
        break;
        case "Magnum":
            icon = logoMapping[3]
        break;
        case "PORTAL_SECURITY":
            icon = logoMapping[4]
        break;
        case "PARFAS":
            icon = logoMapping[2]
        break;
    }

    
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        e.preventDefault();
       
        if (password !== confirmPassword) {
            setMessage("Passwords do not match.");
            return;
        }

        setLoading(true);

        try {
            const body = {
                userID: id,
                password: password
            }

            const response = await changePortalPassword(body)
            if (response.data.success){
                navigate(`/resetPortalPasswordSuccess?appName=${appNameToPass}`)
            } else {
                setMessage("Error resetting password, please try again")
                setLoading(false)
            }
        } catch (error) {
            setMessage("Network error, please check your connection & try again. Error: " + error)
        }
        
        setLoading(false);
    };

    return (
        <div className={`${styles[appName]} ${styles.container}`}>
           
            <div className={`${styles.card} ${styles[appName]}`}>
            <img style={{height: "70px", width:"70px", borderRadius:"10px"}} src={icon}/>
            <h1 className={`${styles.title} ${styles[appName]}`}>Reset password</h1>

            {message && <p className={styles.errorMessage}>{message}</p>}

            <p className={`${styles.subTitle} ${styles[appName]}`}>Create a new Portal password below</p>
            

            <div style={{ position: "relative", display: "flex", alignItems: "center" }}>
                <input
                    type={showPassword ? "text" : "password"}
                    className={`${styles.inputField} ${styles[appName]}`}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    required
                    style={{ width: "100%", paddingRight: "40px" }} // Ensure space for the icon
                />
                <span
                    onClick={() => setShowPassword(!showPassword)}
                    style={{
                        position: "absolute",
                        right: "10px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                    }}
                >
                    <img 
                        src={showPassword ? hidePass : showPass} 
                        alt="Toggle Password Visibility" 
                        style={{ width: "20px", height: "20px" }} // Explicitly set size
                    />
                </span>
            </div>

            <div style={{ position: "relative", display: "flex", alignItems: "center" }}>
                <input
                    type={showConfirmPassword ? "text" : "password"}
                    className={`${styles.inputField} ${styles[appName]}`}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm password"
                    required
                    style={{ width: "100%", paddingRight: "40px" }} // Ensure space for the icon
                />
                <span
                    onClick={() => setConfirmShowPassword(!showConfirmPassword)}
                    style={{
                        position: "absolute",
                        right: "10px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                    }}
                >
                    <img 
                        src={showConfirmPassword ? hidePass : showPass} 
                        alt="Toggle Password Visibility" 
                        style={{ width: "20px", height: "20px" }} // Explicitly set size
                    />
                </span>
            </div>   
                
            <button disabled={loading} onClick={handleSubmit} className={`${styles.button} ${styles[appName]}`}>
                {loading ? "Resetting password..." : "Reset password"}
            </button>
        
            </div>
        </div>
    );
};

export default ResetPortalPassword;
