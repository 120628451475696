import React, { useState, useReducer, useRef, useEffect, useImperativeHandle, forwardRef } from "react";


import CustomDropDown from "../CustomDropDown/CustomDropDown";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import classes from './InviteUserForm.module.css';

import ToggleInput from "../UI/ToggleInput";
import Input from "../CustomDropDown/Input/Input";
import Button1 from "../UI/Button/Button1";
import { inviteUser } from "../../Api Methods/Api";


const extraLinks = [
    {
        LinkTextBold: "Subscribe to get release emails",
    },
]

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const emailReducer = (state, action) => {

    if (action.type === 'USER_INPUT') {
        return { value: action.val, isValid: emailRegex.test(action.val) };
    }

    if (action.type === 'INPUT_BLUR') {
        return { value: state.value, isValid: state.value.length > 0 };
    }

    if (action.type === 'INPUT_CLEAR') {
        return { value: action.val, isValid: null };
    }
    //otherwise 
    return { value: '', isValid: false };
}

// Reducer function
const userRoleReducer = (state, action) => {

    if (action.type === 'TOGGLE_DROPDOWN') {
        return { ...state, showDropDownOptions: !state.showDropDownOptions, isValid: null };
    }

    if (action.type === 'SELECT_OPTION') {
        return { ...state, selectedOption: action.payload, showDropDownOptions: false, isValid: action.val != "UserRole" };
    }

    if (action.type === 'BLUR_DROPDOWN') {
        return { ...state, showDropDownOptions: false, isValid: action.val != "UserRole" };
    }

    if (action.type === 'INPUT_CLEAR') {
        return { selectedOption: null, isValid: null };
    }
    //otherwise 
    return { state };
};


const InviteUserForm = ({ apiData, DeviceId, userID, userRole, userEmail, DeviceName, label, HandleCloseModal, HandleDeviceUserList, companyName}, props) => {

    const [formIsValid, setFormIsValid] = useState(false);

    const [emailState, dispatchEmail] = useReducer(emailReducer, {
        value: '',
        isValid: null
    });

    const [confirmEmailState, confirmDispatchEmail] = useReducer(emailReducer, {
        value: '',
        isValid: null
    });

    // UserRole Drop Down reducer  ----------------
    const [userRoleState, dispatchUserRole] = useReducer(userRoleReducer, {
        showDropDownOptions: false,
        selectedOption: '', // Initial selectedOption state
        isValid: null
    });

    const { showDropDownOptions: showUserRoleDropDownOptions, selectedOption: selectedUserRoleOption } = userRoleState;

    const emailInputRef = useRef();
    const confirmEmailInputRef = useRef();
    const userRoleDropdownRef = useRef();

    const { isValid: emailIsValid } = emailState;
    const { isValid: confirmEmailIsValid} = confirmEmailState
    const { isValid: userRoleIsValid } = userRoleState;

    useEffect(() => {
        const identifier = setTimeout(() => {
            const emailsMatch = emailState.value === confirmEmailState.value;
            setFormIsValid(emailIsValid && confirmEmailIsValid && userRoleIsValid && emailsMatch);
        }, 500);
    
        return () => {
            clearTimeout(identifier);
        };
    }, [emailIsValid, confirmEmailIsValid, userRoleIsValid, emailState.value, confirmEmailState.value]);
    

    const emailChangeHandler = (event) => {
        dispatchEmail({ type: 'USER_INPUT', val: event.target.value });
        // console.log(event.target.value)
    }

    const confirmEmailChangeHandler = (event) => {
        const value = event.target.value;
        const isValid = emailRegex.test(value) && value === emailState.value;
        confirmDispatchEmail({ type: 'USER_INPUT', val: value, isValid });
    };
    

    const validateEmailHandler = () => {
        dispatchEmail({ type: 'INPUT_BLUR' });
    }

    const validateConfirmEmailHandler = () => {
        confirmDispatchEmail({ type: 'INPUT_BLUR' });
    }

    // Drop down handlers ---------------
    const handleUserRoleDropDownOnClick = () => {
        dispatchUserRole({ type: 'TOGGLE_DROPDOWN' });
    };

    const handleUserRoleOptionChange = (option) => {
        dispatchUserRole({ type: 'SELECT_OPTION', payload: option });
    };
    const handleUserRoleBlur = () => {
        dispatchUserRole({ type: 'BLUR_DROPDOWN' });
    };

    const onClearHandler = () => {
        dispatchEmail({ type: 'INPUT_CLEAR', val: "" })
        dispatchUserRole({ type: 'INPUT_CLEAR', val: "" })
    }

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const submitHandler = (event) => {
        event.preventDefault();

        if (formIsValid) {

            const email = emailState.value;
            let userRole = userRoleState.selectedOption;

            if (userRoleState.selectedOption == "App manager"){
                userRole = "Manager"
            } else {
                userRole = "User"
            }
            

            const UserData = {
                email,
                userRole,
            };

            
            inviteUser(apiData, DeviceId, userID, userRole, email, DeviceName, companyName, dispatch).then((responseData) => {

                if (responseData.success){
                    HandleCloseModal();
                    HandleDeviceUserList();
                }
            })

            onClearHandler();

            return;

        } else if (!emailIsValid) {
            emailInputRef.current.focus();
        } else if (!confirmEmailIsValid){
            confirmEmailInputRef.current.focus();
        } else if (!userRoleIsValid) {
            userRoleDropdownRef.current.focus();
        }
    };

    return (
        <form onSubmit={submitHandler} className={classes["container"]} method="dialog" >
            <ul className={classes["inputs-list"]}>
                <div className={classes.label}>{label}</div>

                <li className={classes["input-container"]}>
                    <Input
                        className={classes.input}
                        placeholder={"Enter user's email address"}
                        ref={emailInputRef}
                        id="Email"
                        type={"text"}
                        isValid={emailState.isValid}
                        value={emailState.value}
                        onChange={emailChangeHandler}
                        onBlur={validateEmailHandler}
                    />

                    <Input
                        className={classes.input}
                        placeholder={"Confirm user email address"}
                        ref={confirmEmailInputRef}
                        id="ConfirmEmail"
                        type={"text"}
                        isValid={confirmEmailState.isValid}
                        value={confirmEmailState.value}
                        onChange={confirmEmailChangeHandler}
                        onBlur={validateConfirmEmailHandler}
                    />

                    <CustomDropDown
                        placeholder={"Select Role"}
                        options={['App standard user', 'App manager']}
                        selectedOption={selectedUserRoleOption}
                        handleOptionChange={handleUserRoleOptionChange}
                        handleBlur={handleUserRoleBlur}
                        handleDropDownOnClick={handleUserRoleDropDownOnClick}
                        showDropDownOptions={showUserRoleDropDownOptions}
                        isValid={userRoleIsValid}
                        ref={userRoleDropdownRef}
                    />
                </li>

                <div className={classes["button-container"]}>
                    <Button1 className={classes.submitButton} type="submit">Invite</Button1>
                </div>
            </ul>

        </form>


    )


}

export default InviteUserForm;

