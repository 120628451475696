import React, { Suspense, useMemo, useState, useEffect, useRef } from 'react'
import { addSitesToGroup, getGroupSites, getGroupStats } from '../../Api Methods/Api';
import { Await, defer, Outlet, useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classes from './GroupSites.module.css'
import FilterUsersDeviceSearch from '../../components/PowerSearch/FilterUsersDeviceSearch';
import Oval from 'react-loading-icons/dist/esm/components/oval';
import DevicesGrid from '../../components/GridList/DevicesGrid';
import DataManager from '../../hooks/DataManager';
import Button1 from '../../components/UI/Button/Button1';
import Modal from '../../components/Modal/Modal';
import DropDownForm from '../../components/forms/DropdownCreateGroup';
import DevicesList from '../../components/GridList/DevicesList';
import StatsContainer from './StatsContainer';
import Stats2 from '../../components/UI/Stats/Stats2';
import check from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/check.png'
import CategoryFilter from '../../components/PowerSearch/CategoryFilter';
import { filter } from 'rxjs';
import DeviceLoadingGrid from '../../components/GridList/DeviceLoadingGrid';

const GroupSites = () => {

    const [groupSites, setGroupSites] = useState([]);
    const [originalGroupSites, setOriginalGroupSites] = useState([]);
    const [groupStats, setGroupStats] = useState();
    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const [searchQuery, setSearchQuery] = useState("");
    const [isloading, setIsLoading] = useState(false);
    const [groupMode, setGroupMode] = useState(false);
    const dataManager = new DataManager();
    const groupName = dataManager.getItem('groupClicked');
    const groupPath = dataManager.getItem('groupPath');
    const groupPathToDisplay = groupPath.replace(/>/g, '  |  ');
    const groupID = dataManager.getItem('groupClickedID');
    const [showLoading, setShowLoading] = useState(true)
    const navigate = useNavigate();
    const apiData = {
        token,
        client
    }
    const dispatch = useDispatch();

    const [buttonText, setButtonText] = useState('Remove sites')
    const checkedItems = useState(dataManager.selectedSites.value || []);
    const [selectedGroup, setSelectedGroup] = useState('');

    const [modalComponent, setModalComponent] = useState();
    const [name, setName] = useState();
    const [title, setTitle] = useState('');
    const [update, setUpdate] = useState(0);
    const [categoryFilter, setCategoryFilter] = useState([])
    // const [resultCount, setResultCount] = useState(0);
    // const [groupSites, setGroupSites] = useState([]);
    // const [originalGroupSites, setOriginalGroupSites] = useState([]);
    const [resultCount, setResultCount] = useState(0);
    // const [selectAll, setSelectAll] = useState(false)

    const HandleShowModal = () => {
        ModalDialog.current.open();
    };

    const HandleCloseModal = () => {
        ModalDialog.current.close();

    };

    const successModal = () => {
        setTitle('Success')
        setModalComponent(
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <img className={classes.ModalIcon} src={check}>
            </img>
            <br></br>
            <br></br>
            <div>
                <h3 style={{ textAlign: "center", color:"#858585"}}>Action completed successfully</h3>
            </div>   
            <br></br>
            <br></br>
            <div>
              <br></br>
              <Button1 onClick={HandleCloseModal} style={{width:"100%"}}>Close</Button1>
            </div>
          </div>
        )
        HandleShowModal();
      }

    const ModalDialog = useRef();

    const location = useLocation();
    const isGroupUsersPage = location.pathname.includes('GroupUsers'); // Check if on GroupUsers page

    let data;
    let statsData;

    useEffect(() => {
        const subscription = dataManager.selectedSites.subscribe((sites) => {
            setButtonText(sites.length > 0 ? 'Choose group' : 'Cancel');
        });

        return () => subscription.unsubscribe();
    }, []);

    const HandleSetFilters = () => {
        let filteredSites = originalGroupSites.filter(device => {
            let matchesFilter = false; // Track if this device meets ANY selected criteria
    
            if (categoryFilter.includes("Online") && device.online === "YES") {
                matchesFilter = true;
            }
    
            if (categoryFilter.includes("Offline") && device.online === "NO") {
                matchesFilter = true;
            }
    
            if (categoryFilter.includes("No site name") && (!device.tag || device.tag === "")) {
                matchesFilter = true;
            }
    
            if (categoryFilter.includes("Unused sites") &&
                device.userCount === 0 && (!device.tag || device.tag === "") && device.type === "CC") {
                matchesFilter = true;
            }
    
            if (categoryFilter.includes("Unused controllers") &&
                device.userCount === 0 && (!device.tag || device.tag === "") &&
                (device.type === "AB" || device.type === "ESP" || device.type === "A")) {
                matchesFilter = true;
            }
    
            if (categoryFilter.includes("Active sites")) {
                const fourteenDaysAgo = new Date();
                fourteenDaysAgo.setDate(fourteenDaysAgo.getDate() - 14);
    
                if (device.userCount > 0 && new Date(device.lastLoggedIn) >= fourteenDaysAgo) {
                    matchesFilter = true;
                }
            }
    
            return matchesFilter; // Include this device if it matches ANY filter
        });
    
        setGroupSites(filteredSites);
        setResultCount(filteredSites.length);
    };
    

    const HandleGetGroupSites = async () => {
        const data = await getGroupSites(apiData, groupName);
        setOriginalGroupSites(data); // Store original dataset
        setGroupSites(data); // Display data initially
        setResultCount(data.length);
        setShowLoading(false)
    };

    const HandleGetGroupStats = async () => {
            statsData = await getGroupStats(apiData, groupID)
            setGroupStats(statsData.data[0]);
    
        }
    useEffect(() => {
        HandleGetGroupSites()
        HandleGetGroupStats()
    }, []); 

    useEffect(() => {
        console.log(categoryFilter);
    
        if (categoryFilter.length === 0) {
            // Instead of refetching, reset to the original list
            setGroupSites(originalGroupSites);
            setResultCount(originalGroupSites.length);
        } else {
            HandleSetFilters();
        }
    }, [categoryFilter, originalGroupSites]); // Depend on originalGroupSites so filtering works after fetch

    const handleFilterLogic = async (data, searchQuery) => {
        const Data = await data;
        if (searchQuery === "" && (dataManager.getItem('searchQuery') !== "")){
            setGroupSites(Data)
            dataManager.setItem('searchQuery', "")
            HandleGetGroupSites()
        } else {
            const filteredResults = Data.filter(result => {
                const query = searchQuery.toLowerCase();
                const nameMatch = result.name.toLowerCase().includes(query);
                const keyMatch = result.key.toLowerCase().includes(query);
                const tagMatch = result.tag.toLowerCase().includes(query);
                return nameMatch || keyMatch || tagMatch;
            });

            setGroupSites(filteredResults)
        }
    };

    const addSites = () => {
        dataManager.setItem('isGroupMode', true);
        navigate('/AllSites/');
    }

    const transferSites = async () => {
        const groups = JSON.parse(sessionStorage.getItem('groups'));
        const group = groups.find(g => g.name === dataManager.getItem('selectedGroup'));

        if (group) {
            let body = {
                groupID: group.id,
                groupName: group.name,
                groupDevices: dataManager.selectedSites.value,
            }

            const response = await addSitesToGroup(apiData, body, dispatch);
            if (response) {
                dataManager.setItem('isGroupMode', false);
                dataManager.setSelectedSites([]);
                setGroupMode(false);
                setUpdate(update + 1);
                
                HandleCloseModal();
                successModal();
            } else {
            }
        } else {

        }
    }

    const chooseNewGroup = () => {
        const groups = JSON.parse(sessionStorage.getItem('groups'));
        const groupNames = groups.map(group => group.name);
        setTitle("Remove sites")
        setModalComponent(
            <div>
                <div>
                    <DropDownForm label={"Please select the new group below"} dropDownOptions={groupNames} setSelectedGroup={setSelectedGroup} />
                </div>
                <br></br>
                <Button1 onClick={transferSites}>Remove</Button1>
            </div>
        )

        HandleShowModal();
    }

    const editSites = () => {
        dataManager.setItem('isGroupMode', !groupMode);
        setGroupMode(!groupMode);

        if (buttonText == "Choose group") {
            //show modal for group transfer
            chooseNewGroup();
        }
    }

    useEffect(() => {
        if (groupMode) {
            if (dataManager.getItem('selectedSites').length > 0) {
                setButtonText('Choose group')
            } else {
                setButtonText('Cancel')
            }
        } else {
            setButtonText('Remove sites')
        }
    }, [groupMode, checkedItems])

    const viewUsers = () => {
        navigate('/AllSites/Groups/GroupSites/GroupUsers/');
    }
    
    return (
        <>
            <Modal ref={ModalDialog} title={title} modalName={""} HandleShowModal={HandleShowModal}>
                {modalComponent}
            </Modal>

            <div className={classes['activtyPage_container']}>
                <div style={{ marginLeft: "3px", color: "#7f7f7f" }}>
                    <h2>{groupName}</h2>
                    <p>{groupPathToDisplay}</p>
                </div>

            <Await resolve={groupStats}>
                {(loadedStats) => (
                   <div className={classes.container}>
                        {groupStats && <Stats2 title={"Total sites"} value={loadedStats.sitesCount} isLoadingStats={isloading} isStatic={true} />}
                        {groupStats && <Stats2 title={"Total offline controllers"} value={loadedStats.offlineCount} isLoadingStats={isloading} isStatic={true}/> } 
                        {groupStats && <Stats2 title={"Total portal users"} value={loadedStats.userCount} isLoadingStats={isloading} isStatic={true}/> }
                        {groupStats && <Stats2 title={"Total padlocks"} value={loadedStats.lockCount} isLoadingStats={isloading} isStatic={true}/> }
                        {groupStats && <Stats2 title={"Total keyboxes"} value={loadedStats.keyboxCount} isLoadingStats={isloading} isStatic={true}/> }
                        {groupStats && <Stats2 title={"Total sensors"} value={loadedStats.sensorCount} isLoadingStats={isloading} isStatic={true}/> }
                        {groupStats && <Stats2 title={"Total groups"} value={loadedStats.subGroupCount} isLoadingStats={isloading} isStatic={true}/> }
                   </div>
                )}
            </Await>
                

                {!isGroupUsersPage && (
                    <>
                        {groupSites && <FilterUsersDeviceSearch Devices={groupSites} searchQuery={searchQuery} setSearchQuery={setSearchQuery} placeholder={"Search Sites..."} setIsLoading={setIsLoading} setFilteredResults={setGroupSites} handleFilterLogic={handleFilterLogic} categories={["Online", "Offline", "Notifications On", "Notifications Off", "Favorited", "Not Favorited"]} />}

                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", marginBottom: "0px", marginTop: "0px", gap: "10px" }}>
                            <div style={{ display: "flex", gap: "10px" }}>
                                <Button1 style={{ height: "50px", width: "110px", fontSize: "16px" }} onClick={addSites}>Add sites</Button1>
                                <Button1 style={{ height: "50px", width: "160px", fontSize: "16px" }} onClick={editSites}>{buttonText}</Button1>
                                <Button1 style={{ height: "50px", width: "180px", fontSize: "16px" }} onClick={viewUsers}>View portal users</Button1>
                                <CategoryFilter setCategoryFilter={setCategoryFilter} categories={["Online", "Offline", "No site name", "Unused sites", "Unused controllers", "Active sites"]} categoryFilter={categoryFilter} />

                            </div>
                            
                            {/* <div className={classes.filters}>
                            </div> */}
                            
                        </div>

                        <p>Total results: {showLoading ?"Please wait..." : resultCount}</p>

                        {/* {groupMode && (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: "10px", marginBottom: "10px" }}>
                                <input
                                type="checkbox"
                                id={`checkbox`}
                                className={classes["circular-checkbox"]}
                                onChange={() => HandleSelectAll()}
                                />
                                <label
                                style={{
                                    marginRight: "10px", 
                                    marginLeft: "10px", // adjust left margin to make it consistent
                                    display: 'flex', // ensures the label and text are aligned correctly
                                    alignItems: 'center'
                                }}
                                htmlFor={`checkbox`}
                                className={classes['custom-checkbox']}
                                />
                                <span>Select all</span>
                            </div>
                            </div>
                        )} */}


                        <Suspense fallback={
                            <div className={classes["loadingSpinner-container"]}>
                                <Oval stroke="var(--Primary-Color)" height={200} width={100} speed={1.5} />
                            </div>}>

                            {/* <Await resolve={groupSites}>
                                {(loadedUserDevices) => (
                                    <div style={{ display: "flex", width: "100%" }}>
                                        {loadedUserDevices ? (
                                            loadedUserDevices.length > 0 ? (
                                                <DevicesList
                                                    devices={loadedUserDevices}
                                                    filtering=""
                                                    pageFetchedIndex={1}
                                                    isUsersSites={false}
                                                    groupMode={groupMode}
                                                    update={update}
                                                    setUpdate={setUpdate}
                                                    hideControls={true}
                                                    isSortable={true}
                                                />
                                            ) : (
                                                <p>No sites found.</p>
                                            )
                                        ) : (
                                            <p>Loading sites...</p>
                                        )}
                                    </div>
                                )}
                            </Await> */}

                            <Await resolve={groupSites}>
                                {(loadedGroupSites) => {
                                    return (
                                        <>
                                            <div style={{ display: "flex", width: "100%" }}>
                                                { showLoading ? (
                                                    <div style={{ display: "flex", width: "100%" }}>
                                                        <DeviceLoadingGrid
                                                        CardCount={20}/>
                                                    </div>
                                                ) : (
                                                    <DevicesList
                                                    devices={loadedGroupSites}
                                                    filtering=""
                                                    pageFetchedIndex={1}
                                                    isUsersSites={false}
                                                    groupMode={groupMode}
                                                    update={update}
                                                    setUpdate={setUpdate}
                                                    hideControls={true}
                                                    isSortable={true}
                                                />
                                                )}
                                            </div>
                                        </>
                                    )
                                }}
                            </Await>



                        </Suspense>
                    </>
                )}
                <Outlet />
            </div>
        </>
    )
}

export default GroupSites

export async function loader() {
    const token = sessionStorage.getItem('token');
    const client = sessionStorage.getItem('client');
    const apiData = {
        token,
        client
    }

    return defer({
        groupSites: getGroupSites(apiData, "EyeSpyFX")
    })
}