import React, { Children, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChangeoutputPinState } from "../../../../../../store/service-actions"
import Card from "../../../../Cards/Card";
import { socket } from "../../../../../../Socket/socket";
import useSocketManager from "../../../../../../Socket/useSocketManager";
import classes from "./PinOutput.module.css";
import InactiveIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/Pin/default - Off.svg";
import HiddenIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/HiddenPin.svg";
import pulseIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/Pin/__Switch - Pulse.svg";
import LockIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Locked.svg"
import managerOnly from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/PinIcon-Manager/crown_grey.png"

import { GenerateLockOTPAPI, switchLatch } from "../../../../../../Api Methods/Api";
import Battery from "../../../../../Battery/Battery";
import UseDateFormat from "../../../../../hooks/UseDateFormat";
import UseParseYearMonthDate from "../../../../../hooks/UseParseYearMonthDate";
import DataManager from "../../../../../../hooks/DataManager";
import Modal from "../../../../../Modal/Modal";
import GenerateLockOnTimePassCodeForm from "../../../../Cards/Automations/EditAutomations/GenerateLockOnTimePassCodeForm";
import ShowOTPPopup from "../../../../Cards/Automations/VirutalSources/SmartLocks/ShowOTPPopup";

const PinOutput = ({ OutPutPin, polarity, SmartLock, firmware }) => {
    const dispatch = useDispatch();
    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const userID = useSelector(state => state.auth.userID);

    const { pinName, pinStatusOff, pinStatusOn, pinState, icon, accessLevel, DeviceUUID, Pin, active, type, DeviceType } = OutPutPin;
    const lowerCaseStringIcon = icon.toLowerCase();
    const image = lowerCaseStringIcon + " - Off";
    const otherStateImage = icon + " - On";
    const staticPath = "/assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/";
    const isManagerOnly = accessLevel === "manager" ? true : false
    const imageFromPath = `${process.env.PUBLIC_URL}${staticPath}${image}.svg`
    let otherStateImageFromPath = `${process.env.PUBLIC_URL}${staticPath}${otherStateImage}.svg`;

    const initialPinState = polarity === 1 ? pinState : !pinState;

    const [PinState, setPinState] = useState();
    const [pinStateLoading, setPinStateLoading] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);

    const ModalDialog = useRef();
    const [title, setTitle] = useState("Generate passcode")
    const [modalComponent, setModalComponent] = useState();

    const HandleShowModal = () => {
        ModalDialog.current.open();
    }

    const HandleCloseModal = () => {
        ModalDialog.current.close();
    };

    const apiData = {
        token,
        client,
    }

    useEffect(() => {
        //if external source updates the state, update PinState to reflect that
        setPinState(initialPinState);
    }, [initialPinState]);

    useEffect(() => {
        setPinStateLoading(false);
        setButtonClicked(false);
    }, [pinState])

    const GenerateOTP = async (otpName, startDate, endDate, type, lockID) => {
        console.log("here")
        const body = {
            lockID: lockID,
            startDate: startDate,
            endDate: endDate,
            type: type,
            uuid: DeviceUUID,
            otpName: otpName,
        }
        console.log(body)

        const responseData = await GenerateLockOTPAPI(apiData, body, dispatch)
        console.log(responseData)
        if (responseData && responseData.success) {
            HandleCloseModal();
            setTitle("Lock access code")
            setModalComponent(
                <ShowOTPPopup pincode={responseData.data.pin} closeModal={HandleCloseModal}/>
            )
            HandleShowModal();
        } else {

        }
    }


    const HandlePinClick = async (smartLock) => {
        if (smartLock == null){
            setPinStateLoading(true);
            setButtonClicked(true);

            let body = {};
            const firmwareMajor = firmware.split('.')[0];
            const firmwareMinor = firmware.split('.')[1];

            if (socket.connected){
                console.log(socket.connected)
                if (firmwareMajor > 1 || (firmwareMajor == 1 && firmwareMinor >= 7)){
                    body["cmd"] = "setVirtualState"
                } else {
                    body["cmd"] = "setState"
                }

                body["allPins"] = "no";
                body["uuid"] = DeviceUUID;
                body["pin"] = Pin;
                body["state"] = !PinState;
                body["user"] = userID;
                body["eventID"] = crypto.randomUUID();
                socket.emit("routeCommand", body);
            } else {
                const response = await switchLatch(apiData, userID, Pin, !PinState, DeviceUUID, type, DeviceType, dispatch)
                if (response) {
                    setPinState(!PinState);
                    setPinStateLoading(false);
                    setButtonClicked(false);
                } else {
                    setPinState(PinState);
                    setButtonClicked(false);
                }
            }
        } else {
        
            setModalComponent(
                <GenerateLockOnTimePassCodeForm SmartLock={smartLock} GenerateOTP={GenerateOTP}/>
            )
            HandleShowModal()
        }
    }

    let LockReadDate;
    if (SmartLock != null) {
        const batteryReadAt = SmartLock && SmartLock.batteryReadAt
        LockReadDate = UseParseYearMonthDate(batteryReadAt);
    }

    const createPasscode = () => {
        console.log("called")
    }

    return (
        <>
        <Modal ref={ModalDialog} title={title} modalName={""} HandleShowModal={HandleShowModal}>
            {modalComponent}
        </Modal>

        <button className={`${classes["pin-switch"]} ${!active && classes["disabled-hover"]}`} style={{ opacity: !active && "50%", backgroundColor: SmartLock != null && "var(--PinLockColor)" }} disabled={buttonClicked} onClick={() => HandlePinClick(SmartLock)}>
            {!active &&
                <div className={classes["overlay"]}>
                    {/* <div className={classes.line} />
                    <div className={classes.line2} />
                    <img className={classes.HiddenIcon} src={HiddenIcon}></img> */}
                </div>}

            {/* {SmartLock != null && <div className={classes["smart-lockOverlay"]} />} */}

            <div className={classes.top}>
                <h3>{pinName}</h3>
                {SmartLock == null ? <h3 className={!pinStateLoading ? active ? PinState ? classes.On : classes.Off : classes.inactive : classes.loading}>
                    {active ? PinState ? pinStatusOff : pinStatusOn : "Inactive"}
                </h3> :
                    <h2 className={classes["battery-percentage"]}> {LockReadDate}</h2>
                }

            </div>

            {SmartLock != null ?
                <div className={classes.bottom} onClick={createPasscode}>
                    <img className={classes.LockIcon} src={LockIcon} />
                    {isManagerOnly && <img className={classes.imgManager} src={managerOnly} />}
                    <div className={classes.batteryIconContainer}>
                        <Battery BatteryPowerLevel={SmartLock.battery} isActive={active} />
                        <h2 className={classes["battery-percentage"]}> {SmartLock.battery + "%"}</h2>
                    </div>
                </div>
                :
                <div className={classes.bottom}>
                    <img className={classes.img} src={active ? PinState ? imageFromPath : otherStateImageFromPath : InactiveIcon} />
                    {isManagerOnly && <img className={classes.imgManager} src={managerOnly} />}
                </div>
            }
        </button>
        </>
    );
}

export default PinOutput;