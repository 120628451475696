import React, { useState, useReducer, useRef, useEffect, useImperativeHandle, forwardRef } from "react";


import CustomDropDown from "../CustomDropDown/CustomDropDown";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import classes from './InviteUserForm.module.css';

import ToggleInput from "../UI/ToggleInput";
import Input from "../CustomDropDown/Input/Input";
import Button1 from "../UI/Button/Button1";
import { inviteUser } from "../../Api Methods/Api";
import DataManager from "../../hooks/DataManager";


const userRoleReducer = (state, action) => {

    if (action.type === 'TOGGLE_DROPDOWN') {
        return { ...state, showDropDownOptions: !state.showDropDownOptions, isValid: null };
    }

    if (action.type === 'SELECT_OPTION') {
        return { ...state, selectedOption: action.payload, showDropDownOptions: false, isValid: action.val != "UserRole" };
    }

    if (action.type === 'BLUR_DROPDOWN') {
        return { ...state, showDropDownOptions: false, isValid: action.val != "UserRole" };
    }

    if (action.type === 'INPUT_CLEAR') {
        return { selectedOption: null, isValid: null };
    }
    //otherwise 
    return { state };
};


const SelectRoleForm = ({setUserRole, HandleCloseModal, HandleShowLoadingModal, HandleAddUserSites}) => {

    const [formIsValid, setFormIsValid] = useState(false);
    const dataManager = new DataManager();

    // UserRole Drop Down reducer  ----------------
    const [userRoleState, dispatchUserRole] = useReducer(userRoleReducer, {
        showDropDownOptions: false,
        selectedOption: '', // Initial selectedOption state
        isValid: null
    });

    const { showDropDownOptions: showUserRoleDropDownOptions, selectedOption: selectedUserRoleOption } = userRoleState;

    const userRoleDropdownRef = useRef();

    const { isValid: userRoleIsValid } = userRoleState;

    useEffect(() => {
        const identifier = setTimeout(() => {
            // console.log('Checking form validity!');
            setFormIsValid(userRoleIsValid)
        }, 500);

        return () => {
            // console.log('CLEANUP');
            clearTimeout(identifier);
        };
    }, [userRoleIsValid]);


    // Drop down handlers ---------------
    const handleUserRoleDropDownOnClick = () => {
        dispatchUserRole({ type: 'TOGGLE_DROPDOWN' });
    };

    const handleUserRoleOptionChange = (option) => {
        dispatchUserRole({ type: 'SELECT_OPTION', payload: option });
    };
    const handleUserRoleBlur = () => {
        dispatchUserRole({ type: 'BLUR_DROPDOWN' });
    };

    const onClearHandler = () => {
        dispatchUserRole({ type: 'INPUT_CLEAR', val: "" })
    }

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const submitHandler = (event) => {
        event.preventDefault();

        if (formIsValid) {

            let userRole = userRoleState.selectedOption;
            if (userRoleState.selectedOption == "App manager"){
                userRole = "Manager"
            } else {
                userRole = "User"
            }

            setUserRole(userRole);
            dataManager.setItem('roleSelectedForUserInvite', userRole)
            HandleCloseModal();
            HandleShowLoadingModal();
            HandleAddUserSites();
            onClearHandler();
            return;
        } 
    };

    return (
        <form onSubmit={submitHandler} className={classes["container"]} method="dialog" >
            <ul className={classes["inputs-list"]}>
                <li className={classes["input-container"]}>
                    <CustomDropDown
                        placeholder={"Select Role"}
                        options={['App standard user', 'App manager']}
                        selectedOption={selectedUserRoleOption}
                        handleOptionChange={handleUserRoleOptionChange}
                        handleBlur={handleUserRoleBlur}
                        handleDropDownOnClick={handleUserRoleDropDownOnClick}
                        showDropDownOptions={showUserRoleDropDownOptions}
                        isValid={userRoleIsValid}
                        ref={userRoleDropdownRef}
                    />
                </li>

                
            </ul>
            <br></br>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <Button1 className={classes.submitButton} type="submit">Add to selected sites</Button1>
                </div>
        </form>


    )


}

export default SelectRoleForm;

