import React, { useContext } from "react";
import { VersionContext } from "./VersionContext";

const UpdateBanner = () => {
    const { newVersionAvailable } = useContext(VersionContext);

    if (!newVersionAvailable) return null;

    const refreshPage = () => {
        window.location.reload(true); // Force refresh
    };

    return (
        <div style={{ position: "fixed", bottom: 0, width: "100%", background: "red", color: "white", padding: "10px", textAlign: "center", zIndex: 1000 }}>
            A new version is available. <button onClick={refreshPage} style={{ marginLeft: "10px", padding: "5px 10px", background: "white", color: "red", border: "none", cursor: "pointer" }}>Refresh</button>
        </div>
    );
};

export default UpdateBanner;