import { useState, useEffect, useRef } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import styles from "../ResetPin/resetPin.module.css"; // Import CSS module
import { acceptInvite, createNewPin, removeUser } from "../Api Methods/Api";
import { useDispatch } from "react-redux";
import knowioLogo from '../assets/knowio-logo.png'
import parfasLogo from '../assets/parfas-logo.png'
import portalLogo from '../assets/portal-logo.png'
import magnumLogo from '../assets/magnum-logo.png'
import resureLogo from '../assets/resure-logo.png'

const Register = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const token = searchParams.get("token");
    let appName = searchParams.get("appName");
    if (appName == "RE:SURE"){
        appName = "RESURE"
    }
    if (appName == "Magnum Security"){
        appName = "Magnum"
    }

    let logoMapping = [
        knowioLogo, 
        resureLogo, 
        parfasLogo, 
        magnumLogo, 
        portalLogo
    ]

    let icon;

    switch (appName){
        case "RESURE":
            icon = logoMapping[1]
        break;
        case "KNOWIO":
            icon = logoMapping[0]
        break;
        case "Magnum":
            icon = logoMapping[3]
        break;
        case "PORTAL_SECURITY":
            icon = logoMapping[4]
        break;
        case "PARFAS":
            icon = logoMapping[2]
        break;
    }

    const [name, setName] = useState("");
    const [surname, setSurname] = useState("")
    const [pin, setPin] = useState(["", "", "", ""]);
    const [confirmPin, setConfirmPin] = useState(["", "", "", ""]);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    
    const pinRefs = [useRef(), useRef(), useRef(), useRef()];
    const confirmRefs = [useRef(), useRef(), useRef(), useRef()];
    const dispatch = useDispatch();

    const handleChange = (index, value, isConfirm = false) => {
        if (!/^\d?$/.test(value)) return; // Only allow one digit (0-9)

        const updatedPin = isConfirm ? [...confirmPin] : [...pin];
        updatedPin[index] = value;

        if (isConfirm) {
            setConfirmPin(updatedPin);
        } else {
            setPin(updatedPin);

            // Move focus to confirm PIN section when all 4 digits are entered
            if (updatedPin.join("").length === 4) {
                confirmRefs[0].current.focus();
            }
        }

        // Move to next field if a digit is entered (except last box)
        if (value && index < 3) {
            (isConfirm ? confirmRefs[index + 1] : pinRefs[index + 1]).current.focus();
        }
    };

    const handleKeyDown = (index, e, isConfirm = false) => {
        if (e.key === "Backspace" && !e.target.value && index > 0) {
            // Move to previous field on backspace
            (isConfirm ? confirmRefs[index - 1] : pinRefs[index - 1]).current.focus();
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newPin = pin.join("");
        const confirmedPin = confirmPin.join("");
        
        if (newPin.length !== 4 || confirmedPin.length !== 4) {
            setMessage("PIN must be exactly 4 digits.");
            return;
        }

        if (newPin !== confirmedPin) {
            setMessage("PINs do not match.");
            return;
        }

        if (name == ""){
            setMessage("Please enter your first name")
            return;
        }

        if (surname == ""){
            setMessage("Please enter your last name")
            return;
        }

        setLoading(true);

        try {
            const body = {
                token: token, 
                name: name + " " + surname, 
                pin: newPin,
            }

            const response = await acceptInvite(body)
            console.log(response)
            if (response.success){
                navigate(`/RegisterSuccess?token=${token}&appName=${appName}&userName=${name}`) //go to success screen with app download buttons
            } else {
                setMessage("Error creating account. Please contact support")
            }

        } catch (error) {
            setMessage("Network error, please check your connection & try again. Error: " + error)
        }
        
        setLoading(false);
    };

    return (
        <div className={`${styles[appName]} ${styles.container}`}>
           
            <div className={`${styles.card} ${styles[appName]}`}>
            <img style={{height: "70px", width:"70px", borderRadius:"10px"}} src={icon}/>
            <h1 className={`${styles.title} ${styles[appName]}`}>Create account</h1>

            {message && <p className={styles.errorMessage}>{message}</p>}

            <p className={`${styles.subTitle} ${styles[appName]}`}>Enter your first and last name below</p>
            

            <input
                type="text"
                className={`${styles.inputField} ${styles[appName]}`}
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="First name"
                required
            />

            <input
                type="text"
                className={`${styles.inputField} ${styles[appName]}`}
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
                placeholder="Last name"
                required
            />          
                
            <p className={`${styles.subTitle} ${styles[appName]}`}>Enter a 4-digit PIN below.</p>
                <form onSubmit={handleSubmit}>
                    {/* New PIN Input Boxes */}
                    <div className={`${styles.pinContainer} ${styles[appName]}`}>
                        {pin.map((digit, index) => (
                            <input
                                style={{ color: "white" }}
                                key={index}
                                type="text"
                                inputMode="numeric"
                                maxLength="1"
                                className={`${styles.pinInput} ${styles[appName]}`}
                                value={digit}
                                onChange={(e) => handleChange(index, e.target.value)}
                                onKeyDown={(e) => handleKeyDown(index, e)}
                                ref={pinRefs[index]}
                                
                            />
                        ))}
                    </div>

                    <p className={`${styles.subTitle} ${styles[appName]}`}>Confirm your PIN:</p>

                    {/* Confirm PIN Input Boxes */}
                    <div className={`${styles.pinContainer} ${styles[appName]}`}>
                        {confirmPin.map((digit, index) => (
                            <input
                                style={{ color: "white" }}
                                key={index}
                                type="text"
                                inputMode="numeric"
                                maxLength="1"
                                className={`${styles.pinInput} ${styles[appName]}`}
                                value={digit}
                                onChange={(e) => handleChange(index, e.target.value, true)}
                                onKeyDown={(e) => handleKeyDown(index, e, true)}
                                ref={confirmRefs[index]}
                            />
                        ))}
                    </div>

                    <button type="submit" disabled={loading} className={`${styles.button} ${styles[appName]}`}>
                        {loading ? "Creating account..." : "Create account"}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Register;
