import { useState, useEffect, useRef } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import styles from "./resetPin.module.css"; // Import CSS module
import { createNewPin } from "../Api Methods/Api";
import { useDispatch } from "react-redux";
import knowioLogo from '../assets/knowio-logo.png'
import parfasLogo from '../assets/parfas-logo.png'
import portalLogo from '../assets/portal-logo.png'
import magnumLogo from '../assets/magnum-logo.png'
import resureLogo from '../assets/resure-logo.png'

const ResetPin = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const token = searchParams.get("token");
    let appName = searchParams.get("appName");
    if (appName == "RE:SURE"){
        appName = "RESURE"
    }
    if (appName == "Magnum Security"){
        appName = "Magnum"
    }

    let logoMapping = [
        knowioLogo, 
        resureLogo, 
        parfasLogo, 
        magnumLogo, 
        portalLogo
    ]

    let icon;

    switch (appName){
        case "RESURE":
            icon = logoMapping[1]
        break;
        case "KNOWIO":
            icon = logoMapping[0]
        break;
        case "Magnum":
            icon = logoMapping[3]
        break;
        case "PORTAL_SECURITY":
            icon = logoMapping[4]
        break;
        case "PARFAS":
            icon = logoMapping[2]
        break;
    }

    const [pin, setPin] = useState(["", "", "", ""]);
    const [confirmPin, setConfirmPin] = useState(["", "", "", ""]);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    
    const pinRefs = [useRef(), useRef(), useRef(), useRef()];
    const confirmRefs = [useRef(), useRef(), useRef(), useRef()];
    const dispatch = useDispatch();

    const handleChange = (index, value, isConfirm = false) => {
        if (!/^\d?$/.test(value)) return; // Only allow one digit (0-9)

        const updatedPin = isConfirm ? [...confirmPin] : [...pin];
        updatedPin[index] = value;

        if (isConfirm) {
            setConfirmPin(updatedPin);
        } else {
            setPin(updatedPin);

            // Move focus to confirm PIN section when all 4 digits are entered
            if (updatedPin.join("").length === 4) {
                confirmRefs[0].current.focus();
            }
        }

        // Move to next field if a digit is entered (except last box)
        if (value && index < 3) {
            (isConfirm ? confirmRefs[index + 1] : pinRefs[index + 1]).current.focus();
        }
    };

    const handleKeyDown = (index, e, isConfirm = false) => {
        if (e.key === "Backspace" && !e.target.value && index > 0) {
            // Move to previous field on backspace
            (isConfirm ? confirmRefs[index - 1] : pinRefs[index - 1]).current.focus();
        }
    };

    const getIP = async () => {
        try {
            const response = await fetch("https://api64.ipify.org?format=json");
            const data = await response.json();
            console.log(data)
            console.log(data.ip)
            return data.ip; // Returns the user's public IP address
        } catch (error) {
            console.error("Error fetching IP:", error);
            return null;
        }
    }

    const getUserLocation = async (ip) => {
        console.log(ip)
        try {
            const response = await fetch(`https://ipapi.co/${ip}/json/`);
            const data = await response.json();
            const body = {
                city: data.city,
                region: data.region,
                country: data.country_name,
                ip: ip
            };

            return JSON.stringify(body)
        } catch (error) {
            console.error("Error fetching location:", error);
            return null;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newPin = pin.join("");
        const confirmedPin = confirmPin.join("");
        const ip = await getIP()
        const location = await getUserLocation(ip)

        if (newPin.length !== 4 || confirmedPin.length !== 4) {
            setMessage("PIN must be exactly 4 digits.");
            return;
        }

        if (newPin !== confirmedPin) {
            setMessage("PINs do not match.");
            return;
        }

        setLoading(true);

        try {
            let appNameToSend = appName
            if (appNameToSend == "RESURE"){
                appNameToSend = "RE:SURE"
            }
            if (appNameToSend == "Magnum"){
                appNameToSend = "Magnum Security"
            }

            const body = {
                newPin: newPin,
                token: token, 
                location: location, 
                appName: appNameToSend
            }
            const response = await createNewPin(body, dispatch)
            console.log(response)
            
            if (response.data.success) {
                console.log("here")
                navigate(`/ResetSuccess?token=${token}&appName=${appName}`)
            } else {
                console.log("here")
                setMessage(response.data.msg);
            }
        } catch (error) {
            console.log("here")
            setMessage("Network error. Please check your connection and try again.");
        }
        setLoading(false);
    };

    return (
        <div className={`${styles[appName]} ${styles.container}`}>
           
            <div className={`${styles.card} ${styles[appName]}`}>
            <img style={{height: "70px", width:"70px", borderRadius:"10px"}} src={icon}/>
                <h1 className={`${styles.title} ${styles[appName]}`}>Reset Your PIN</h1>
                <p className={`${styles.subTitle} ${styles[appName]}`}>Enter a new 4-digit PIN below.</p>
                {message && <p className={styles.errorMessage}>{message}</p>}

                <form onSubmit={handleSubmit}>
                    {/* New PIN Input Boxes */}
                    <div className={`${styles.pinContainer} ${styles[appName]}`}>
                        {pin.map((digit, index) => (
                            <input
                                style={{ color: "white" }}
                                key={index}
                                type="text"
                                inputMode="numeric"
                                maxLength="1"
                                className={`${styles.pinInput} ${styles[appName]}`}
                                value={digit}
                                onChange={(e) => handleChange(index, e.target.value)}
                                onKeyDown={(e) => handleKeyDown(index, e)}
                                ref={pinRefs[index]}
                                
                            />
                        ))}
                    </div>

                    <p className={`${styles.subTitle} ${styles[appName]}`}>Confirm your PIN:</p>

                    {/* Confirm PIN Input Boxes */}
                    <div className={`${styles.pinContainer} ${styles[appName]}`}>
                        {confirmPin.map((digit, index) => (
                            <input
                                style={{ color: "white" }}
                                key={index}
                                type="text"
                                inputMode="numeric"
                                maxLength="1"
                                className={`${styles.pinInput} ${styles[appName]}`}
                                value={digit}
                                onChange={(e) => handleChange(index, e.target.value, true)}
                                onKeyDown={(e) => handleKeyDown(index, e, true)}
                                ref={confirmRefs[index]}
                            />
                        ))}
                    </div>

                    <button type="submit" disabled={loading} className={`${styles.button} ${styles[appName]}`}>
                        {loading ? "Resetting..." : "Reset PIN"}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ResetPin;
