import React, { Children, useEffect, useState, useRef } from 'react'
import classes from "../Sigfox/SigFoxContentLayout.module.css"
import Modal from '../../../../../Modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import RemoveCamectIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/SensorIcons/trash.png"
import battery100 from '../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/SensorIcons/battery_100.png'
import battery75 from '../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/SensorIcons/battery_75.png'
import battery50 from '../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/SensorIcons/battery_50.png'
import battery25 from '../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/SensorIcons/battery_25.png'
import battery0 from '../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/SensorIcons/battery_0.png'
import signal100 from '../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/SensorIcons/signal_100.png'
import signal75 from '../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/SensorIcons/signal_75.png'
import signal50 from '../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/SensorIcons/signal_50.png'
import signal25 from '../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/SensorIcons/signal_25.png'
import signal0 from '../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/SensorIcons/signal_0.png'

const EfentoContentLayout = ({ sensor, pinName, fetchVirtualSources }) => {
    const { type, virtualInput } = sensor;
    const typeSplit = type.split(" ").pop();
    const signal = 49 //(will be sensor.signal, not currently returned) - Get from devicedetails when added
    const battery = 24 //(will be sensor.battery, not currently returned) - Get from deviceDetails when added
    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const DeviceUUID = useSelector(state => state.auth.DeviceUUID);
    const apiData = {
        token,
        client
    }
    const ModalDialog = useRef();

    const HandleShowModal = () => {
        ModalDialog.current.open();
    }

    const HandleCloseModal = () => {
        ModalDialog.current.close();
        HandelResetModal();
    }

    const [modalTitle, setModalTitle] = useState("SmartLock")
    const [modalComponent, setModalComponent] = useState()
    const [modalPageIndex, setModalPageIndex] = useState(null);
    const [showAddCamect, setShowAddCamect] = useState(false);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                // Handle the Escape key press here
                HandelResetModal();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const HandelResetModal = () => {
        setShowAddCamect(null);
        setModalPageIndex(null);
    }

    const dispatch = useDispatch();
    const DeviceDetails = useSelector(state => state.service.DeviceDetails)

    const batteryIcons = [
        battery100, 
        battery75, 
        battery50, 
        battery25, 
        battery0
    ]

    const signalIcons = [
        signal100, 
        signal75, 
        signal50, 
        signal25, 
        signal0
    ]

    let signalIcon;
    let batteryIcon;

    if (signal >= 75){
        signalIcon = signalIcons[0]
    } else if (signal >= 50 && signal < 75){
        signalIcon = signalIcons[1]
    } else if (signal >= 25 && signal < 50){
        signalIcon = signalIcons[2]
    } else if (signal > 0 && signal < 25){
        signalIcon = signalIcons[3]
    } else {
        signalIcon = signalIcons[4]
    }

    if (battery >= 75){
        batteryIcon = batteryIcons[0]
    } else if (battery >= 50 && battery < 75){
        batteryIcon = batteryIcons[1]
    } else if (battery >= 25 && battery < 50){
        batteryIcon = batteryIcons[2]
    } else if (battery > 0 && battery < 25){
        batteryIcon = batteryIcons[3]
    } else {
        batteryIcon = batteryIcons[4]
    }


    // const unlinkSigfox = async () => {
    //     const responseData = await unlinkSigfoxAPI(apiData, DeviceUUID, virtualInput, dispatch)
    //     if (responseData) {
    //         fetchVirtualSources();
    //     }
    // }

    return (
        <>
            <Modal ref={ModalDialog} title={modalTitle} HandleShowModal={HandleShowModal} HandleCloseModal={HandleCloseModal} style={{ minWidth: "600px", minHeight: "270px" }} modalLayoutStyle={{ alignItems: "center" }}>
                {modalComponent}
            </Modal>

            <div className={classes.CamectContentLayout} >
                <div className={classes["left-wrapper"]}>
                    <h3 className={classes.title}>{"Knowio Sensor"}</h3>
                    <h3 className={classes.subtitle}>{typeSplit}</h3>
                </div>

                <div className={classes["right-wrapper"]}>

                    <div className={classes["text-container"]} >
                        <h3 className={classes.pinName}>{pinName.pinName} </h3>
                    </div>

                    <div className={classes["Icons-Container"]} >
                        <img className={classes.Icon} src={batteryIcon}  />
                        <img className={classes.Icon} src={signalIcon}  />
                        <img className={classes.Icon2} src={RemoveCamectIcon} />

                    </div>
                </div>
            </div >

        </>
    )
}

export default EfentoContentLayout;




