import React, { Children, useEffect, useState, useRef } from 'react'
import classes from "./SigFoxContentLayout.module.css";
import Modal from '../../../../../Modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import RemoveCamectIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/SensorIcons/trash.png"
import { unlinkSigfoxAPI } from '../../../../../../Api Methods/Api';

const SigFoxContentLayout = ({ sensor, pinName, fetchVirtualSources }) => {
    const { type, virtualInput } = sensor;

    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const DeviceUUID = useSelector(state => state.auth.DeviceUUID);

    const apiData = {
        token,
        client
    }

    //------------------------------- Modal Logic  ----------------------------------------------
    const ModalDialog = useRef();

    // call ref to clearStates method from child component ScheduledAction.
    const ClearStatesChildComponetRef = useRef();

    const HandleShowModal = () => {
        ModalDialog.current.open();
    }

    const HandleCloseModal = () => {
        ModalDialog.current.close();

        HandelResetModal();
    }

    const [modalTitle, setModalTitle] = useState("SmartLock")
    const [modalComponent, setModalComponent] = useState()
    //--------------------------------------------------------------------------------------------
    const [modalPageIndex, setModalPageIndex] = useState(null);

    const [showAddCamect, setShowAddCamect] = useState(false);

    // reset model when Escape key is pressed
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                // Handle the Escape key press here
                HandelResetModal();
            }
        };

        // Add event listener when component mounts
        document.addEventListener('keydown', handleKeyDown);


        // Clean up event listener when component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const HandelResetModal = () => {
        setShowAddCamect(null);
        setModalPageIndex(null);
    }

    const dispatch = useDispatch();
    const DeviceDetails = useSelector(state => state.service.DeviceDetails)

    const unlinkSigfox = async () => {
        const responseData = await unlinkSigfoxAPI(apiData, DeviceUUID, virtualInput, dispatch)
        if (responseData) {
            fetchVirtualSources();
        }
    }

    return (
        <>
            <Modal ref={ModalDialog} title={modalTitle} HandleShowModal={HandleShowModal} HandleCloseModal={HandleCloseModal} style={{ minWidth: "600px", minHeight: "270px" }} modalLayoutStyle={{ alignItems: "center" }}>
                {modalComponent}
            </Modal>

            <div className={classes.CamectContentLayout} >
                <div className={classes["left-wrapper"]}>
                    <h3 className={classes.title}>{"Sigfox"}</h3>
                    <h3 className={classes.subtitle}>{type}</h3>
                </div>

                <div className={classes["right-wrapper"]}>

                    <div className={classes["text-container"]} >
                        <h3 className={classes.pinName}>{pinName.pinName} </h3>
                    </div>

                    <div className={classes["Icons-Container"]} >
                        <img className={classes.Icon} src={RemoveCamectIcon} onClick={() => unlinkSigfox(2)} />
                    </div>
                </div>
            </div >

        </>
    )
}

export default SigFoxContentLayout;




