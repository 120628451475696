import React, { Children, useEffect, useState, useRef } from 'react'

import classes from "../Sigfox/SigFoxContentLayout.module.css";
import arrowIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Page - Forward.svg";

import Modal from '../../../../../Modal/Modal';


import { useDispatch, useSelector } from 'react-redux';

import OTPIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/__Access.svg";
import RemoveCamectIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Delete - Remove.svg"
import GenerateLockOnTimePassCodeForm from '../../EditAutomations/GenerateLockOnTimePassCodeForm';
import { deleteCamectAPI, GenerateLockOTP, GenerateLockOTPAPI, removeLockAPI, unlinkGateway, unlinkSigfoxAPI, updateCamectRelationshipAPI } from '../../../../../../Api Methods/Api';

import LinkIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Camect/__Link.svg"
import EditIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Camect/EditView Black.svg"
import RefreshIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Camect/__Refresh.svg"
import Button2 from '../../../../Button/Button2';

import Button1 from '../../../../Button/Button1';

import battery100 from '../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/SensorIcons/battery_100.png'
import battery75 from '../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/SensorIcons/battery_75.png'
import battery50 from '../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/SensorIcons/battery_50.png'
import battery25 from '../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/SensorIcons/battery_100.png'
import battery0 from '../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/SensorIcons/battery_0.png'

import signal100 from '../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/SensorIcons/signal_100.png'
import signal75 from '../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/SensorIcons/signal_75.png'
import signal50 from '../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/SensorIcons/signal_50.png'
import signal25 from '../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/SensorIcons/signal_25.png'
import signal0 from '../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/SensorIcons/signal_0.png'

import trash from '../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/SensorIcons/trash.png'
import UseDateFormat from '../../../../../hooks/UseDateFormat';
import UseTimeDateFormat from '../../../../../hooks/UseTimeDateFormat';
import { format } from 'date-fns';

const GatewayContentLayout = ({ gateway, fetchVirtualSources }) => {
    console.log(gateway)
    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const DeviceUUID = useSelector(state => state.auth.DeviceUUID);
    const battery = gateway.battery
    const signal = gateway.signal
    const linked = new Date(gateway.dateLinked)
    const linkedFormatted = format(linked, "MMMM do, yyyy")

    const apiData = {
        token,
        client
    }

    const batteryIcons = [
        battery100, 
        battery75, 
        battery50, 
        battery25, 
        battery0
    ]

    const signalIcons = [
        signal100, 
        signal75, 
        signal50, 
        signal25, 
        signal0
    ]

    let signalIcon;
    let batteryIcon;

    if (signal >= 75){
        signalIcon = signalIcons[0]
    } else if (signal >= 50 && signal < 75){
        signalIcon = signalIcons[1]
    } else if (signal >= 25 && signal < 50){
        signalIcon = signalIcons[2]
    } else if (signal >= 0 && signal < 25){
        signalIcon = signalIcons[3]
    } else {
        signalIcon = signalIcons[4]
    }

    if (battery >= 75){
        batteryIcon = batteryIcons[0]
    } else if (battery >= 50 && battery < 75){
        batteryIcon = batteryIcons[1]
    } else if (battery >= 25 && battery < 50){
        batteryIcon = batteryIcons[2]
    } else if (battery >= 0 && battery < 25){
        batteryIcon = batteryIcons[3]
    } else {
        batteryIcon = batteryIcons[4]
    }

    

    //------------------------------- Modal Logic  ----------------------------------------------
    const ModalDialog = useRef();

    // call ref to clearStates method from child component ScheduledAction.
    const ClearStatesChildComponetRef = useRef();

    const HandleShowModal = () => {
        ModalDialog.current.open();
    }

    const HandleCloseModal = () => {
        ModalDialog.current.close();

        HandelResetModal();
    }

    const [modalTitle, setModalTitle] = useState("SmartLock")
    const [modalComponent, setModalComponent] = useState()
    //--------------------------------------------------------------------------------------------
    const [modalPageIndex, setModalPageIndex] = useState(null);

    const [showAddCamect, setShowAddCamect] = useState(false);

    // reset model when Escape key is pressed
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                // Handle the Escape key press here
                HandelResetModal();
            }
        };

        // Add event listener when component mounts
        document.addEventListener('keydown', handleKeyDown);


        // Clean up event listener when component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const HandelResetModal = () => {
        setShowAddCamect(null);
        setModalPageIndex(null);
    }

    const dispatch = useDispatch();
    const DeviceDetails = useSelector(state => state.service.DeviceDetails)

    const UnlinkGateway = async () => {
        const responseData = await unlinkGateway(apiData, DeviceUUID, gateway.id, dispatch)
        if (responseData) {
            fetchVirtualSources();
        }
    }

    return (
        <>
            <Modal ref={ModalDialog} title={modalTitle} HandleShowModal={HandleShowModal} HandleCloseModal={HandleCloseModal} style={{ minWidth: "600px", minHeight: "270px" }} modalLayoutStyle={{ alignItems: "center" }}>
                {modalComponent}
            </Modal>

            <div className={classes.CamectContentLayout} >
                <div className={classes["left-wrapper"]}>
                    <h3 className={classes.title}>{"Knowio Gateway"}</h3>
                    <h3 className={classes.subtitle}>{gateway.IMEI}</h3>
                </div>

                <div className={classes["right-wrapper"]}>

                <div className={classes["text-container"]} >
                        <h3 className={classes.dateLinked}>{linkedFormatted}</h3>
                    </div>

                    <div className={classes["Icons-Container"]} >
                        <img className={classes.Icon} src={batteryIcon}/>
                        <img className={classes.Icon} src={signalIcon}/>
                        <img className={classes.Icon2} src={trash} onClick={() => UnlinkGateway()}/>
                    </div>
                </div>
            </div >

        </>
    )
}

export default GatewayContentLayout;




