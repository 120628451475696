import React, { useMemo, useEffect, Suspense, useState, useRef } from 'react'
import classes from "./DevicesList.module.css";
import ListCard from './ListCard';
import BasicTable from '../Table/Table/Table';
import { Await, useLoaderData, useNavigate } from 'react-router-dom';
import { Oval } from 'react-loading-icons';
import UseDateFormat from '../hooks/UseDateFormat';
import Pill_Widget from '../UI/Widgets/Pill_Widget';
import DropdownList from '../UI/Cards/DropdownList';
import SettingsCog from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/settings.png"
import { useDispatch, useSelector } from 'react-redux';
import { addSitesToGroup, changeCamectIntegration, changeRole, clearDeviceLogs, copyIPIOSettings, copyUsersToAnotherDevice, getDevices, getGroups, getRandomUIPIOID, rebootIPIODevice, removeUser, resetIPIODevice, suspendDevice } from '../../Api Methods/Api';
import { serviceActions } from '../../store/service-Slice';
import useSetCookie from '../hooks/UseSetCookie';
import Modal from '../Modal/Modal';
import DropDownForm from '../forms/DropDownForm';
import Dropdown from '../../components/forms/DropdownCreateGroup'
import DataManager from '../../hooks/DataManager';
import GridPaginationControls from '../Table/Table/GridPaginationControls';
import OutputPinCircle from '../UI/DataLayouts/OutputPinCircle';
import InputPinCircle from '../UI/DataLayouts/InputPinCircle';
import useParseArrayDeviceData from '../hooks/useParseArrayDeviceData';
import SetTag from '../Modal/ModalLayouts/SetTag';
import Button1 from '../UI/Button/Button1';
import CopyUsersPowersearch from '../../components/hooks/PowerSearchCopySettings'
import check from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/check.png'
import loading from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/loading.png'
import fail from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/cross.png'
import SetNameForUsers from '../Modal/ModalLayouts/SetName';
import SuspendIcon from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropdownIcons/suspend.png'
import ClearLogsIcon from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropdownIcons/clearLogs.png'
import ChangePortalNameIcon from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropdownIcons/changeTag.png'
import CamectIntegrationIcon from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropdownIcons/camectIntegration.png'
import CopyConfigIcon from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropdownIcons/copyConfig.png'
import RebootIcon from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropdownIcons/reboot.png'
import ResetIcon from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropdownIcons/reset.png'
import AddToGroupIcon from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropdownIcons/addToGroup.png'
import revokeAccess from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropdownIcons/revoke.png'
import ChangeRole from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropdownIcons/swap.png'
import { se } from 'date-fns/locale';
import { add } from 'date-fns';

const DevicesList = ({ devices, filtering, HandleSaveSearchQuery, totalPages, setTotalPages, pageFetchedIndex, setPageFetchedIndex, pageSize, setPageSize, totalDevices, setTotalDevices, isUsersSites, groupMode, update, setUpdate, hideControls, offlineOnly, isSortable, editUserAccess, sitesToRemove, setSitesToRemove, HandleGiveAccess, sitesForGroup, setSitesForGroup, sitesForGroupMode, setSitesForGroupMode, selectAllCancelled }) => {
  
  const [SiteDevices, setSiteDevices] = useState(devices)
  const token = useSelector(state => state.auth.token);
  const client = useSelector(state => state.auth.client);
  const dataManager = new DataManager();
  const parsedData = useParseArrayDeviceData(devices);
  const [suggestions, setSuggestions] = useState([]);
  const [selectionMade, setSelectionMade] = useState(false);
  const [siteID, setSiteID] = useState();
  const [copyUsersChecked, setCopyUsersChecked] = useState(dataManager.getItem('copyUsersChecked'));
  const [autoChooseASiteChecked, setAutoChooseASiteChecked] = useState(dataManager.getItem('autoChooseASite'));
  const [deviceKey, setDeviceKey] = useState("");
  const [queryDeviceList, setQueryDeviceList] = useState("");
  const [myUserID, setMyUserID] = useState("");
  const [nameSet, setNameSet] = useState('');
  const [deviceType, setDeviceType] = useState("");
  const [redraw, setRedraw] = useState(0)
  let newSiteUUID = "";
  const [selectedGroup, setSelectedGroup] = useState()
  const addAccessMode = dataManager.getItem('isAddAccessMode')
  const [checkedItems, setCheckedItems] = useState(dataManager.selectedSites.value || []);
  const loggedInAs = useSelector(state => state.auth.companyName);
  const userID = useSelector(state => state.auth.userID);
  const DeviceKey = sessionStorage.getItem("DeviceKey")
  const [openDropdownRowId, setOpenDropdownRowId] = useState(null);
  const [groups, setGroups] = useState([]);
  const apiData = {token,client}
  const dropdownRef = useRef(null);
  const ModalDialog = useRef();
  const [modalTitle, setModalTitle] = useState("title")
  const [modalComponent, setModalComponent] = useState()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const columnVisibility = JSON.parse(localStorage.getItem('columnVisibility')) || {};
  const [selectAll, setSelectAll] = useState(null)
  
  const GetGroups = async () => {
      try {
          const listOptions = await getGroups(apiData, dispatch);
          console.log(listOptions);
          setGroups(listOptions || []);
      } catch (error) {
          console.error('Failed to fetch groups:', error);
          setGroups([]);
      }
  };

  useEffect(() => {
    console.log(groupMode)
      GetGroups();
  }, []);

  const HandleNextPage = () => {
    setPageFetchedIndex(prevPageFetchedIndex => prevPageFetchedIndex + 1)
    dataManager.setItem('pageIndex', prevPageFetchedIndex => prevPageFetchedIndex + 1)
  }

  const HandlePreviousPage = () => {
    setPageFetchedIndex(prevPageFetchedIndex => prevPageFetchedIndex - 1)
    dataManager.setItem('pageIndex', prevPageFetchedIndex => prevPageFetchedIndex - 1)
  }

  const HandleSetPageIndex = (pageFetchedIndex) => {
    setPageFetchedIndex(pageFetchedIndex)
  }

  const HandlePageSize = (PageSize) => {
    setPageSize(PageSize);
    dataManager.setItem('pageSize', PageSize)
    HandlePageCount(totalPages);
    dataManager.setItem('totalPages', totalPages)
  }

  const HandlePageCount = (totalPages) => {
    setTotalPages(totalPages);
  }

  useEffect(() => {

    if (selectAll == null){
      return
    }

    if (selectAll) {
      if (addAccessMode){
        console.log("here")
        const devices = SiteDevices.map(device => ({
          deviceID: device.id,
          name: device.deviceName !== '' ? device.deviceName : device.key
        }));

        setCheckedItems(devices)
      } else if (groupMode && !sitesForGroupMode){
        console.log("here")
        const deviceUUIDs = SiteDevices.map(device => device.uuid);
        console.log(deviceUUIDs)

        setCheckedItems(deviceUUIDs); 
      } else if (isUsersSites && !sitesForGroupMode){
        console.log("here")
        const devices = SiteDevices.map(device => ({
          deviceUUID: device.uuid,
          deviceID: device.deviceID,
        }));

        setSitesToRemove(devices); 
      } else if (sitesForGroupMode) {
        const deviceUUIDs = SiteDevices.map(device => device.uuid);
        console.log(deviceUUIDs)
        setSitesForGroup(deviceUUIDs); 
      }
    } 
    else {
      if (addAccessMode || groupMode){
        setCheckedItems([]);
      } else if (isUsersSites && !sitesForGroupMode){
        setSitesToRemove([]);
      } else if (sitesForGroupMode){
        setSitesForGroup([])
      }
    }
  }, [selectAll, SiteDevices]);
  

  useEffect(() => {
    console.log(sitesToRemove)
  }, [sitesToRemove])

  useEffect(() => {
    console.log(checkedItems)
  }, [checkedItems])

  useEffect(() => {
    console.log(sitesForGroup)
  }, [sitesForGroup])

  useEffect(() => {
    setSiteDevices(devices);
    dataManager.setItem('pageIndex', pageFetchedIndex)
  }, [pageFetchedIndex, pageSize, devices])

  const HandleChangeOwner = async (result) => {
    const data = await devices;
    const updatedData = data.map(row => {
      if (row.uuid === result.DeviceUUID) {
        return { ...row, name: result.dropDown };
      }
      return row;
    });

    setSiteDevices(updatedData);
  };

  const handleSettingsClick = (rowId) => {
    // If the clicked dropdown is already open, close it
    if (openDropdownRowId === rowId) {
      setOpenDropdownRowId(null);
    } else {
      // Otherwise, open the clicked dropdown
      setOpenDropdownRowId(rowId);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // Clicked outside the dropdown menu, so close it
        setOpenDropdownRowId(null);
      }
    };

    // Add event listener to listen for clicks outside the dropdown menu
    document.addEventListener('click', handleClickOutside);

    return () => {
      // Cleanup: Remove event listener when component unmounts
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const HandleShowModal = () => {
    ModalDialog.current.open();
  }

  const HandleCloseModal = () => {
    ModalDialog.current.close();
    setSuggestions([])
    setQueryDeviceList("")
    setSelectionMade(false)
    setSiteID("")
    setCopyUsersChecked(false)
    dataManager.setItem('copyUsersChecked', false);
    dataManager.setItem('setNameCopyUsers', "")
  }

  useEffect(() => {
    if ((suggestions.length > 0) || ((redraw > 0) && queryDeviceList !== "")){
      showCopySiteSettingsModal(deviceKey);
    } 
  }, [suggestions, redraw])

  useEffect(() => {
    if (queryDeviceList !== "" && queryDeviceList !== " " && queryDeviceList !== null){
      showCopySiteSettingsModal(deviceKey);
    } else {
      setSuggestions([])
    }
      //This is to rerender the modal and show the new data in the query
  }, [queryDeviceList])

  const HandleUipiodOnClick = (DeviceUUID) => {
    dispatch(serviceActions.setDeviceUUID(DeviceUUID));
    useSetCookie("DeviceUUID", DeviceUUID, 1);
    navigate("/AllSites/SiteView/" + DeviceUUID);
  }

  const updateSiteID = (siteID) => {
    setQueryDeviceList(siteID);
    setSuggestions([]);
    dataManager.setItem('siteIDToCopyTo', siteID);
    setRedraw(redraw+1)
    setSelectionMade(true);
  }

  const selectAnEmptySite = (event) => {
    setAutoChooseASiteChecked(event.target.checked);
    dataManager.setItem('autoChooseASite', event.target.checked);
    showCopySiteSettingsModal(deviceKey);
  }

  const showCopySiteSettingsModal = (DeviceKey, type) => {
    setModalTitle("Copy configuration of: " + DeviceKey)
    setModalComponent(
        <div>
          { (<div>
            <label>
              <input
                type="checkbox"
                checked={dataManager.getItem('autoChooseASite')}
                onChange={selectAnEmptySite}
              />
              Automatic site selection
            </label>
            <br></br>
            <p style={{color:"#4C4C4C"}}>The system will select a site ID with no users that is not named.</p>
          </div>)}
    
          <br></br>
          
          {!dataManager.getItem('autoChooseASite') && (
            
            <div>
            <div>
              <CopyUsersPowersearch apiData={apiData} update={update} setUpdate={setUpdate} suggestions={suggestions} setSuggestions={setSuggestions} queryDeviceList={queryDeviceList} setQueryDeviceList={setQueryDeviceList} redraw={redraw} setRedraw={setRedraw} />
            </div>
        
            {suggestions.length > 0 && (
              <ul
                style={{
                  listStyle: 'none',
                  margin: 0,
                  padding: '0px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  position: 'absolute',
                  width: '94%',
                  backgroundColor: '#fff',
                  zIndex: 1000,
                }}
              >
                {suggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    onClick={() => updateSiteID(suggestion)}
                    style={{
                      padding: '8px',
                      cursor: 'pointer',
                      borderBottom: '1px solid #ddd',
                    }}
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}
    
            <div 
              style={{
                flexDirection: "column", 
                justifyContent: "center", 
                alignItems: "center", 
                textAlign: "center"
              }}
              >
              <p style={{color:"#4C4C4C"}}>{`Please note, only sites without a name or users will appear in search suggestions.`}</p>
              <p style={{color:"#4C4C4C"}}>{`If a site isn't listed, you can still copy to it by entering its Site ID.`}</p>
            </div>
        </div>
      )}
    
      <br></br>
          <div>
            <label>
              <input
                type="checkbox"
                checked={dataManager.getItem('copyUsersChecked')}
                onChange={handleCopyUsersSelected}
              />
              Copy users too?
            </label>
            <br></br>
          </div>
          {dataManager.getItem('copyUsersChecked') && (
            <div>
              <SetNameForUsers 
                placeholder={"Name of site for new users in app and in portal:"} 
                HandleCloseModal={HandleCloseModal} 
                setNameSet={setNameSet}
              />
              <small>(Note, you can change this after)</small>
            </div>
          )}
          <br/>
          <Button1 style={{width:"100%"}} onClick={copySiteSettings}>Copy</Button1>
        </div>
        
      
    );

    HandleShowModal();
  }

  const AddSiteToGroup = async (uuid) => {
    const group = groups.find(g => g.name === dataManager.getItem('selectedGroup'));
    console.log(dataManager.getItem('selectedGroup'))
      if (group) {
          let body = {
              groupID: group.id,
              groupName: group.name,
              groupDevices: [uuid]
          }

          const response = await addSitesToGroup(apiData, body, dispatch);
          console.log(response)
          if (response.success) {
              setUpdate(update + 1);
              HandleCloseModal();
              successModal("Site successfully added to group: " + dataManager.getItem('selectedGroup'), false);
              
              setSiteDevices((prevSiteDevices) =>
                prevSiteDevices.map((device) =>
                  device.uuid === uuid ? { ...device, name: dataManager.getItem('selectedGroup') } : device
                )
              );
          } else {
            errorModal("Something went wrong, please try again", false);
          }
      } else {
        errorModal("Unable to find group, please try again", false);
      }
  }

  // ---------- carrys out clicked action in dropdown
  const UserActionHandler = (userAction, suspend, DeviceId, DeviceUUID, userID, DeviceName, DeviceKey, camectIntegationType, tag, row, DeviceDetails, type) => {
    switch (userAction) {
      case 'Unsuspend Site':
      case 'Suspend Site':
        const SuspendDevice = async () => {
          const response = await suspendDevice(apiData, suspend, DeviceId, dispatch);
          if (response) {
            let status;

            if (row.status === "suspended") {
              status = "available"
            } else {
              status = "suspended"
            }
            setSiteDevices((prevSiteDevices) =>
              prevSiteDevices.map((device) =>
                device.uuid === DeviceUUID ? { ...device, status: status } : device
              )
            );
            successModal("Site is now " + status)
            setOpenDropdownRowId(null);
            setUpdate(prevUpdate => prevUpdate + 1);
          } else {
            errorModal("Something went wrong, please try again", false)
          }
        }
        SuspendDevice();
        break;
      case 'Clear Logs':
        const ClearLogs = () => {
          setOpenDropdownRowId(null);

          const HandleClearLogs = async () => {
            const response = await clearDeviceLogs(apiData, DeviceUUID, userID, dispatch);
            if (response.success) {
              HandleCloseModal();
              successModal("Logs cleared successfully", false)
            } else {
              errorModal("Something went wrong, please try again", false)
            }
          }

          const Cancel = () => {
            HandleCloseModal();
          }

          setModalTitle("Clear Logs");
          setModalComponent(
            <div style={{ display: "flex", flexDirection: "column", alignItems: "content", justifyContent: "center", width: "100%", gap: "20px" }}>
              <div style={{ display: "flex", alignItems: "content", justifyContent: "center", width: "100%", gap: "3px" }}>
                <h4 style={{ margin: "0px" }}>{"Are you sure you want to clear Device: " + DeviceKey + " Log's. This action is "}</h4>
                <h4 style={{ margin: "0px", color: "red" }}>Permanent</h4>
                <h4 style={{ margin: "0px" }}>!</h4>
              </div>
              <div style={{ display: "flex", alignItems: "content", justifyContent: "center", width: "100%", gap: "10px" }}>
                <Button1 propsclassName={classes.CancelButton} onClick={Cancel}>Cancel</Button1>
                <Button1 propsclassName={classes.ClearLogs} onClick={() => HandleClearLogs()}>Clear Logs</Button1>
              </div>
            </div>
          )
          HandleShowModal()
        }
        ClearLogs();
        break;
      case 'Change portal name':
        const ChangeTag = async () => {
          setOpenDropdownRowId(null);
          setModalTitle("Change portal name")
          setModalComponent(
          <SetTag 
            placeholder={tag} 
            DeviceUUID={DeviceUUID} 
            HandleCloseModal={HandleCloseModal} 
            dispatch={dispatch} update={update} 
            setUpdate={setUpdate} 
            SiteDevices={SiteDevices} 
            setSiteDevices={setSiteDevices} 
            successModal={successModal}
            errorModal={errorModal}
          />)
          HandleShowModal()
        }
        ChangeTag();
        break;
      case 'Change group':
        const ChangeOwner = async (DeviceKey) => {
          setModalTitle("Change group of: " + DeviceKey)
          setModalComponent(<DropDownForm apiData={apiData} DeviceUUID={DeviceUUID} companyName={""} NotificationTitle={""} label={"Please select a new site group below"} dropDownOptions={['EyeSpyFX', 'RE:SURE', 'PARFAS', 'PORTAL_SECURITY', 'DCC', 'SOLO', 'CSC', 'CPS', 'EES', 'WEEBIG', 'KNOWIO', '12TH_LOCK']} HandleCloseModal={HandleCloseModal} update={update} setUpdate={setUpdate} SiteDevices={SiteDevices} setSiteDevices={setSiteDevices} />)
          setOpenDropdownRowId(null);
          HandleShowModal()
        }
        ChangeOwner(DeviceKey);
        break;
      case 'Change camect integration type':
        const Changecamectintergrationtype = async () => {
          const CamectIntegationType = camectIntegationType == 2 ? 1 : 2;
          const notificationMessage = CamectIntegationType == 1 ? "Whole Camect" : "Per Cam Arming"
          const response = await changeCamectIntegration(apiData, DeviceUUID, CamectIntegationType, DeviceName, dispatch)
          if (response) {
            successModal("Camect integration changed to " + notificationMessage)
            DeviceDetails.camectIntegrationType = CamectIntegationType;
            row.deviceDetails = JSON.stringify(DeviceDetails);
            setOpenDropdownRowId(null);
          } else {
            errorModal("Failed to change camect integration type, please try again")
          }
        }
        Changecamectintergrationtype();
        break;
      case 'Copy site configuration':
        const Copydevicesettings = async (apiData, fromDeviceUUID) => {
          setDeviceKey(DeviceKey);
          dataManager.setItem('deviceUUIDToCopyFrom', DeviceUUID);
          dataManager.setItem('deviceIDToCopyFrom', DeviceId);
          setMyUserID(userID);
          setDeviceType(type);
          showCopySiteSettingsModal(DeviceKey, type);
        }
        Copydevicesettings(apiData, DeviceUUID);
        break;
      case 'Reboot site':
        const RebootDevice = async () => {
          const response = await rebootIPIODevice(apiData, DeviceUUID, userID, DeviceName);
          setOpenDropdownRowId(null);
          if (response){
            successModal("Site rebooting...")
          } else {
            errorModal("Failed to reboot site, please try again")
          }
        }
        RebootDevice();
        break;
      case 'Reset site':
        const ResetDevice = async () => {
          setModalTitle("Are you sure?")
          setModalComponent(
              <div>
                  <div style={{ textAlign: "center" }}>
                  <h2>Are you sure you want to reset: {DeviceKey}</h2>
                  </div>
                  <p style={{ textAlign: "center" }}>This will revert all settings & customisations to factory default.</p>
                  <p style={{ textAlign: "center" }}>This action cannot be undone!</p>
                  <div style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "10px" // Adds space between buttons
                  }}>
                      <Button1 onClick={HandleCloseModal}>No, cancel</Button1>
                      <Button1 onClick={() => resetSite(apiData, DeviceUUID, userID, DeviceName, dispatch)}>Yes, I'm sure</Button1>
                  </div>
              </div>
          )
        } 
        HandleShowModal();
        ResetDevice();
      break;
      case 'Add to group' :
        console.log(row.uuid)
        const ShowGroupsModal = async () => {
          const groupNames = groups.map(listOptions => listOptions.name);
          setModalTitle("Add site to group")
          setModalComponent(
            <div>
              <Dropdown label={"Please select a group below"} dropDownOptions={groupNames} setSelectedGroup={setSelectedGroup} />
              <br></br>
              <Button1 onClick={() => AddSiteToGroup(row.uuid)}>Add to group</Button1>
            </div>
            
          )
          HandleShowModal();
        }

        ShowGroupsModal();
      break;
      case 'Change role':
        const HandleChangeUserAccess = async () => {
          const responseData = await changeRole(apiData, DeviceId, userID, row.role, row.userID, DeviceUUID, dispatch);
            if (responseData.success) {
              const newRole = row.role == 'user' ? 'manager' : 'user'
              const formattedNewRole = newRole == 'user' ? "app standard user" : "app manager"

                setSiteDevices((prevSiteDevices) =>
                  prevSiteDevices.map((device) =>
                    device.uuid === DeviceUUID ? { ...device, role: newRole } : device
                  )
                );
                setOpenDropdownRowId(null);
                successModal(`User role successfully changed to ${formattedNewRole}`, false)
            } else {
              errorModal("Failed to change users role, please try again.", false)
            }
        }

        HandleChangeUserAccess()
      break;
      case 'Revoke access':
        const HandleRevokeUserAccess = async () => {
            const responseData = await removeUser(apiData, DeviceId, DeviceUUID, userID, row.userID, dispatch)
                if (responseData) {
                    setSiteDevices((prevSiteDevices) =>
                      prevSiteDevices.filter((device) => device.uuid !== DeviceUUID)
                    );
                    setOpenDropdownRowId(null);
                    successModal("User successfully removed", false)
                } else {
                  errorModal("Failed to remove user, please try again.", false)
                }
        }

        HandleRevokeUserAccess();
      break;  
      default:
    }
  }

  const resetSite = async (apiData, DeviceUUID, userID, DeviceName, dispatch) => {
    const response = await resetIPIODevice(apiData, DeviceUUID, userID, DeviceName, dispatch)
    if (response.success) {
      successModal("Site reset successfully!", false)
      setPageFetchedIndex(pageFetchedIndex)
    }
    setOpenDropdownRowId(null);
}

  const successModal = (message, isCopyConfig) => {
    setModalTitle('Success')
    setModalComponent(
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <img className={classes.Icon} src={check}>
        </img>
        <br></br>
        <br></br>
        <div>
            <h3 style={{ textAlign: "center", color:"#858585"}}>{message}</h3>
        </div>   
        <br></br>
        <br></br>
        <div>
          {isCopyConfig && (<Button1 style={{width:"100%"}} onClick={() => HandleUipiodOnClick(newSiteUUID)}>Go to new site</Button1>)}
          <br></br>
          {isCopyConfig && (<Button1 style={{width:"100%"}} onClick={() => HandleUipiodOnClick(dataManager.getItem('deviceUUIDToCopyFrom'))}>Go to original site</Button1>)}
          <br></br>
          <Button1 onClick={HandleCloseModal} style={{width:"100%"}}>Close</Button1>
        </div>
      </div>
    )
    HandleShowModal();
  }

  const errorModal = (message, isCopyConfig) => {
    setModalTitle('Error')
    setModalComponent(
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <img className={classes.Icon} src={fail}>
        </img>
        <br></br>
        <br></br>
        <div>
            <h3 style={{ textAlign: "center", color:"#858585"}}>{message}</h3>
        </div>   
        <br></br>
        <br></br>
        <div>
          {isCopyConfig && <Button1 onClick={copySiteSettings}style={{width:"100%"}}>Try again</Button1>}
          <br></br>
          <Button1 style={{width:"100%"}} onClick={HandleCloseModal}>Cancel</Button1>
        </div>
      </div>
    )
    HandleShowModal();
  }

  const loadingModal = (message) => {
    setModalTitle('Please wait...')
    setModalComponent(
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <img className={classes.Icon} src={loading}>
        </img>
        <br></br>
        <br></br>
        <div>
            <h3 style={{ textAlign: "center", color:"#858585"}}>{message}</h3>
        </div>   
        <br></br>
        <br></br>
      </div>
    )
    HandleShowModal();
  }

  const copySiteSettings = async () => {
    loadingModal("Copying site configuration, please wait...");

    const copyUsers = dataManager.getItem('copyUsersChecked');    
    const fromUUID = dataManager.getItem('deviceUUIDToCopyFrom');
    let toUIPIOID = queryDeviceList;
    const siteName = dataManager.getItem('setNameCopyUsers');
    const auto = dataManager.getItem('autoChooseASite')
    if (auto){
      toUIPIOID = ""
    }

    loadingModal();

      const response = await copyIPIOSettings(apiData, fromUUID, toUIPIOID, auto, dispatch);
      if (response.success){
        newSiteUUID = response.data[0].uuid;
        if (copyUsers){
          if (auto){
            handleCopyUsers(siteName, auto, response.data[0].key);
          } else {
            handleCopyUsers(siteName, auto, "");
          }
          
          loadingModal("Site configuration copied successfully! Now copying users, please wait...")
        } else {
          if (auto){
            successModal(`Site configuration copied successfully! \n${"Site ID: ", response.data[0].key}`, true);
          } else {
            successModal("Site configuration copied successfully!", true);
          }

          setPageFetchedIndex(pageFetchedIndex)
        }
      } else {
        //error
        if (auto){
          errorModal("Failed to automatically copy site configuration. \nUnable to find a suitable site, please enter the Site ID manually", false)
        } else {
          errorModal("Failed to copy site configuration, please try again.", true);
        }
      } 
  }

  const handleCopyUsers = async (siteName, auto, newUIPIOID) => {
    const deviceID = dataManager.getItem('deviceIDToCopyFrom');
    let response = "";
    if (auto){
      response = await copyUsersToAnotherDevice(apiData, deviceID, newUIPIOID, siteName, userID, dispatch);
    } else {
      response = await copyUsersToAnotherDevice(apiData, deviceID, queryDeviceList, siteName, userID, dispatch);
    }
    if (response.success){
      successModal("Site configuration & users copied successfully!", true)
      setPageFetchedIndex(pageFetchedIndex)
      dataManager.setItem('setNameCopyUsers', "")
    } else {
      errorModal("Failed to copy users, please try again.", false);
    }
  }

  const outputs =
  {
    output1: 1,
    output2: 0,
    output3: 0,
    output4: 1
  };

  const inputs =
  {
    input1: 1,
    input2: 1,
    input3: 1,
    input4: 1,
    input5: 0,
    input6: 1,
    input7: 1,
    input8: 0,
  };


  const handleCheckboxChange = (uuid, deviceID, deviceName) => {
    console.log("Here")
    if (!dataManager.getItem('isAddAccessMode')){
      setCheckedItems((prevCheckedItems) => {
        if (prevCheckedItems.includes(uuid)) {
          return prevCheckedItems.filter((id) => id !== uuid);
        } else {
          return [...prevCheckedItems, uuid];
        }
      });
    } else {
      console.log("here")
      setCheckedItems((prevCheckedItems) => {
        if (prevCheckedItems.some((item) => item.deviceID === deviceID)) {
          return prevCheckedItems.filter((item) => item.deviceID !== deviceID);
        } else {
          return [...prevCheckedItems, { deviceID, deviceName }];
        }
      });
    }
  };

  const HandleSitesChange = (deviceUUID, deviceID, deviceName) => {
    console.log("here")
      setSitesToRemove((prevSitesToRemove) => {
        // Check if the combination of deviceUUID and deviceID already exists
        const exists = prevSitesToRemove.some(
          (site) => site.deviceUUID === deviceUUID && site.deviceID === deviceID
        );

        if (exists) {
          // Remove the item if it already exists
          return prevSitesToRemove.filter(
            (site) => site.deviceUUID !== deviceUUID || site.deviceID !== deviceID
          );
        } else {
          // Add the item if it doesn't exist
          return [...prevSitesToRemove, { deviceUUID, deviceID }];
        }
      });
  }

  const HandleSitesForGroupChange = (uuid) => {
    console.log("here")
    setSitesForGroup((prevSitesForGroup) => {
      if (prevSitesForGroup.includes(uuid)) {
        return prevSitesForGroup.filter((id) => id !== uuid);
      } else {
        return [...prevSitesForGroup, uuid];
      }
    });
    
  }

  useEffect(() => {
    console.log(checkedItems)
    dataManager.setSelectedSites(checkedItems)
  }, [checkedItems])

  const handleCopyUsersSelected = (event) => {
    setCopyUsersChecked(event.target.checked);
    dataManager.setItem('copyUsersChecked', event.target.checked);
    showCopySiteSettingsModal(deviceKey);
  }


  let tablecolumns = [
    {
      id: 'tag',
      header: () => (
        <div className={classes.tableTitleText}>
          <h3>Portal site name</h3>
        </div>
      ),
      filterLabel: "Site name",
      accessorKey: 'tag',
      hidden: !columnVisibility['tag'] ?? false,
      cell: (row) => {
        const uuid = row.row.original.uuid;
        const deviceID = row.row.original.id;
        let deviceName= row.row.original.key;

        if (row.row.original.tag !== ""){
          deviceName = row.row.original.tag
        }

        if (row.row.original.deviceName !== ""){
            deviceName = row.row.original.deviceName
        }
        //const deviceName = row.row.original.tag;

        console.log(row.row.original)
        return (
          <div style={{
            maxWidth: '150px',
            overflow: 'hidden',
            whiteSpace: 'normal',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2 
          }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>  
              {(groupMode || addAccessMode) && (
                <div style={{ marginRight: "10px" }}>  {/* Added margin to separate from the pill */}
                  <input
                    type="checkbox"
                    id={`checkbox-${uuid}`}
                    className={classes["circular-checkbox"]}
                    checked={(checkedItems.some((item) => item === row.row.original.uuid)) || 
                      (checkedItems.some((item) => item.deviceID === row.row.original.id))
                    }
                    
                    onChange={() => handleCheckboxChange(uuid, deviceID, deviceName)}
                  />
                  <label
                    htmlFor={`checkbox-${uuid}`}
                    className={classes['custom-checkbox']}
                  />
                </div>
              )}
          
              <div onClick={() => HandleUipiodOnClick(uuid)} style={{ cursor: "pointer", width: "fit-content" }}>
                {row.row.original.tag && row.row.original.tag.trim() !== "" && (
                  <Pill_Widget
                    boolean={true}
                    PillColor_IfTrue={"#919191"}
                    PillColor_IfFalse={"#6e6e6e"}
                    text_IfTrue={row.row.original.tag}
                    className={classes.PillHeight}
                  />
                )}
              </div>
            </div>
          </div>
          
        );
      }, 
    },
    {
      hidden: !columnVisibility['deviceName'] ?? false,
      id: 'deviceName',
      header: () => (
        <div className={classes.tableTitleText}>
        <h3>{isUsersSites ? 'Their app site name' : 'My app site name'}</h3>
      </div>
      ),
      filterLabel: "My site name",
      accessorKey: 'deviceName',
      cell: (row) => {
        return (
          <div style={{
            maxWidth: '150px',
            overflow: 'hidden',
            whiteSpace: 'normal',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2 }} onClick={() => HandleUipiodOnClick(row.row.original.uuid)} className={classes.tableValueText}>
            {row.row.original.deviceName && row.row.original.deviceName.trim() !== "" && (
              <Pill_Widget
                boolean={true}
                PillColor_IfTrue={"#C3B1E1"}
                PillColor_IfFalse={"#6e6e6e"}
                text_IfTrue={row.row.original.deviceName}
                className={classes.PillHeight}
              />
            )}
          </div>
        )
      }
    },
    {
      hidden: !columnVisibility['key'] ?? false,
      id: 'key',
      header: () => (
        <div className={classes.tableTitleText}>
          <h3>Site ID</h3>
        </div>
      ),
      filterLabel: "Site ID",
      accessorKey: 'key',
      cell: (row) => {

        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} className={classes.tableValueTextBold}>
            {row.row.original.key}
          </div>
        )
      }
    },
    {
      hidden: !columnVisibility['serial'] ?? false,
      id: 'serial',
      header: () => (
        <div className={classes.tableTitleText}>
          <h3>Serial number</h3>
        </div>
      ),
      filterLabel: "Serial number",
      accessorKey: 'subGroup',
      cell: (row) => {
        const subGroup = row.row.original.subGroup;
        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} className={classes.tableValueText}>
            {subGroup != "" ? subGroup : "-"}
          </div>
        )
      }
    },
    {
      hidden: !columnVisibility['ip'] ?? false,
      id: 'ip',
      header: () => (
        <div className={classes.tableTitleText}>
          <h3>IP address</h3>
        </div>
      ),
      filterLabel: "IP address",
      accessorKey: 'ip',
      cell: (row) => {
        const isCC = row.row.original.type === "CC" ? true : false
        const ipAddress = row.row.original.ip
        let CC = ""
        if (isCC) {
          if (ipAddress == "10.250.1.76"){
            CC = "IPIO CC (1)"
          } else if (ipAddress == "10.250.1.30"){
            CC = "IPIO CC (2)"
          } else if (ipAddress == "10.250.1.215"){
            CC = "IPIO CC (3)"
          } else if (ipAddress == "10.250.1.218"){
            CC = "IPIO CC (4)"
          } else if (ipAddress == "10.250.1.203"){
            CC = "IPIO CC (5)"
          } else if (ipAddress == "10.250.1.65"){
            CC = "IPIO CC (6)"
          } else if (ipAddress == "10.250.1.77"){
            CC = "IPIO CC (7)"
          } else if (ipAddress == "10.250.1.143"){
            CC = "IPIO CC (8)"
          } else if (ipAddress == "10.250.1.57"){
            CC = "IPIO CC (9)"
          } else if (ipAddress == "10.250.1.22"){
            CC = "IPIO CC (Upgrade Test)"
          } else if (ipAddress == "10.250.1.64"){
            CC = "IPIO CC (FW Dev)"
          }
        }
        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} className={classes.tableValueText}>
            <Pill_Widget 
            boolean={isCC} 
            PillColor_IfTrue={"#6996f1"} 
            PillColor_IfFalse={"#626a67"} 
            text_IfTrue={CC} 
            text_IfFalse={row.row.original.ip}
            className={classes.PillHeight} />
          </div>
        )
      }
    },
    {
      hidden: !columnVisibility['firmware'] ?? false,
      id: 'firmware',
      header: () => (
        <div className={classes.tableTitleText}>
          <h3>Firmware</h3>
        </div>
      ),
      filterLabel: "Firmware",
      accessorKey: 'firmware',
      cell: (row) => {
        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} className={classes.tableValueText}>
            {row.row.original.firmware}
          </div>
        )
      }
    },
    {
      hidden: !columnVisibility['status'] ?? false,
      id: 'status',
      header: () => (
        <div className={classes.tableTitleText}>
          <h3>Status</h3>
        </div>
      ),
      filterLabel: "Status",
      accessorKey: 'status',
      cell: (row) => {
        const OnlineStatus = row.row.original.online == "YES" ? true : false
        const SuspendedStatus = row.row.original.status == "suspended";
        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} className={classes.tableValueText}>
            <Pill_Widget 
                boolean={OnlineStatus} 
                PillColor_IfTrue={"#22ca80"} 
                PillColor_IfFalse={"#ff4f70"} 
                text_IfTrue={"Online"} 
                text_IfFalse={"Offline"} 
                className={classes.PillHeight} 
              />

                {SuspendedStatus && 
                  <Pill_Widget 
                    boolean={SuspendedStatus} 
                    PillColor_IfTrue={"black"} 
                    PillColor_IfFalse={"none"} 
                    text_IfTrue={"Suspended"}
                    className={classes.PillHeight} 
                  />
                }
          </div>
        )
      }
    },
    {
      hidden: !columnVisibility['userCount'] ?? false,
      id: 'userCount',
      header: () => (
        <div className={classes.tableTitleText}>
          <h3>User count</h3>
        </div>
      ),
      filterLabel: "User count",
      accessorKey: 'userCount',
      cell: (row) => {
        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} className={classes.tableValueTextBold}>
            {row.row.original.userCount}
          </div>
        )
      }
    },
    {
      hidden: !columnVisibility['states'] ?? false,
      id: 'states',
      header: () => (
        <div className={classes.tableTitleText}>
          <h3>States</h3>
        </div>
      ),
      filterLabel: "States",
      cell: (row) => {
        const data = parsedData[row.row.index]
        return (
          <div className={classes["bottom-wrapper"]}>
            <div>
              <ul className={classes.outputs}>
                <OutputPinCircle outputs={outputs} OutputPins={data.OutputPins} />
              </ul>
            </div>
            <div className={classes["bottom-wrapper_divider"]} />
            <div>
              <ul className={classes.inputs}>
                <InputPinCircle inputs={inputs} InputPins={data.InputPins} />
              </ul>
            </div>
          </div>
        )
      }
    },
    {
      hidden: !columnVisibility['group'] ?? false,
      id: 'group',
      header: () => (
        <div className={classes.tableTitleText}>
          <h3>Group</h3>
        </div>
      ),
      filterLabel: "Group",
      accessorKey: 'name',
      cell: (row) => {
        let companyName = row.row.original.name;
        
        if (loggedInAs == companyName){
         return;
        }

        if (companyName == "RE:SURE") {
          companyName = "RESURE"
        }
        
        const companyColors = {
          EyeSpyFX: "rgb(100, 149, 237)",
          RESURE: "rgb(75, 0, 130)",
          PARFAS: "rgb(0, 0, 0)",
          PORTAL_SECURITY: "rgb(0, 0, 139)",
          SOLO: "rgb(178, 34, 34)",
          KNOWIO: "rgb(20, 155, 8)",
          CPS: "rgb(8, 155, 121)",
          CSC_SECURITY: "rgb(8, 150, 155)",
          EES: "rgb(94, 8, 155)"
        };

        const companyColor = companyColors[companyName] || "#6e6e6e";

        return (

          <div style={{
            maxWidth: '100px',
            overflow: 'hidden',
            whiteSpace: 'normal',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2, 
            cursor: "pointer", color: "white", fontWeight: "600"}} onClick={() => HandleUipiodOnClick(row.row.original.uuid)}>
            <Pill_Widget 
              boolean={true} 
              PillColor_IfTrue={companyColor} 
              PillColor_IfFalse={"#626a67"} 
              text_IfTrue={row.row.original.name}
              className={classes.PillHeight} 
            />
          </div>
        )
        
      }
    },
    {
      hidden: !columnVisibility['actions'] ?? false,
      id: 'actions',
      filterLabel: "Settings",
      cell: (row) => {
        let items = [
          {
            image: SuspendIcon,
            title:
              row.row.original.status !== "suspended"
                ? "Suspend Site"
                : "Unsuspend Site",
          },
          {
            image: ClearLogsIcon,
            title: "Clear Logs",
          },
          {
            image: ChangePortalNameIcon,
            title: "Change portal name",
          },
          {
            image: AddToGroupIcon,
            title: "Add to group"
          }
        ];

        const firmware = row.row.original.firmware.split('.')
        const firmwareMajor = firmware[0]
        const firmwareMinor = firmware[1]
        const DeviceType = row.row.original.type;
        const isOnline = row.row.original.online == "YES" ? true : false;
        
        const deviceDetails = JSON.parse(row.row.original.deviceDetails);
        const camectIntegationType = deviceDetails.camectIntegrationType;

        if ((firmwareMajor > 1 || (firmwareMajor == 1 && firmwareMinor >= 9)) && DeviceType != 'V') {
          items.splice(4, 0, {
            image: CamectIntegrationIcon,
            title: "Change camect integration type",
          },);
        }

        if ((firmwareMajor > 1 || (firmwareMajor == 1 && firmwareMinor >= 7))) {
          items.splice(5, 0, {
            image: CopyConfigIcon,
            title: "Copy site configuration",
          });
        }

        if (isOnline && DeviceType != "CC") {
            items.splice(6, 0, {
              image: RebootIcon,
              title: "Reboot site",
            })
        }

        if (isOnline){
          items.splice(7, 0,
            {
              image: ResetIcon,
              title: "Reset site",
            });
        }

        const ifSettingsCogIsClicked = openDropdownRowId === row.row.id
        const suspend = row.row.original.status == "suspended" ? "available" : "suspended";

        return <DropdownList
          item={row.row.id}
          icon={SettingsCog}
          onClick={handleSettingsClick}
          ifSettingsCogIsClicked={ifSettingsCogIsClicked}
          ref={dropdownRef}
          title={"Actions"}
          className={classes.container}
          onUserActionClick={(userAction) =>
            UserActionHandler(
              userAction,
              suspend,
              row.row.original.id,
              row.row.original.uuid,
              userID,
              row.row.original.name,
              row.row.original.key,
              camectIntegationType,
              row.row.original.tag,
              row.row.original,
              deviceDetails,
              row.row.original.type
            )
          }
          listItems={items}
        />
      },
    }
  ];

  let tableColumnsOfflineOnly = [
  {
      id: 'tag',
      header: () => (
        <div className={classes.tableTitleText} title="Click to sort">
          <h3>Portal site name ⇅</h3>
        </div>
      ),
      filterLabel: "Site name",
      accessorKey: 'tag',
      hidden: !columnVisibility['tag'] ?? false,
      cell: (row) => {
        const uuid = row.row.original.uuid;
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {groupMode || addAccessMode && (
              <div style={{ marginLeft: "10px", marginTop: "3px", marginRight: "20px" }}>
                <input
                  type="checkbox"
                  id={`checkbox-${uuid}`}
                  className={classes["circular-checkbox"]}
                  checked={checkedItems.some((item) => item === row.row.original.uuid)}
                  onChange={() => handleCheckboxChange(uuid)}
                />
                <label
                  htmlFor={`checkbox-${uuid}`}
                  className={classes['custom-checkbox']}
                />
              </div>
            )}
            <div onClick={() => HandleUipiodOnClick(uuid)} style={{ cursor: "pointer", width: "fit-content" }}>
              {row.row.original.tag && row.row.original.tag.trim() !== "" && (
                <Pill_Widget
                  boolean={true}
                  PillColor_IfTrue={"#919191"}
                  PillColor_IfFalse={"#6e6e6e"}
                  text_IfTrue={row.row.original.tag}
                  className={classes.PillHeight}
                />
              )}
            </div>
          </div>
        );
      }, 
    },
    {
      hidden: !columnVisibility['deviceName'] ?? false,
      id: 'deviceName',
      header: () => (
        <div className={classes.tableTitleText} title="Click to sort">
        <h3>{isUsersSites ? 'Their app site name ⇅' : 'My app site name ⇅'}</h3>
      </div>
      ),
      filterLabel: "My site name",
      accessorKey: 'deviceName',
      cell: (row) => {
        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} className={classes.tableValueText}>
            {row.row.original.deviceName && row.row.original.deviceName.trim() !== "" && (
              <Pill_Widget
                boolean={true}
                PillColor_IfTrue={"#C3B1E1"}
                PillColor_IfFalse={"#6e6e6e"}
                text_IfTrue={row.row.original.deviceName}
                className={classes.PillHeight}
              />
            )}
          </div>
        )
      }
    },
    {
      hidden: !columnVisibility['key'] ?? false,
      id: 'key',
      header: () => (
        <div className={classes.tableTitleText} title="Click to sort">
          <h3>Site ID ⇅</h3>
        </div>
      ),
      filterLabel: "Site ID",
      accessorKey: 'key',
      cell: (row) => {

        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} className={classes.tableValueTextBold}>
            {row.row.original.key}
          </div>
        )
      }
    },
    {
      hidden: !columnVisibility['offlineSince'] ?? false,
      id: 'offlineSince',
      header: () => (
        <div className={classes.tableTitleText} title="Click to sort">
          <h3>Offline since ⇅</h3>
        </div>
      ),
      filterLabel: "Offline Since",
      accessorKey: 'lastLoggedIn',
      cell: (row) => {
        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} className={classes.tableValueText}>
             <Pill_Widget 
              PillColor_IfTrue={"#ff4f70"} 
              PillColor_IfFalse={"#ff4f70"} 
              text_IfTrue={UseDateFormat(row.row.original.lastLoggedIn)} 
              text_IfFalse={UseDateFormat(row.row.original.lastLoggedIn)}
              className={classes.PillHeight} 
            />
          </div>
        )
      } 
    },
    {
      hidden: !columnVisibility['serial'] ?? false,
      id: 'serial',
      header: () => (
        <div className={classes.tableTitleText} title="Click to sort">
          <h3>Serial number ⇅</h3>
        </div>
      ),
      filterLabel: "Serial number",
      accessorKey: 'subGroup',
      cell: (row) => {
        const subGroup = row.row.original.subGroup;
        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} className={classes.tableValueText}>
            {subGroup != "" ? subGroup : "-"}
          </div>
        )
      }
    },
    {
      hidden: !columnVisibility['ip'] ?? false,
      id: 'ip',
      header: () => (
        <div className={classes.tableTitleText} title="Click to sort">
          <h3>IP address ⇅</h3>
        </div>
      ),
      filterLabel: "IP address",
      accessorKey: 'ip',
      cell: (row) => {
        const isCC = row.row.original.type === "CC" ? true : false
        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} className={classes.tableValueText}>
            <Pill_Widget 
            boolean={isCC} 
            PillColor_IfTrue={"#6996f1"} 
            PillColor_IfFalse={"#626a67"} 
            text_IfTrue={"IPIO CC"} 
            text_IfFalse={row.row.original.ip}
            className={classes.PillHeight} />
          </div>
        )
      }
    },
    {
      hidden: !columnVisibility['firmware'] ?? false,
      id: 'firmware',
      header: () => (
        <div className={classes.tableTitleText} title="Click to sort">
          <h3>Firmware ⇅</h3>
        </div>
      ),
      filterLabel: "Firmware",
      accessorKey: 'firmware',
      cell: (row) => {
        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} className={classes.tableValueText}>
            {row.row.original.firmware}
          </div>
        )
      }
    },
    {
      hidden: !columnVisibility['userCount'] ?? false,
      id: 'userCount',
      header: () => (
        <div className={classes.tableTitleText} title="Click to sort">
          <h3>User count ⇅</h3>
        </div>
      ),
      filterLabel: "User count",
      accessorKey: 'userCount',
      cell: (row) => {
        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} className={classes.tableValueTextBold}>
            {row.row.original.userCount}
          </div>
        )
      }
    },
    
    {
      hidden: !columnVisibility['group'] ?? false,
      id: 'group',
      header: () => (
        <div className={classes.tableTitleText} title="Click to sort">
          <h3>Group ⇅</h3>
        </div>
      ),
      filterLabel: "Group",
      accessorKey: 'name',
      cell: (row) => {
        let companyName = row.row.original.name;
        if (companyName == "RE:SURE") {
          companyName = "RESURE"
        }

        const companyColors = {
          EyeSpyFX: "rgb(100, 149, 237)",
          RESURE: "rgb(75, 0, 130)",
          PARFAS: "rgb(0, 0, 0)",
          PORTAL_SECURITY: "rgb(0, 0, 139)",
          SOLO: "rgb(178, 34, 34)",
          KNOWIO: "rgb(20, 155, 8)",
          CPS: "rgb(8, 155, 121)",
          CSC_SECURITY: "rgb(8, 150, 155)",
          EES: "rgb(94, 8, 155)"
        };

        const companyColor = companyColors[companyName] || "#6e6e6e";

        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} style={{ cursor: "pointer", width: "fit-content", color: "white", fontWeight: "600" }}>
            <Pill_Widget 
            boolean={true} 
            PillColor_IfTrue={companyColor} 
            PillColor_IfFalse={"#626a67"} 
            text_IfTrue={row.row.original.name}
            className={classes.PillHeight} />
          </div>
        )
      }
    }
  ];

  let tableColumnsUserSites = [
    {
      id: 'tag',
      header: () => (
        <div className={classes.tableTitleText}>
          <h3>Portal site name</h3>
        </div>
      ),
      filterLabel: "Site name",
      accessorKey: 'tag',
      hidden: !columnVisibility['tag'] ?? false,
      cell: (row) => {
        const uuid = row.row.original.uuid;
        const deviceID = row.row.original.id;
        let deviceName= row.row.original.key;

        if (row.row.original.tag !== ""){
          deviceName = row.row.original.tag
        }

        if (row.row.original.deviceName !== ""){
            deviceName = row.row.original.deviceName
        }
        console.log(deviceID)
        return (
          <div style={{
            maxWidth: '150px',
            overflow: 'hidden',
            whiteSpace: 'normal',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2 
          }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>  
              {(editUserAccess && !sitesForGroupMode) && (
                <div style={{ marginRight: "10px" }}>  {/* Added marginRight to separate from the pill */}
                  <input
                    type="checkbox"
                    id={`checkbox-${uuid}`}
                    className={classes["circular-checkbox"]}
                    checked={sitesToRemove.some(
                      (item) => item.deviceUUID === row.row.original.uuid && item.deviceID === row.row.original.deviceID
                    )}                  
                    onChange={() => HandleSitesChange(uuid, deviceID, deviceName)}
                  />
                  <label
                    htmlFor={`checkbox-${uuid}`}
                    className={classes['custom-checkbox']}
                  />
                </div>
              )}

              {(!editUserAccess && sitesForGroupMode) && (
                <div style={{ marginRight: "10px" }}>  {/* Added marginRight to separate from the pill */}
                  <input
                    type="checkbox"
                    id={`checkbox-${uuid}`}
                    className={classes["circular-checkbox"]}
                    checked={sitesForGroup.some(
                      (item) => item === row.row.original.uuid
                    )}                  
                    onChange={() => HandleSitesForGroupChange(uuid)}
                  />
                  <label
                    htmlFor={`checkbox-${uuid}`}
                    className={classes['custom-checkbox']}
                  />
                </div>
              )}
          
              <div onClick={() => HandleUipiodOnClick(uuid)} style={{ cursor: "pointer", width: "fit-content" }}>
                {row.row.original.tag && row.row.original.tag.trim() !== "" && (
                  <Pill_Widget
                    boolean={true}
                    PillColor_IfTrue={"#919191"}
                    PillColor_IfFalse={"#6e6e6e"}
                    text_IfTrue={row.row.original.tag}
                    className={classes.PillHeight}
                  />
                )}
              </div>
            </div>
          </div>
          
        );
      }, 
    },
    {
      hidden: !columnVisibility['deviceName'] ?? false,
      id: 'deviceName',
      header: () => (
        <div className={classes.tableTitleText}>
        <h3>Their app site name</h3>
      </div>
      ),
      filterLabel: "My site name",
      accessorKey: 'deviceName',
      cell: (row) => {
        return (
          <div style={{
            maxWidth: '150px',
            overflow: 'hidden',
            whiteSpace: 'normal',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2 }} onClick={() => HandleUipiodOnClick(row.row.original.uuid)} className={classes.tableValueText}>
            {row.row.original.deviceName && row.row.original.deviceName.trim() !== "" && (
              <Pill_Widget
                boolean={true}
                PillColor_IfTrue={"#C3B1E1"}
                PillColor_IfFalse={"#6e6e6e"}
                text_IfTrue={row.row.original.deviceName}
                className={classes.PillHeight}
              />
            )}
          </div>
        )
      }
    },
    {
      hidden: !columnVisibility['key'] ?? false,
      id: 'key',
      header: () => (
        <div className={classes.tableTitleText}>
          <h3>Site ID</h3>
        </div>
      ),
      filterLabel: "Site ID",
      accessorKey: 'key',
      cell: (row) => {
        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} className={classes.tableValueTextBold}>
            {row.row.original.key}
          </div>
        )
      }
    },
    {
      hidden: !columnVisibility['serial'] ?? false,
      id: 'serial',
      header: () => (
        <div className={classes.tableTitleText}>
          <h3>Serial number</h3>
        </div>
      ),
      filterLabel: "Serial number",
      accessorKey: 'subGroup',
      cell: (row) => {
        const subGroup = row.row.original.subGroup;
        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} className={classes.tableValueText}>
            {subGroup != "" ? subGroup : "-"}
          </div>
        )
      }
    },
    {
      hidden: !columnVisibility['role'] ?? false,
      id: 'role',
      header: () => (
        <div className={classes.tableTitleText}>
          <h3>Role</h3>
        </div>
      ),
      filterLabel: "role",
      accessorKey: 'role',
      cell: (row) => {
        let newRole;
        switch (row.row.original.role){
          case 'user':
            newRole = "App standard user" 
            break;
          case 'manager':
            newRole = "App manager"
        }
        const setPillColour = row.row.original.role == 'user' ? true : false
        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} className={classes.tableValueText}>
            <Pill_Widget 
                boolean={setPillColour} 
                PillColor_IfTrue={"#9c9c9c"} 
                PillColor_IfFalse={"#1e82d4"} 
                text_IfTrue={newRole} 
                text_IfFalse={newRole} 
                className={classes.PillHeight} 
              />
          </div>
        )
      }
    },
    {
      hidden: !columnVisibility['invitedBy'] ?? false,
      id: 'invitedBy',
      header: () => (
        <div className={classes.tableTitleText}>
          <h3>Invited by</h3>
        </div>
      ),
      filterLabel: "invitedby",
      accessorKey: 'invitedByName',
      cell: (row) => {
      
          return (
            <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} className={classes.tableValueTextBold}>
              {row.row.original.invitedByName}
            </div>
          )
        
      }
    },
    {
      hidden: !columnVisibility['status'] ?? false,
      id: 'status',
      header: () => (
        <div className={classes.tableTitleText}>
          <h3>Status</h3>
        </div>
      ),
      filterLabel: "Status",
      accessorKey: 'status',
      cell: (row) => {
        const OnlineStatus = row.row.original.online == "YES" ? true : false
        const SuspendedStatus = row.row.original.status == "suspended";
        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} className={classes.tableValueText}>
            <Pill_Widget 
                boolean={OnlineStatus} 
                PillColor_IfTrue={"#22ca80"} 
                PillColor_IfFalse={"#ff4f70"} 
                text_IfTrue={"Online"} 
                text_IfFalse={"Offline"} 
                className={classes.PillHeight} 
              />

                {SuspendedStatus && 
                  <Pill_Widget 
                    boolean={SuspendedStatus} 
                    PillColor_IfTrue={"black"} 
                    PillColor_IfFalse={"none"} 
                    text_IfTrue={"Suspended"}
                    className={classes.PillHeight} 
                  />
                }
          </div>
        )
      }
    },
    {
      hidden: !columnVisibility['group'] ?? false,
      id: 'group',
      header: () => (
        <div className={classes.tableTitleText}>
          <h3>Group</h3>
        </div>
      ),
      filterLabel: "Group",
      accessorKey: 'name',
      cell: (row) => {
        let companyName = row.row.original.name;
        if (companyName == "RE:SURE") {
          companyName = "RESURE"
        }

        const companyColors = {
          EyeSpyFX: "rgb(100, 149, 237)",
          RESURE: "rgb(75, 0, 130)",
          PARFAS: "rgb(0, 0, 0)",
          PORTAL_SECURITY: "rgb(0, 0, 139)",
          SOLO: "rgb(178, 34, 34)",
          KNOWIO: "rgb(20, 155, 8)",
          CPS: "rgb(8, 155, 121)",
          CSC_SECURITY: "rgb(8, 150, 155)",
          EES: "rgb(94, 8, 155)"
        };

        const companyColor = companyColors[companyName] || "#6e6e6e";

        return (
          <div style={{
            maxWidth: '100px',
            overflow: 'hidden',
            whiteSpace: 'normal',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2, 
            cursor: "pointer", color: "white", fontWeight: "600"}} onClick={() => HandleUipiodOnClick(row.row.original.uuid)}>
            <Pill_Widget 
            boolean={true} 
            PillColor_IfTrue={companyColor} 
            PillColor_IfFalse={"#626a67"} 
            text_IfTrue={row.row.original.name}
            className={classes.PillHeight} />
          </div>
        )
      }
    },
    {
      hidden: !columnVisibility['actions'] ?? false,
      id: 'actions',
      filterLabel: "Settings",
      cell: (row) => {
        let items = [
          {
            image: revokeAccess,
            title: "Revoke access"
          },
          {
            image: ChangeRole,
            title: "Change role"
          },
          { divider: true }, 
          {
            image: SuspendIcon,
            title:
              row.row.original.status !== "suspended"
                ? "Suspend Site"
                : "Unsuspend Site",
          },
          {
            image: ClearLogsIcon,
            title: "Clear Logs",
          },
          {
            image: ChangePortalNameIcon,
            title: "Change portal name",
          },
          {
            image: AddToGroupIcon,
            title: "Add to group"
          }
        ];

        const firmware = row.row.original.firmware.split('.')
        const firmwareMajor = firmware[0]
        const firmwareMinor = firmware[1]
        const DeviceType = row.row.original.type;
        const isOnline = row.row.original.online == "YES" ? true : false;
        
        const deviceDetails = JSON.parse(row.row.original.deviceDetails);
        const camectIntegationType = deviceDetails.camectIntegrationType;

        if ((firmwareMajor > 1 || (firmwareMajor == 1 && firmwareMinor >= 9)) && DeviceType != 'V') {
          items.splice(4, 0, {
            image: CamectIntegrationIcon,
            title: "Change camect integration type",
          },);
        }

        if ((firmwareMajor > 1 || (firmwareMajor == 1 && firmwareMinor >= 7))) {
          items.splice(5, 0, {
            image: CopyConfigIcon,
            title: "Copy site configuration",
          });
        }

        if (isOnline && DeviceType != "CC") {
            items.splice(6, 0, {
              image: RebootIcon,
              title: "Reboot site",
            })
        }

        if (isOnline){
          items.splice(7, 0,
            {
              image: ResetIcon,
              title: "Reset site",
            });
        }

        const ifSettingsCogIsClicked = openDropdownRowId === row.row.id
        const suspend = row.row.original.status == "suspended" ? "available" : "suspended";

        return <DropdownList
          item={row.row.id}
          icon={SettingsCog}
          onClick={handleSettingsClick}
          ifSettingsCogIsClicked={ifSettingsCogIsClicked}
          ref={dropdownRef}
          title={"Actions"}
          className={classes.container}
          onUserActionClick={(userAction) =>
            UserActionHandler(
              userAction,
              suspend,
              row.row.original.id,
              row.row.original.uuid,
              userID,
              row.row.original.name,
              row.row.original.key,
              camectIntegationType,
              row.row.original.tag,
              row.row.original,
              deviceDetails,
              row.row.original.type
            )
          }
          listItems={items}
        />
      },
    }
  ]

  const tableColumnsFiltered = tablecolumns.filter(column => !column.hidden);

  if (offlineOnly){
    tablecolumns = tableColumnsOfflineOnly
  }
  if (isUsersSites){
    tablecolumns = tableColumnsUserSites
  }
  let tableData = SiteDevices;

  useEffect(() => {
    if (selectAllCancelled){
      setSelectAll(false)
    }
  }, [selectAllCancelled])

  const HandleSelectAll = () => {
    if (selectAll == null || !selectAll){
      setSelectAll(true)
    } else if (selectAll){
      setSelectAll(false)
    }
  }

  return (
    <>
      <Modal ref={ModalDialog} title={modalTitle} modalName={"Site View"} HandleShowModal={HandleShowModal}>
        {modalComponent}
      </Modal>

      <div className={`${classes.table} ${classes.center} `}>
        <ListCard secondaryClassName={classes["Card-container"]}>
          
          <div className={classes["tableCardLayoutUI-wrapper"]} style={{ padding: "0px 10px 0px 10px" }}>
            <Suspense fallback={
              <div className={classes["loadingSpinner-container"]}>
                <Oval stroke="var(--Primary-Color)" height={200} width={100} speed={1.5} />
              </div>}>

              
              <Await resolve={SiteDevices}>
                {(loadedtableData) =>
                <div style={{ width: "auto", minWidth: "1000px", height: "100%", minHeight:"500px" }} >
                {(groupMode || addAccessMode || editUserAccess || sitesForGroupMode) && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: "0px", marginBottom: "0px" }}>
                        <input
                          type="checkbox"
                          id={`checkbox`}
                          className={classes["circular-checkbox"]}
                          onChange={() => HandleSelectAll()}
                        />
                        <label
                          style={{
                            marginRight: "10px", 
                            marginLeft: "10px", // adjust left margin to make it consistent
                            display: 'flex', // ensures the label and text are aligned correctly
                            alignItems: 'center'
                          }}
                          htmlFor={`checkbox`}
                          className={classes['custom-checkbox']}
                        />
                        <span>Select all</span>
                      </div>
                    </div>
                )}
                <br></br>
                  <BasicTable
                    data={loadedtableData}
                    columns={tablecolumns}
                    allColumns={tablecolumns}
                    filtering={filtering}
                    rowClick={false}
                    ShowPagination={false}
                    PageSize={5000}
                    paginationOptions={[25, 50, 100]}
                    showFilterButton={false}
                    isSortable={isSortable}
                  />
                  </div>
                }
              </Await>
            </Suspense>
            <br></br>
            <br></br>
            {!hideControls && (
              <GridPaginationControls
                pageIndex={pageFetchedIndex}
                nextPage={HandleNextPage}
                previousPage={HandlePreviousPage}
                setPageIndex={HandleSetPageIndex}
                pageCount={totalPages}
                setPageSize={HandlePageSize}
                PageSize={pageSize}
                totalDevices={totalDevices}
                setTotalDevices={setTotalDevices}
                hidePageSize={offlineOnly}
              />
            )}
          </div>
        </ListCard>
      </div>
    </>
  )
}

export default DevicesList;