import { useState, useEffect, useRef } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import styles from "./resetPin.module.css"; // Import CSS module
import checkmark from '../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/check.png'

const ResetPin = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const token = searchParams.get("token");
    let appName = searchParams.get("appName")
    const [appLink, setAppLink] = useState("");
    const [isVisible, setIsVisible] = useState(true)

    if (appName == "RE:SURE"){
        appName = "RESURE"
    }
    if (appName == "Magnum Security" || appName == "MAGNUM_SECURITY"){
        appName = "Magnum"
    }

    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (appName == "RESURE" || appName == "RE:SURE"){
            if (/android/i.test(userAgent)) {
                setAppLink("https://play.google.com/store/apps/details?id=resureio.resure.com.resureio&hl=en_GB&gl=US"); // Android deep link
            } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                setAppLink("https://apps.apple.com/us/app/re-sure-i-o/id1046871278"); // iOS deep link
            } else {
                setIsVisible(false); // Fallback for desktop users
            }
        } else if (appName == "KNOWIO" || appName == "Knowio"){
            if (/android/i.test(userAgent)) {
                setAppLink("https://play.google.com/store/apps/details?id=ipio.eyespyfx.com.knowio&hl=en_US"); // Android deep link
            } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                setAppLink("https://apps.apple.com/ie/app/knowio/id6476141189"); // iOS deep link
            } else {
                setIsVisible(false); // Fallback for desktop users
            }
        } else if (appName == "Magnum Security" || appName == "MAGNUM_SECURITY" || appName == "Magnum"){
            if (/android/i.test(userAgent)) {
                setAppLink("https://play.google.com/store/apps/details?id=ipio.eyespyfx.com.magnum&hl=en&gl=US"); // Android deep link
            } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                setAppLink("https://apps.apple.com/tr/app/magnum-security/id6451967278"); // iOS deep link
            } else {
                setIsVisible(false); // Fallback for desktop users
            }
        } else if (appName == "Portal Security" || appName == "PORTAL_SECURITY"){
            if (/android/i.test(userAgent)) {
                setAppLink("ttps://play.google.com/store/apps/details?id=ipio.eyespyfx.com.portal&hl=en&gl=US"); // Android deep link
            } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                setAppLink("https://apps.apple.com/gb/app/portal-security/id1551776651"); // iOS deep link
            } else {
                setIsVisible(false); // Fallback for desktop users
            }
        } else if (appName == "Parfas" || appName == "PARFAS"){
            if (/android/i.test(userAgent)) {
                setAppLink("https://play.google.com/store/apps/details?id=ipio.eyespyfx.com.parfas&hl=en_ZA"); // Android deep link
            } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                setAppLink("https://apps.apple.com/gb/app/parfas-in-control/id1439793903"); // iOS deep link
            } else {
                setIsVisible(false); // Fallback for desktop users
            }
        }
    }, []);

    return (
        <div className={`${styles.container} ${styles[appName]}`}>
            <div className={`${styles.card} ${styles[appName]}`}>
                <img style={{width: "80px", height:"80px"}} src={checkmark}></img>
                <h1 className={styles.title}>Success 🎉</h1>
                <p className={styles.subTitle}>Your PIN has been reset successfully, you can now return to the app</p>
            <br></br>
                {appLink && isVisible && (
                    <a href={appLink} style={{ padding: "10px 20px", background: "#007bff", color: "white", borderRadius: "5px", textDecoration: "none" }}>
                        Open App
                    </a>
                )}
            </div>
        </div>
    );
};

export default ResetPin;
