import React, { Suspense, useMemo, useState, useEffect, useRef } from 'react'
import Oval from 'react-loading-icons/dist/esm/components/oval';
import { useDispatch, useSelector } from 'react-redux';
import { Await, defer, useLoaderData, useNavigate } from 'react-router-dom';
import { addSitesToGroup, enableUserNotifications, getCompanyUserList, getGroups, getUsersDevices, removeUser, switchInstallerMode } from '../../Api Methods/Api';
import DevicesGrid from '../../components/GridList/DevicesGrid';
import FilterUsersDeviceSearch from '../../components/PowerSearch/FilterUsersDeviceSearch';
import ActivityTableLayout from '../../components/Table/ActivityTableLayout';
import PortalUsersTableLayout from '../../components/Table/PortalUsersTableLayout';
import UserDevicesTableLayout from '../../components/Table/UserDevicesTableLayout';
import SearchBar from '../SearchView/Search/SearchBar';
import PortalActivityPage from '../PortalActivity/PortalActivityPage';
import classes from "./UserDevicesPage.module.css"
import DataManager from '../../hooks/DataManager';
import DevicesList from '../../components/GridList/DevicesList';
import DeviceLoadingGrid from '../../components/GridList/DeviceLoadingGrid';
import { da } from 'date-fns/locale';
import Stats2 from '../../components/UI/Stats/Stats2';
import Modal from '../../components/Modal/Modal';
import confirmation from '../../../src/assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/confirmation.png'
import check from '../../../src/assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/check.png'
import fail from '../../../src/assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/cross.png'
import loadingIcon from '../../../src/assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/loading.png'
import Button1 from '../../components/UI/Button/Button1';
import DropDownForm from '../../components/forms/DropdownCreateGroup';

const UserDevicesPage = () => {

  const dataManager = new DataManager()

  const ModalDialog = useRef();

  const HandleShowModal = () => {
    ModalDialog.current.open();
    setChangeNotifications(false)
      setChangePortalAdmin(false)
  };

  const HandleCloseModal = () => {
      ModalDialog.current.close();
      setChangeNotifications(false)
      setChangePortalAdmin(false)
  };

  const [modalComponent, setModalComponent] = useState();
  const [modalTitle, setModalTitle] = useState();
  const navigate = useNavigate();
  const [UserDevices, setUserDevices] = useState([]);
  const [userObj, setUserObj] = useState(dataManager.getItem('clickedUserObj'))
  const portalAccess = userObj.portalAccess == null || userObj.portalAccess == "" || userObj.portalAccess == '' ? "No" : "Yes"
  console.log(portalAccess)
  console.log(userObj.portalAccess)
  const notifications = userObj.notifications == "yes" ? "Enabled" : "Disabled"
  const lastLoggedInDate = new Date(userObj.lastLoggedIn)
  const userSinceDate = new Date(userObj.created)
  const formattedLastLoggedIn = lastLoggedInDate.toLocaleString("en-GB", {
    day: "numeric", 
    month: "long", 
    year: "numeric",
  });
  const formattedUserSinceDate = userSinceDate.toLocaleString("en-GB", {
    day: "numeric", 
    month: "long", 
    year: "numeric",
  });
  const client = useSelector(state => state.auth.client);
  const token = useSelector(state => state.auth.token);
  let ClickedUserID = dataManager.getItem('clickedUserID')
  const companyName = useSelector(state => state.auth.companyName);
  const [update, setUpdate] = useState(0)
  const pageFetchedIndex = dataManager.getItem('pageIndex')
  const [loading, setIsLoading] = useState(true)
  const [filteredData, setFilteredData] = useState([]);
  const [changeNotifications, setChangeNotifications] = useState(false)
  const [changePortalAdmin, setChangePortalAdmin] = useState(false)
  const [editUserAccess, setEditUserAccess] = useState(false)
  const [sitesForGroupMode, setSitesForGroupMode] = useState(false)
  const [buttonTitle, setButtonTitle] = useState("Edit access")
  const [addToGroupButtonTitle, setAddToGroupButtonTitle] = useState("Add sites to a group")
  const [sitesToRemove, setSitesToRemove] = useState([]);
  const [sitesForGroup, setSitesForGroup] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("")
  const myUserID = useSelector(state => state.auth.userID)
  const [groups, setGroups] = useState()
  const [selectAllCancelled, setSelectAllCancelled] = useState(false)
  // const [selectAll, setSelectAll] = useState(false)

  const apiData = {
    token,
    client
  }

  const HandleUsersDevices = async () => {
    if (dataManager.getItem('clickedOnHeader')){
      ClickedUserID = sessionStorage.getItem('userID');
      dataManager.setItem('clickedOnHeader', false)
    }
    const data = await getUsersDevices(apiData, ClickedUserID, companyName, dispatch)
    setFilteredData(data)
    setUserDevices(data)
    console.log(data)
    setIsLoading(false)
  }

  const dispatch = useDispatch();
  useEffect(() => {
    HandleUsersDevices();
  }, []);


  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const handleDeviceFilter = () => {
      const filtered = UserDevices.filter((item) =>
        Object.values(item)
          .some((value) => value?.toString().toLowerCase().includes(searchQuery.toLowerCase()))
      );
      dataManager.setItem('userSitesSearchTerm', searchQuery); 
      setFilteredData(filtered); 
    };
  
    if (searchQuery !== dataManager.getItem('userSitesSearchTerm')) {
      handleDeviceFilter(); 
    }
  }, [searchQuery]);

  const HandleEditUser = async (task) => {
    switch (task){
      case 'notification' :
        //pertform change NC API
        let newState = userObj.notifications == 'yes' ? "no" : "yes"
        const responseData = await enableUserNotifications(apiData, userObj.id, newState, dispatch)
        if (responseData) {
          const newStateFormatted = newState == "yes" ? "enabled" : "disabled"
          console.log(userObj)
          setUserObj((prevUserObj) => ({
            ...prevUserObj,
            notifications: newState,
          }));
          HandleCloseModal();
          setChangeNotifications(false)
          successModal(`Successfully ${newStateFormatted} notifications for ${userObj.fullName}`)
        } else {
          HandleCloseModal();
          setChangeNotifications(false)
          errorModal(`Failed to update notifications for ${userObj.fullName}`)
        }
        
      break;
      case 'portalAdmin':
        //perform change installer API
        const newValue = userObj.portalAccess !== "" ? "" : "INSTALLER"
        const response = await switchInstallerMode(apiData, userObj.id, newValue)
        if (response) {
          const newValueFormatted = newValue == "" ? "revoked" : "granted"
            //modify userObj to update installer value
          setUserObj((prevUserObj) => ({
            ...prevUserObj,
            portalAccess: newValue,
          }));

          HandleCloseModal();
          setChangePortalAdmin(false)
          successModal(`Successfully ${newValueFormatted} app admin privilages for ${userObj.fullName}`)
        } else {
          HandleCloseModal()
          setChangePortalAdmin(false)
          errorModal(`Failed to update app admin privilages for ${userObj.fullName}`)
        }
        
      break;
    }
  }

  const confirmModal = (message, task) => {
      setModalTitle("Confirm")
      setModalComponent(
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <img className={classes.ModalIcon} src={confirmation}>
          </img>
          <br></br>
          <br></br>
          <div>
              <h3 style={{ textAlign: "center", color:"#858585"}}>{message}</h3>
          </div>   
          <br></br>
          <br></br>
          <div>
            <br></br>
            <Button1 onClick={() => HandleEditUser(task)} style={{width:"100%"}}>Confirm</Button1>
            <br></br>
            <Button1 onClick={HandleCloseModal} style={{width:"100%"}}>Cancel</Button1>
          </div>
        </div>
      )
      HandleShowModal();
  }

  const successModal = (message) => {
    setModalTitle('Success')
    setModalComponent(
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <img className={classes.ModalIcon} src={check}>
        </img>
        <br></br>
        <br></br>
        <div>
            <h3 style={{ textAlign: "center", color:"#858585"}}>{message}</h3>
        </div>   
        <br></br>
        <br></br>
        <div>
          <Button1 onClick={HandleCloseModal} style={{width:"100%"}}>Close</Button1>
        </div>
      </div>
    )
    HandleShowModal();
  }

  const errorModal = (message) => {
    setModalTitle('Error')
    setModalComponent(
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <img className={classes.ModalIcon} src={fail}>
        </img>
        <br></br>
        <br></br>
        <div>
            <h3 style={{ textAlign: "center", color:"#858585"}}>{message}</h3>
        </div>   
        <br></br>
        <br></br>
        <div>
          <Button1 onClick={HandleCloseModal} style={{width:"100%"}}>Close</Button1>
        </div>
      </div>
    )
    HandleShowModal();
  }

  useEffect(() => {
    if (changeNotifications){
      const newValue = userObj.notifications == 'no' ? "enable" : "disable"
      confirmModal(`Are you sure you want to ${newValue} notifications for ${userObj.fullName}?`, "notification");
    }
    
  }, [changeNotifications])

  useEffect(() => {
    console.log(changePortalAdmin)
    if (changePortalAdmin){
      const newValue = userObj.portalAccess !== "" ? "revoke" : "grant"
      confirmModal(`Are you sure you want to ${newValue} app admin privilages for ${userObj.fullName}?`, "portalAdmin");
    }
  }, [changePortalAdmin])

  useEffect(() => {
    console.log(sitesToRemove)
  }, [sitesToRemove])

  useEffect(() => {
    console.log(sitesForGroup)
  }, [sitesForGroup])
  
  const editUserSiteAccess = () => {
    setButtonTitle(!editUserAccess ? "Cancel" : "Edit access")
    setEditUserAccess(!editUserAccess);

    if (buttonTitle == "Cancel"){
      setSitesToRemove([])
      setSelectAllCancelled(true)
    }
  }

  const HandleRevokeAccess = async () => {
    pleaseWaitModal(sitesToRemove.length);
    if (sitesToRemove.length > 0){
      for (const site of sitesToRemove) {
        const { deviceUUID, deviceID } = site;
        const success = await removeUser(apiData, deviceID, deviceUUID, myUserID, userObj.id, dispatch);
    
        if (success) {
          setFilteredData(prevFilteredData => prevFilteredData.filter(site => site.deviceUUID !== deviceUUID));
          console.log(`Successfully removed site with UUID: ${deviceUUID}`);
        } else {
          console.log(`Failed to remove site with UUID: ${deviceUUID}`);
        }
      }
      HandleCloseModal();
      setEditUserAccess(false)
      setButtonTitle("Edit access")
      successModal(`${userObj.fullName}'s access successfully revoked from ${sitesToRemove.length} site(s)`)
      HandleUsersDevices()
      setSitesToRemove([])
    } else {
      errorModal("Please select a site")
    }
  }

  const HandleGiveAccess = () => {
    //dataManager.setItem('isGroupMode', true);
    dataManager.setItem('isAddAccessMode', true)
    navigate('/AllSites/');
  }

  const pleaseWaitModal= (siteCount) => {
    setModalTitle("Please wait")
    setModalComponent(
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <img className={classes.ModalIcon} src={loadingIcon}>
        </img>
        <br></br>
        <br></br>
        <div>
            <h3 style={{ textAlign: "center", color:"#858585"}}>{`Revoking ${userObj.fullName}'s access from ${siteCount} site(s)`}</h3>
        </div>   
        <br></br>
        <br></br>
      </div>
    )
    HandleShowModal();
  }

  const waitModal= (siteCount, groupName) => {
    setModalTitle("Please wait")
    setModalComponent(
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <img className={classes.ModalIcon} src={loadingIcon}>
        </img>
        <br></br>
        <br></br>
        <div>
            <h3 style={{ textAlign: "center", color:"#858585"}}>{`Adding ${siteCount} sites to ${groupName}`}</h3>
        </div>   
        <br></br>
        <br></br>
      </div>
    )
    HandleShowModal();
  }


  const HandleAddSitesForGroup = () => {
    setAddToGroupButtonTitle(!sitesForGroupMode ? "Cancel" : "Add sites to a group")
    setSitesForGroupMode(!sitesForGroupMode);

    if (addToGroupButtonTitle == "Cancel"){
      setSitesForGroup([])
      setSelectAllCancelled(true)
    }
  }

  const ShowSelectGroup = async () => {
    if (sitesForGroup.length > 0){
      const allGroups = await getGroups(apiData, dispatch);
      const groupNames = allGroups.map(listOptions => listOptions.name);
      console.log(groupNames)
          setModalTitle("Add site to group")
          setModalComponent(
            <div>
              <DropDownForm label={"Please select a group below"} dropDownOptions={groupNames} setSelectedGroup={setSelectedGroup} />
              <br></br>
              <Button1 onClick={() => AddSitesToGroup(allGroups)}>Add to group</Button1>
            </div>
            
          )
          HandleShowModal();
    } else {
      errorModal("Please select a site")
    }
  }

  const AddSitesToGroup = async (groups) => {
    if (sitesForGroup.length > 0) {
      const group = groups.find(g => g.name === dataManager.getItem('selectedGroup'));

      let body = {
        groupID: group.id,
        groupName: dataManager.getItem('selectedGroup'),
        groupDevices: sitesForGroup,
      }
      const response = await addSitesToGroup(apiData, body, dispatch)
      if (response.success){
        HandleCloseModal();
        waitModal(sitesForGroup.length, dataManager.getItem('selectedGroup'))
        successModal(`${sitesForGroup.length} sites successfully added to ${dataManager.getItem('selectedGroup')}`, false);
        setSitesForGroupMode(false)
        setSitesForGroup([])
        setAddToGroupButtonTitle("Add sites to a group")
        HandleUsersDevices()
      } else {
        console.log("failed")
      }
    } else {
      errorModal("Please select a site");
    }
  }


  return (  
    <>
    <Modal ref={ModalDialog} title={modalTitle} modalName={""} HandleShowModal={HandleShowModal}>
          {modalComponent}
    </Modal>
    
    <Await resolve={filteredData}>
      {(loadedUserDevices) => {
        return (
          <>
          <div className={classes.container}>
            <Stats2 title={"Name"} value={userObj.fullName}  isLoadingStats={false} isStatic={true} />
            <Stats2 title={"Email address"} value={userObj.email}  isLoadingStats={false} isStatic={true} />
            <Stats2 title={"User since"} value={formattedUserSinceDate}  isLoadingStats={false} isStatic={true} /> 
            <Stats2 title={"Last logged in"} value={formattedLastLoggedIn}  isLoadingStats={false} isStatic={true} /> 
            <Stats2 title={"Total access"} value={filteredData.length}  isLoadingStats={false} isStatic={true} /> 
            <Stats2 title={"Notifications"} value={notifications}  isLoadingStats={false} isStatic={true} changeNotifications={changeNotifications} setChangeNotifications={setChangeNotifications} />
            <Stats2 title={"App admin"} value={portalAccess}  isLoadingStats={false} isStatic={true} changePortalAdmin={changePortalAdmin} setChangePortalAdmin={setChangePortalAdmin} />
          </div>
          
            <div style={{ width: "100%", minWidth: "1000px", height: "100%", minHeight:"500px" }} >
              {loading ? (
                  <div style={{ display: "flex", width: "100%" }}>
                      <DeviceLoadingGrid
                      CardCount={
                          20
                      }
                      />
                  </div>
                ) : (
                <div style={{width: "100%"}}>
                  <div className={classes.searchContainer}>
                    <SearchBar
                      type="text"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      style={{
                        padding: "10px", // Adjust padding to reduce height
                        width: "300px", // Set a specific width
                        fontSize: "14px", // Adjust font size for better proportions
                        boxSizing: "border-box", // Ensure consistent sizing
                        borderRadius: "4px", // Add slight rounding for aesthetics
                      }}
                      
                    />
                   
                    

                    {/* Buttons wrapper */}
                    <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>

                    {(!editUserAccess && sitesForGroupMode)&& ( <Button1
                      style={{
                        height: "55px",
                        width: "160px",
                        fontSize: "16px",
                        marginBottom: "0px",
                      }} 
                      onClick={ShowSelectGroup}>
                        Add to group
                      </Button1> )}

                      {!editUserAccess && ( <Button1
                      style={{
                        height: "55px",
                        width: "160px",
                        fontSize: "16px",
                        marginBottom: "0px",
                      }} 
                      onClick={HandleAddSitesForGroup}>
                        {addToGroupButtonTitle}
                      </Button1> )}

                      

                    {(editUserAccess && !sitesForGroupMode)&& 
                      <Button1
                        style={{
                          height: "55px",
                          width: "160px",
                          fontSize: "16px",
                          marginBottom: "0px",
                        }}
                        onClick={() => HandleRevokeAccess()}
                      >
                        Revoke access
                      </Button1>}

                      { !sitesForGroupMode && (<Button1
                        style={{
                          height: "55px",
                          width: "140px",
                          fontSize: "16px",
                          marginBottom: "0px",
                        }}
                        onClick={editUserSiteAccess}
                      >
                        {buttonTitle}
                      </Button1>)}

                      { (!sitesForGroupMode && !editUserAccess)&& (<Button1
                        style={{
                          height: "55px",
                          width: "140px",
                          fontSize: "16px",
                          marginBottom: "0px",
                        }}
                        onClick={HandleGiveAccess}
                        >
                        Add sites
                      </Button1>)}

                      
                    </div>

                    {searchQuery !== "" && (
                      <div>
                        <p>{filteredData.length} results</p>
                      </div>
                    )}
                  </div>

                  <DevicesList 
                    devices={loadedUserDevices}
                    filtering={""}
                    pageFetchedIndex={1}
                    isUsersSites={true}
                    groupMode={false}
                    update={update}
                    setUpdate={setUpdate}
                    hideControls={true}
                    isSortable={true}
                    editUserAccess={editUserAccess}
                    sitesForGroup={sitesForGroup}
                    setSitesForGroup={setSitesForGroup}
                    sitesForGroupMode={sitesForGroupMode}
                    setSitesForGroupMode={setSitesForGroupMode}
                    sitesToRemove={sitesToRemove}
                    setSitesToRemove={setSitesToRemove}
                    HandleGiveAccess={HandleGiveAccess}
                    selectAllCancelled={selectAllCancelled}
                  />
                  </div>
                )
              }
            </div>
        </>
        )
      }
    } 
  </Await>
  </>
  )
}

export default UserDevicesPage

export async function loader() {
  const token = sessionStorage.getItem('token');
  const client = sessionStorage.getItem('client');
  const companyName = sessionStorage.getItem('companyName');
  const userID = sessionStorage.getItem('ClickedUserID');

  const apiData = {
    token,
    client
  }

  return defer({
    //userDevices: getUsersDevices(apiData, userID, companyName)
  })
}
