import { useState, useEffect, useRef } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import styles from "../../../ResetPin/resetPin.module.css"; // Import CSS module
import checkmark from '../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/check.png'
import knowioLogo from '../../../assets/knowio-logo.png'
import parfasLogo from '../../../assets/parfas-logo.png'
import portalLogo from '../../../assets/portal-logo.png'
import magnumLogo from '../../../assets/magnum-logo.png'
import resureLogo from '../../../assets/resure-logo.png'
import { set } from "date-fns";

const PortalPasswordResetSuccess = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    let appName = searchParams.get("appName")

 
    if (appName == "RE:SURE"){
        appName = "RESURE"
    }
    if (appName == "Magnum Security" || appName == "MAGNUM_SECURITY"){
        appName = "Magnum"
    }

    let logoMapping = [
        knowioLogo, 
        resureLogo, 
        parfasLogo, 
        magnumLogo, 
        portalLogo
    ]

    let icon;

    switch (appName){
        case "RESURE":
            icon = logoMapping[1]
        break;
        case "KNOWIO":
            icon = logoMapping[0]
        break;
        case "Magnum":
            icon = logoMapping[3]
        break;
        case "PORTAL_SECURITY":
            icon = logoMapping[4]
        break;
        case "PARFAS":
            icon = logoMapping[2]
        break;
    }

    return (
        <div className={`${styles.container} ${styles[appName]}`}>
            <div className={`${styles.card} ${styles[appName]}`}>
                <img style={{width: "100px", height:"100px", borderRadius:"10px"}} src={icon}></img>
                <h1 className={styles.title}>Password reset successfully! 🎉</h1>
                <p className={styles.subTitle}>You can now log into the Portal</p>
            <br></br>
                
                    <a href="https://portal.inniovate.com" style={{ padding: "10px 20px", background: "#007bff", color: "white", borderRadius: "5px", textDecoration: "none" }}>
                        Back to Portal
                    </a>
                
                <br></br>
            </div>
        </div>
    );
};

export default PortalPasswordResetSuccess;
