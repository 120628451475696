import { useState, useEffect, useRef } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import styles from "../ResetPin/resetPin.module.css"; // Import CSS module
import { acceptInvite, createNewPin, removeUser } from "../Api Methods/Api";
import { useDispatch } from "react-redux";
import knowioLogo from '../assets/knowio-logo.png'
import resureLogo from '../assets/resure-logo.png'

const FullAccountRegistration = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const token = searchParams.get("token");
    let appName = searchParams.get("appName");
    if (appName == "RE:SURE"){
        appName = "RESURE"
    }

    let logoMapping = [
        knowioLogo, 
        resureLogo
    ]

    let icon = appName == "RESURE" ? logoMapping[1] : logoMapping[0]

    const [name, setName] = useState("");
    const [pin, setPin] = useState(["", "", "", ""]);
    const [confirmPin, setConfirmPin] = useState(["", "", "", ""]);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("")
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    
    const pinRefs = [useRef(), useRef(), useRef(), useRef()];
    const confirmRefs = [useRef(), useRef(), useRef(), useRef()];
    const dispatch = useDispatch();

    const handleChange = (index, value, isConfirm = false) => {
        if (!/^\d?$/.test(value)) return; // Only allow one digit (0-9)

        const updatedPin = isConfirm ? [...confirmPin] : [...pin];
        updatedPin[index] = value;

        if (isConfirm) {
            setConfirmPin(updatedPin);
        } else {
            setPin(updatedPin);

            // Move focus to confirm PIN section when all 4 digits are entered
            if (updatedPin.join("").length === 4) {
                confirmRefs[0].current.focus();
            }
        }

        // Move to next field if a digit is entered (except last box)
        if (value && index < 3) {
            (isConfirm ? confirmRefs[index + 1] : pinRefs[index + 1]).current.focus();
        }
    };

    const handleKeyDown = (index, e, isConfirm = false) => {
        if (e.key === "Backspace" && !e.target.value && index > 0) {
            // Move to previous field on backspace
            (isConfirm ? confirmRefs[index - 1] : pinRefs[index - 1]).current.focus();
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newPin = pin.join("");
        const confirmedPin = confirmPin.join("");
        if (name == ""){
            setMessage("Please enter your name")
            return;
        }

        if (newPin.length !== 4 || confirmedPin.length !== 4) {
            setMessage("PIN must be exactly 4 digits");
            return;
        }

        if (newPin !== confirmedPin) {
            setMessage("PINs do not match");
            return;
        }

        if (password == "" || confirmPassword == ""){
            setMessage("Please create a Portal password")
            return;
        }

        if (password !== confirmPassword){
            setMessage("Passwords do not match")
            return;
        }

        setLoading(true);

        try {
            const body = {
                token: token, 
                name: name, 
                pin: pin,
                portalPassword: password
            }

            //const response = await acceptInvite(body)
            // console.log(response)
            // if (response.success){
            //     navigate(`/RegisterSuccess?token=${token}&appName=${appName}&userName=${name}`) //go to success screen with app download buttons
            // } else {
            //     setMessage("Error creating account. Please contact support")
            // }

        } catch (error) {
            setMessage("Network error, please check your connection & try again. Error: " + error)
        }
        
        setLoading(false);
    };

    return (
        <div className={`${styles[appName]} ${styles.container}`}>
           
            <div className={`${styles.card} ${styles[appName]}`}>
            <img style={{height: "70px", width:"70px", borderRadius:"10px"}} src={icon}/>
            {message && <p className={styles.errorMessage}>{message}</p>}
            <h1 className={`${styles.title} ${styles[appName]}`}>Create admin account</h1>
            <p className={`${styles.subTitle} ${styles[appName]}`}>Enter your first and last name below</p>
            
            <input
                type="text"
                className={`${styles.inputField} ${styles[appName]}`}
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Full Name"
                required
            />
                <h2 className={`${styles.subTitle} ${styles[appName]}`}>Create a 4-digit PIN</h2>
                <p className={`${styles.subTitle} ${styles[appName]}`}>This PIN is used to log into the mobile app</p>
                <form onSubmit={handleSubmit}>
                    {/* New PIN Input Boxes */}
                    <div className={`${styles.pinContainer} ${styles[appName]}`}>
                        {pin.map((digit, index) => (
                            <input
                                style={{ color: "white" }}
                                key={index}
                                type="text"
                                inputMode="numeric"
                                maxLength="1"
                                className={`${styles.pinInput} ${styles[appName]}`}
                                value={digit}
                                onChange={(e) => handleChange(index, e.target.value)}
                                onKeyDown={(e) => handleKeyDown(index, e)}
                                ref={pinRefs[index]}
                                
                            />
                        ))}
                    </div>

                    <p className={`${styles.subTitle} ${styles[appName]}`}>Confirm your PIN:</p>

                    {/* Confirm PIN Input Boxes */}
                    <div className={`${styles.pinContainer} ${styles[appName]}`}>
                        {confirmPin.map((digit, index) => (
                            <input
                                style={{ color: "white" }}
                                key={index}
                                type="text"
                                inputMode="numeric"
                                maxLength="1"
                                className={`${styles.pinInput} ${styles[appName]}`}
                                value={digit}
                                onChange={(e) => handleChange(index, e.target.value, true)}
                                onKeyDown={(e) => handleKeyDown(index, e, true)}
                                ref={confirmRefs[index]}
                            />
                        ))}
                    </div>

                    <br></br>
                    <h2 className={`${styles.subTitle} ${styles[appName]}`}>Create a password</h2>
                    <p className={`${styles.subTitle} ${styles[appName]}`}>This password is used to log into the Portal</p>
                    <input
                        type="text"
                        className={`${styles.inputField} ${styles[appName]}`}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Portal password"
                        required
                    />
                    <p className={`${styles.subTitle} ${styles[appName]}`}>Confirm your password</p>
                    <input
                        type="text"
                        className={`${styles.inputField} ${styles[appName]}`}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Confirm portal password"
                        required
                    />

                    <button type="submit" disabled={loading} className={`${styles.button} ${styles[appName]}`}>
                        {loading ? "Creating account..." : "Create account"}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default FullAccountRegistration;
