import { useDispatch } from "react-redux";
import { authActions } from "../store/auth-Slice";
import { serviceActions } from "../store/service-Slice";

const HandleResponseData = (result) => {
    console.log(result)
    const OutPutPins = [5, 6, 13, 19];

    const {
        id,
        name,
        mac,
        key,
        ip,
        host,
        uuid,
        online,
        created,
        lastLoggedIn,
        status,
        tag,
        networkType,
        deviceDetails,
        firmware,
        type,
        numInput,
        numOutput,
        subGroup,
        deviceExtraDetails,
        latestFirmware,
        deviceName,
        iglooLocks } = result.data[0];

    // Passing Single Device Details to redux store to make these details availabe to other components. e.g. header
    const DeviceDetailsParsed = JSON.parse(deviceDetails);

    const dispatch = useDispatch();

    const DeviceExtraDetailsParsed = JSON.parse(deviceExtraDetails);

    console.log(DeviceExtraDetailsParsed)
    dispatch(serviceActions.setDeviceDetails([DeviceDetailsParsed, id, DeviceExtraDetailsParsed]));

    const allPinsNotificationActive = DeviceDetailsParsed.allPinsNotificationActive;
    const allPinsNotificationSound = DeviceDetailsParsed.allPinsNotificationSound;
    const camectIntegrationType = DeviceDetailsParsed.camectIntegrationType;
    const singleDeviceName = DeviceDetailsParsed.name;
    const offlineNotificationActive = DeviceDetailsParsed.offlineNotificationActive;
    const offlineNotificationSound = DeviceDetailsParsed.offlineNotificationSound;
    const onlineNotificationActive = DeviceDetailsParsed.onlineNotificationActive;
    const onlineNotificationSound = DeviceDetailsParsed.onlineNotificationSound;
    const polarity = DeviceDetailsParsed.polarity;
    const polarityReason = DeviceDetailsParsed.polarityReason;
    

    const singleDeviceSettingData = { online, numOutput, numInput, allPinsNotificationActive, allPinsNotificationSound, camectIntegrationType, singleDeviceName, offlineNotificationActive, onlineNotificationActive, offlineNotificationSound, onlineNotificationSound, polarity, polarityReason, lastLoggedIn, iglooLocks }

    // Parsing the Pins out into OutPut Pins / Input Pins - also added the pin number as a property to the OutPut Object.
    const DeviceOutputPins = OutPutPins.map((output) => { const value = DeviceDetailsParsed[output]; return { ...value, Pin: output }; }).filter((obj) => obj !== undefined);
    let InputPins;
    if (numInput != 0) {
        let DeviceInputPins;
        if (numInput == 4) {
            const InputsPins = [21, 20, 16, 12];
            DeviceInputPins = InputsPins.map((input) => { const value = DeviceDetailsParsed[input]; return { ...value, Pin: input }; }).filter((obj) => obj !== undefined);
        } else if (numInput == 8) {
            const InputsPins = [21, 20, 16, 12, 4, 3, 2, 1];
            DeviceInputPins = InputsPins.map((input) => { const value = DeviceDetailsParsed[input]; return { ...value, Pin: input }; }).filter((obj) => obj !== undefined);
        }



    const obj = JSON.parse(deviceExtraDetails);


        //Input Device Icons
        let InputDeviceIcons;

        if (numInput == 4) {
            const InputsPins = [21, 20, 16, 12];
            //Input Device Icons
            InputDeviceIcons = InputsPins.map((input) => JSON.parse(deviceExtraDetails)[input]).filter((value) => value !== undefined);
        } else if (numInput == 8) {
            const InputsPins = [21, 20, 16, 12, 4, 3, 2, 1];
            InputDeviceIcons = InputsPins.map((input) => JSON.parse(deviceExtraDetails)[input]).filter((value) => value !== undefined);
        }


        const arrayOfInputDeviceIcons = Object.values(InputDeviceIcons);
        const arrayOfInputDeviceIconsValues = arrayOfInputDeviceIcons.map(obj => obj.icon);
        const arrayOfInputManagerOnlyValues = arrayOfInputDeviceIcons.map(obj => obj.accessLevel);

        console.log(arrayOfInputDeviceIcons)
        console.log(arrayOfInputDeviceIconsValues)
        console.log(arrayOfInputManagerOnlyValues)


        // debugger;
        InputPins = DeviceInputPins.map((item, index) => {

            let icon = arrayOfInputDeviceIconsValues[index];
            let accessLevel = arrayOfInputManagerOnlyValues[index];
            console.log(icon)
            console.log(accessLevel)

            // if extraDeviceDetails does not return icons for inputs 4,3,2,1 then pass 'default.svg'
            if (icon != undefined) {
                {
                    if (icon.includes('.png')) {
                        // //slice off .png 
                        icon = icon.slice(0, -4) /*+ '.svg';*/
                    } else {
                        icon = icon /*+ '.svg';*/
                    }
                }
            } else {
                icon = 'default.svg'
            }
            return { ...item, icon, accessLevel };
        });

    }
    //Output Device Icons
    const OutputDeviceIcons = OutPutPins.map((output) => JSON.parse(deviceExtraDetails)[output]).filter((value) => value !== undefined);
    console.log(OutputDeviceIcons)
    const arrayOfOutputDeviceIcons = Object.values(OutputDeviceIcons);
    const arrayOfOutputDeviceIconsValues = arrayOfOutputDeviceIcons.map(obj => obj.icon);
    const arrayOfOutputManagerOnlyValues = arrayOfOutputDeviceIcons.map(obj => obj.accessLevel);
    console.log(arrayOfOutputManagerOnlyValues)

    const OutputPins = DeviceOutputPins.map((item, index) => {
        let icon = arrayOfOutputDeviceIconsValues[index];
        let accessLevel = arrayOfOutputManagerOnlyValues[index];

        let DeviceUUID = uuid;
        let DeviceType = type;
        
        if (icon.includes('.png')) {
            icon = icon.slice(0, -4)
        } else {
            icon = icon
        }
        return { ...item, icon, accessLevel, DeviceUUID, DeviceType };
    });

    const ResponseData = [
        { OutputPins, InputPins, singleDeviceSettingData },
        { id, name, mac, key, ip, host, uuid, online, created, lastLoggedIn, status, tag, networkType, firmware, type, subGroup, numInput, numOutput, latestFirmware, camectIntegrationType, deviceName }
    ]
    return ResponseData;
}

export default HandleResponseData;