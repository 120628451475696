import React, { useState, useReducer, useRef, useEffect, useImperativeHandle, forwardRef } from "react";
import { useDispatch } from "react-redux";
import { GenerateLockOTP } from "../../../../../Api Methods/Api";
import Input from "../../../../CustomDropDown/Input/Input";
import Button1 from "../../../Button/Button1";

import classes from "./GenerateLockOnTimePassCodeForm.module.css"
import TimeDatePicker from "../../../TimeDatePicker/TimeDatePickerOld";

const NameReducer = (state, action) => {

    if (action.type === 'USER_INPUT') {
        return { value: action.val, isValid: action.val.length > 0 };
    }

    if (action.type === 'INPUT_BLUR') {
        return { value: state.value, isValid: state.value.length > 0 };
    }

    if (action.type === 'INPUT_CLEAR') {
        return { value: action.val, isValid: null };
    }
    //otherwise 
    return { value: '', isValid: false };
}

const SurnameReducer = (state, action) => {

    if (action.type === 'USER_INPUT') {
        return { value: action.val, isValid: action.val.length > 0 };
    }

    if (action.type === 'INPUT_BLUR') {
        return { value: state.value, isValid: state.value.length > 0 };
    }

    if (action.type === 'INPUT_CLEAR') {
        return { value: action.val, isValid: null };
    }
    //otherwise 
    return { value: '', isValid: false };
}


const GenerateLockOnTimePassCodeForm = ({ SmartLock, GenerateOTP }) => {

    const [formIsValid, setFormIsValid] = useState(false);

    const [startDate, setStartDate] = useState(null)

    const [error, setError] = useState('');

    const [endDate, setEndDate] = useState(null)

    const [pinType, setPinType] = useState(1)

    const [nameState, dispatchName] = useReducer(NameReducer, {
        value: '',
        isValid: null
    });

    const [surnameState, dispatchSurname] = useReducer(SurnameReducer, {
        value: '',
        isValid: null
    });

    const NameInputRef = useRef();
    const SurnameInputRef = useRef();

    const { isValid: NameIsValid } = nameState;
    const { isValid: SurnameIsValid } = nameState;

    useEffect(() => {
        const identifier = setTimeout(() => {
            // console.log('Checking form validity!');
            setFormIsValid(NameIsValid && SurnameIsValid)
        }, 500);

        return () => {
            // console.log('CLEANUP');
            clearTimeout(identifier);
        };
    }, [NameIsValid, SurnameIsValid]);

    const NameChangeHandler = (event) => {
        dispatchName({ type: 'USER_INPUT', val: event.target.value });
        // console.log(event.target.value)
    }

    const SurnameChangeHandler = (event) => {
        dispatchSurname({ type: 'USER_INPUT', val: event.target.value });
        // console.log(event.target.value)
    }

    const validateNameHandler = () => {
        dispatchName({ type: 'INPUT_BLUR' });
    }

    const validateSurnameHandler = () => {
        dispatchSurname({ type: 'INPUT_BLUR' });
    }

    const onClearHandler = () => {
        dispatchName({ type: 'INPUT_CLEAR', val: "" });
        dispatchSurname({ type: 'INPUT_CLEAR', val: "" });
    }

    const selectStartDate = (time, date) => {
        const formattedDateTime = `${date.startDate} ${time}:00`;
        
        setStartDate(formattedDateTime)
    };

    const selectEndDate = (time, date) => {

        const formattedDateTime = `${date.startDate} ${time}:00`;
        setEndDate(formattedDateTime)
    };

    useEffect(() => {
        if (startDate && endDate) {
          if (new Date(startDate) > new Date(endDate)) {
            setError('Start date cannot be after end date.');
          } else {
            setError('');
          }
        }
      }, [startDate, endDate]);

    const convertToUTC = (dateString) => {
        // Create a new Date object from the date string
        if (dateString != null && dateString != ""){
        const localDate = new Date(dateString);
        
        // Convert the Date object to ISO string (which is in UTC)
        const utcDateString = localDate.toISOString();
        const formattedUTCDateString = utcDateString.replace('T', ' ').replace('Z', '');
        
        return formattedUTCDateString;
        }
      };

    const dispatch = useDispatch();

    const submitHandler = (event) => {
        event.preventDefault();

        if (formIsValid && error == '') {

            const otpName = nameState.value + " " + surnameState.value;
            
            if (pinType != 3){
                setEndDate(startDate)
            }
            
            GenerateOTP(otpName, convertToUTC(startDate), convertToUTC(endDate), pinType, SmartLock.lockID);

            onClearHandler();
            return
        }
    };


    return (
        <form onSubmit={submitHandler} className={classes["container"]} method="dialog" >
            {/*This is the name input*/}
            <ul className={classes["inputs-list"]}>
                <div className={classes.label}>{"Who is the code for?"}</div>
                <li className={classes["input-container"]}>
                    <label className={classes.subTitle} ></label>
                    <Input
                        className={classes.input}
                        placeholder={"First Name..."}
                        ref={NameInputRef}
                        id="Name"
                        type={"text"}
                        isValid={nameState.isValid}
                        value={nameState.value}
                        onChange={NameChangeHandler}
                        onBlur={validateNameHandler}
                    />
                    <Input
                        className={classes.input}
                        placeholder={"Surname..."}
                        ref={SurnameInputRef}
                        id="Name"
                        type={"text"}
                        isValid={surnameState.isValid}
                        value={surnameState.value}
                        onChange={SurnameChangeHandler}
                        onBlur={validateSurnameHandler}
                    />
                </li>

                {/*This is the pincode selection*/}
                <li className={classes["input-container"]}>
                    <div className={classes.pincodeButtonContainer}>
                        <div className={classes.label} style={{ top: "-25px" }}>{"Which type of pincode?"}</div>
                        <Button1 type="button" propsclassName={pinType === 1 && classes.activeButton} style={{ width: "100%" }} onClick = {() => setPinType(1)}>Once</Button1>
                        <Button1 type="button" propsclassName={pinType === 3 && classes.activeButton} style={{ width: "100%" }} onClick = {() => setPinType(3)}>Duration</Button1>
                        <Button1 type="button" propsclassName={pinType === 2 && classes.activeButton} style={{ width: "100%" }} onClick = {() => setPinType(2)}>Permanent</Button1>
                    </div>
                </li>

                {/*This is the date selection*/}
                <li className={classes["input-container"]}>
                    <div className={classes.pincodeButtonContainer}>
                        <div className={classes.label} style={{ top: "-25px" }}>{"Choose Start & End dates"}</div>
                        <div className={classes["timeDatePicker-Container"]}>
                            <TimeDatePicker handler={selectStartDate} label={"Start at:"}/>
                            {pinType === 3 && <TimeDatePicker handler={selectEndDate} label={"End at:"} />}
                        </div>
                    </div>
                </li>
            </ul>

            <div className={classes.generateButtonLayout} style={{height: pinType != 3 && "100px"}}> 
                <Button1 className={classes.submitButton} type="submit" style={{ width: "100%"}}>Generate</Button1>
            </div>
            
        </form>
    )
}

export default GenerateLockOnTimePassCodeForm