import React, { useState, useRef, useEffect } from 'react';
import CategoryFilter from './CategoryFilter';
import SearchBar from './SearchBar';

// import img from "../../assets/PortalIconnographySVGs/PortalAppRealted_Icons/Power Search/__Search.svg";

import classes from './PowerSearch.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { PowerSearch, UserPowerSearch } from '../../Api Methods/Api';
import DataManager from '../../hooks/DataManager';

const FilterUsersSearch = ({ searchQuery, setSearchQuery, setIsLoading, placeholder, setFilteredResults, handleFilterLogic, categories, reset, setReset }) => {
    const dispatch = useDispatch();
    const [categoryFilter, setCategoryFilter] = useState([]);
    const dataManager = new DataManager()
    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);

    const ApiData = {
        token,
        client
    }

    const formRef = useRef();

    const timer = useRef();


    useEffect(() => {
        const HandleUserSearch = async (ApiData, query) => {
            
            timer.current = Date.now();
            const triggerTime = timer.current;
            const responseData = await UserPowerSearch(ApiData, query, dispatch);
            const users = responseData[0].users;

            if (timer.current == triggerTime) {
                handleFilterLogic(users, searchQuery, categoryFilter);
                
                if (users != null) {
                    //setIsLoading(false);
                }
            }

        }

        const delayDebounceFn = setTimeout(() => {
            //   Perform fetch call with the input value
            if (searchQuery !== "") {
                setIsLoading(true)
                dataManager.setItem('userSearchQuery', searchQuery)
                HandleUserSearch(ApiData, searchQuery)
            } else if (dataManager.getItem('userSearchQuery') == ""){
                //HandleUserSearch(ApiData, "")
                console.log("hit")
                setReset(reset+1)
            }
        }, 1700);


        return () => clearTimeout(delayDebounceFn);
    }, [searchQuery, categoryFilter]);


    return (
        <form className={classes.form} ref={formRef}>
            <SearchBar
                text="text"
                placeholder={placeholder}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
            <div className={classes.filters}>
                {/* {categories && <CategoryFilter setCategoryFilter={setCategoryFilter} categories={categories} />} */}
            </div>
        </form>
    );
};

export default FilterUsersSearch;
