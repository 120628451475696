import React from 'react'
import postRequest from './Request Methods/postRequest';
import { ApiEndpoints } from '../store/type/API-Endpoints';
import getRequest from './Request Methods/getRequest';
import postRequestNoAuth from './Request Methods/postRequestNoAuth';
import putRequest from './Request Methods/putRequest';
import { json, redirect } from 'react-router-dom';
import getRefreshTokenRequest from './Request Methods/getRefreshTokenRequest';
import { authActions } from '../store/auth-Slice';
import deleteRequest from './Request Methods/deleteRequest';
import deleteRequestWithBody from './Request Methods/deleteRequestWithBody';
import { useSelector } from 'react-redux';


const maxRetries = 3
let retries = 0



export const getRefreshToken = async (method) => {
    if (retries < maxRetries) {
        const uri = ApiEndpoints.refreshTokenAPI;

        const apiData = {
            refreshToken: sessionStorage.getItem("refreshToken"),
            client: sessionStorage.getItem("client")
        }

        //if no refreshToken then logout.
        if (apiData.refreshToken !== null || apiData.refreshToken === "") {
            redirect("/logout")
        }

        retries++

        const responseData = await getRefreshTokenRequest(uri, apiData);
        const token = responseData.token
        const refreshToken = responseData.refreshToken
        const client = sessionStorage.getItem("client");

        const newApiData = {
            token,
            client,
            refreshToken,
        }

        sessionStorage.setItem("token", token);
        sessionStorage.setItem("refreshToken", refreshToken)
        // window.location.reload();

        return newApiData;
    }
    else {
        retries = 0
        redirect("/logout")
    }
    return null;
}

export const resendOTP = async (email, dispatch) => {

    const uri = ApiEndpoints.resendOTPAPI;

    var body = { 'email': email };
    const responseData = await postRequestNoAuth(uri, body)

    if (responseData.data.MessageId) {
        return true
    } else {
        return false
    }
}


export const resetPortalPassword = async (apiData, email, dispatch) => {
    const uri = ApiEndpoints.resetPortalPasswordAPI;

    var body = { 'email': email };
    const response = postRequest(uri, body, apiData, dispatch)
    return response;
}

export const getDevices = async (apiData, companyName, dispatch) => {

    const uri = ApiEndpoints.getDevicesAPI + companyName
    const responseData = await getRequest(uri, apiData);
    return responseData.data
}

export const getStats = async (apiData, companyName, dispatch) => {

    const uri = ApiEndpoints.getStatsAPI + companyName;

    const responseData = await getRequest(uri, apiData, dispatch);
    return responseData.data
}


export const getDeviceEventLogs = async (apiData, DeviceUUID, dispatch) => {

    const uri = ApiEndpoints.getEventsAPI + DeviceUUID + "/Portal";

    const responseData = await getRequest(uri, apiData, dispatch);
    return responseData;
}

export const getDeviceEventLogsRanged = async (apiData, DeviceUUID, startDate, endDate, dispatch) => {
    const uri = ApiEndpoints.getEventsAPI2;

    const body = {
        "pageSize": "",
        "pageNumber": "",
        "deviceUUID": DeviceUUID,
        "startDate": startDate,
        "endDate": endDate
    }

    const events = await postRequest(uri, body, apiData, dispatch);
    return events;
}

export const getSingleDeviceUsers = async (apiData, DeviceUUID, dispatch) => {

    const uri = ApiEndpoints.getUsersAPI + DeviceUUID;

    const responseData = await getRequest(uri, apiData, dispatch);
    return responseData;
}

export const getSingleDeviceData = async (apiData, DeviceUUID, dispatch) => {

    const uri = ApiEndpoints.getSingleDeviceAPI + DeviceUUID;

    const responseData = await getRequest(uri, apiData, dispatch);
    console.log(responseData)
    return responseData;
}


export const getSensorData = async (apiData) => {

    const uri = ApiEndpoints.getSensorDataAPI + 'DCC';

    const responseData = await getRequest(uri, apiData);
    return responseData;
}

export const getActivityLog = async (apiData, dispatch) => {
    const uri = ApiEndpoints.getActivityLogAPI;

    const responseData = await getRequest(uri, apiData, dispatch);
    return responseData.data
}

export const getInactiveDevices = async (apiData) => {
    const uri = ApiEndpoints.getInactiveDevicesAPI;

    const responseData = await getRequest(uri, apiData);
    return responseData;
}

export const getCompanyUserList = async (apiData, companyName) => {
    const uri = ApiEndpoints.getCompanyUserListAPI + companyName;

    const responseData = await getRequest(uri, apiData);
    const users = await responseData.data[0];
    // const usersExtraDetails = await responseData.data[1];
    // return [users, usersExtraDetails]

    return users
}

export const getUsersDevices = async (apiData, managingUserID, companyName, dispatch) => {
    const uri = ApiEndpoints.getUsersDevicesAPI + managingUserID + '/' + companyName;
    const responseData = await getRequest(uri, apiData, dispatch);
    return responseData.data[0].devices;
}

//---- For Switch latch below
const generateUUID = () => {
    var d = new Date().getTime();

    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
}

export const switchLatch = async (apiData, userID, pin, newState, DeviceUUID, pinType, deviceType, dispatch) => {
    var allPin = "no"

    if (pin == -1) {
        allPin = "all"
    }

    var uri = ApiEndpoints.switchLatchAPI

    if (deviceType != 'V') {
        uri = (pinType == 'VIRTUAL_LATCH') ? ApiEndpoints.switchLatchExternalLinkAPI : ApiEndpoints.switchLatchAPI
    }
    else {
        uri = ApiEndpoints.switchVirtualLatchAPI
    }

    let body = { "user": userID, "pin": parseInt(pin), "state": newState, "allPins": "", "eventID": generateUUID(), "actionType": "", "uuid": DeviceUUID }
    const responseData = await postRequest(uri, body, apiData, dispatch);
    return responseData;
}

export const switchPulse = async (apiData, userID, pin, pulseLength, DeviceUUID, pinType, dispatch) => {

    const uri = (pinType == 'VIRTUAL_PULSE') ? ApiEndpoints.switchVirtualPulseAPI : ApiEndpoints.switchPulseAPI

    let body = { "user": userID, "pin": parseInt(pin), 'pulseTime': pulseLength, "eventID": generateUUID(), "uuid": DeviceUUID }

    const responseData = await postRequest(uri, body, apiData, dispatch);
    return responseData;
}


export const setTag = async (apiData, DeviceUUID, tag, dispatch) => {
    const uri = ApiEndpoints.setTagAPI + DeviceUUID + '/' + tag

    const responseData = await getRequest(uri, apiData, dispatch);
    return responseData.data.success;
}

export const changeRole = async (apiData, DeviceID, userID, role, userIDToChange, uuid, dispatch) => {
    const uri = ApiEndpoints.changeRoleAPI;
    var body = { 'deviceID': DeviceID, 'userID': userID, 'role': role, 'userIDToChange': userIDToChange, 'uuid': uuid };
    const responseData = await postRequest(uri, body, apiData, dispatch)
    return responseData;
}


export const removeUser = async (apiData, deviceID, deviceUUID, senderUserID, userToRemoveID, dispatch) => {
    const uri = ApiEndpoints.removeUserAPI + deviceID + '/' + deviceUUID + '/' + senderUserID + '/' + userToRemoveID
    const responseData = await getRequest(uri, apiData, dispatch)
    console.log(responseData)
    return responseData.data.success
}

export const suspendDevice = async (apiData, suspend, deviceID, dispatch) => {
    const uri = ApiEndpoints.suspendAPI + deviceID

    var body = { 'status': suspend };
    const response = await putRequest(uri, body, apiData, dispatch)
    return response
}

export const clearDeviceLogs = async (apiData, deviceUUID, userID, dispatch) => {
    const uri = ApiEndpoints.clearLogsAPI + deviceUUID + '/' + userID
    const response = await getRequest(uri, apiData, dispatch);
    return response
}

export const changeDeviceOwner = async (apiData, deviceUUID, newOwner, deviceName, dispatch) => {
    const uri = ApiEndpoints.changeOwnerAPI
    var body = { 'uuid': deviceUUID, 'name': newOwner };
    const response = await postRequest(uri, body, apiData, dispatch);
    return response.data.success;
}

export const changeCamectIntegration = async (apiData, uuid, integrationType, deviceName, dispatch) => {
    const uri = ApiEndpoints.changeCamectIntegrationAPI;
    var body = { 'uuid': uuid, 'integrationSetting': integrationType };
    const response = await postRequest(uri, body, apiData, dispatch)
    return response;
}

// export const copyIPIOSettings = async (data, toDeviceKey, dispatch) => {
//     const { apiData, fromDeviceUUID } = data
//     const uri = ApiEndpoints.copySettingsAPI;
//     var body = { 'fromUUID': fromDeviceUUID, 'toUIPIOID': toDeviceKey };
//     // if (toDeviceKey != "") {
//     //     postRequest(uri, body, apiData, dispatch)
//     // }
//     const response = postRequest(uri, body, apiData, dispatch);
//     return response;

// }

export const copyIPIOSettings = async (apiData, fromUUID, toUIPIOID, auto, dispatch) => {
    const uri = ApiEndpoints.copySettingsAPI;
    const body = {
        'fromUUID': fromUUID,
        'toUIPIOID': toUIPIOID,
        'auto': auto
    }
    const response = await postRequest(uri, body, apiData, dispatch);
    return response;
}

export const rebootIPIODevice = async (apiData, uuid, deviceName, dispatch) => {
    const uri = ApiEndpoints.rebootIPIOAPI + uuid
    const response = getRequest(uri, apiData, dispatch)
    return response
}

export const resetIPIODevice = async (apiData, deviceUUID, userID, deviceName, dispatch) => {
    const uri = ApiEndpoints.resetDeviceAPI + deviceUUID + '/' + userID
    const response = await getRequest(uri, apiData, dispatch)
    return response;
}

export const copyUsersToAnotherDevice = async (apiData, deviceIDFrom, uipioid, name, userID, dispatch) => {
    const uri = ApiEndpoints.copyUsersAPI;

    var body = {
        'deviceIDFrom': deviceIDFrom,
        'uipioid': uipioid,
        'name': name,
        'userID': userID
    }

    const response = await postRequest(uri, body, apiData, dispatch);
    return response;
}


function getAppName(companyName) {
    switch (companyName) {
        case "EyeSpyFX":
            return 'KNOWIO'

        case "RE:SURE":
            return "RE:SURE I/O"

        case "PARFAS":
            return "Parfas"

        case "DCC":
            return "RE:SURE I/O"

        case "SOLO":
            return "RE:SURE I/O"

        case "PORTAL_SECURITY":
            return "Portal Security"

        case "CPS":
            return "RE:SURE I/O"

        default:
            return "KNOWIO"
    }
}

export const inviteUser = async (apiData, deviceID, userID, role, email, deviceName, companyName, dispatch) => {
    const uri = ApiEndpoints.inviteUserAPI
    const appName = getAppName(companyName);
    const body = { 'deviceID': deviceID, 'userID': userID, "role": role, "email": email, "deviceName": deviceName, "appName": appName };

    console.log(body);
    
    const responseData = await postRequest(uri, body, apiData, dispatch)
    console.log(responseData)
    return responseData
    
}

export const PowerSearchAPI = async (apiData, query, pageSize, pageNumber, online, offline, notag, sort, dispatch) => {
    const uri = ApiEndpoints.powerSearch;

    const body = {
        query,
        pageSize,
        pageNumber,
        online,
        offline,
        notag,
        sort
    }

    const responseData = await postRequest(uri, body, apiData, dispatch);
    return responseData.data;
}

export const UserPowerSearch = async (apiData, searchTerm, dispatch) => {
    const uri = ApiEndpoints.userPowerSearch + searchTerm;

    const responseData = await getRequest(uri, apiData, dispatch);
    return responseData.data;
}



export const getPagedDevicesAPI = async (apiData, pageSize, pageNumber, online, offline, notag, sort, dispatch) => {
    const uri = ApiEndpoints.pagedDevices;
    const body = {
        pageSize,
        pageNumber,
        online,
        offline,
        notag,
        sort
    }
    const responseData = await postRequest(uri, body, apiData, dispatch);
    return responseData.data;
}

export const enableUserNotifications = async (apiData, userID, allow, dispatch) => {

    const uri = ApiEndpoints.enableUserNotificationsAPI
    var body = { 'userID': userID, 'notifications': allow };
    const responseData = await postRequest(uri, body, apiData, dispatch)

    return responseData.data.success
}

export const switchInstallerMode = async (apiData, userID, value, dispatch) => {

    const uri = ApiEndpoints.activateInstallerModeAPI
    var body = { 'userID': userID, 'enabled': value };
    const responseData = await postRequest(uri, body, apiData, dispatch)

    return responseData.data.success
}

export const createPortalPassword = async (email, pin, password) => {

    const uri = ApiEndpoints.createPortalPasswordAPI
    var body = { 'email': email, 'password': password, 'pin': pin };
    const responseData = await postRequestNoAuth(uri, body)

    return responseData
}


export const getHolidaysModesAPI = async (apiData, DeviceUUID) => {
    const uri = ApiEndpoints.getHolidaysModes + DeviceUUID
    const responseData = await getRequest(uri, apiData)

    return responseData.data;
}

export const createHolidayModeAPI = async (apiData, body, dispatch) => {
    const uri = ApiEndpoints.createHolidayMode;
    const responseData = await postRequest(uri, body, apiData, dispatch)

    return responseData;
}

export const editHolidayModeAPI = async (apiData, body, dispatch) => {
    const uri = ApiEndpoints.editHolidayMode;
    const responseData = await putRequest(uri, body, apiData, dispatch)
    return responseData;
}

export const deleteHolidayModeAPI = async (apiData, holidayModeID, dispatch) => {
    const uri = ApiEndpoints.deleteHolidayMode + holidayModeID;
    const responseData = await deleteRequest(uri, apiData, dispatch)
    return responseData;
}

export const getInputRules = async (apiData, DeviceUUID, dispatch) => {
    const uri = ApiEndpoints.getInputRules + DeviceUUID

    const responseData = await getRequest(uri, apiData, dispatch)

    return responseData;
}

export const setInputRuleApi = async (apiData, body, dispatch) => {
    const uri = ApiEndpoints.setInputRule
    const responseData = await postRequest(uri, body, apiData, dispatch)
    return responseData;
}

export const getSchedules = async (apiData, DeviceUUID, dispatch) => {
    const uri = ApiEndpoints.getSchedules + DeviceUUID;
    const responseData = await getRequest(uri, apiData, dispatch)
    return responseData;
}

export const createScheduleApi = async (apiData, body, dispatch) => {
    const uri = ApiEndpoints.getSchedules;
    const responseData = await postRequest(uri, body, apiData, dispatch)
    return responseData;
}


export const updateScheduleApi = async (apiData, body, dispatch) => {
    const uri = ApiEndpoints.getSchedules;
    const responseData = await putRequest(uri, body, apiData, dispatch)
    return responseData;
}

export const deleteScheduleApi = async (apiData, scheduleID, dispatch) => {
    const uri = ApiEndpoints.getSchedules + scheduleID;
    const responseData = await deleteRequest(uri, apiData, dispatch)
    return responseData;
}


export const UpdateDeviceDetails = async (apiData, body, DeviceID, dispatch) => {
    const uri = ApiEndpoints.updateDeviceDetails + DeviceID;
    const responseData = await putRequest(uri, body, apiData, dispatch)

    return responseData;
}

export const UpdateDeviceDetailsExtras = async (apiData, body, DeviceID, dispatch) => {
    const uri = ApiEndpoints.updateDeviceDetailsExtra + DeviceID;
    const responseData = await putRequest(uri, body, apiData, dispatch)

    return responseData;
}

export const GenerateLockOTPAPI = async (apiData, body, dispatch) => {
    const uri = ApiEndpoints.generateLockOTP;
    console.log(apiData)
    console.log(body)
    const responseData = await postRequest(uri, body, apiData, dispatch)
    return responseData;
}

export const removeLockAPI = async (apiData, DeviceUUID, OutputPin, dispatch) => {
    const uri = ApiEndpoints.removeLock + DeviceUUID + "/" + OutputPin;
    const responseData = await deleteRequest(uri, apiData, dispatch)
    return responseData;
}

export const getCamectsAPI = async (apiData, DeviceUUID, dispatch) => {
    const uri = ApiEndpoints.getCamects + DeviceUUID;
    const responseData = await getRequest(uri, apiData, dispatch)
    return responseData;
}


export const addCamectAPI = async (apiData, body, dispatch) => {
    const uri = ApiEndpoints.addCamect;
    const responseData = await postRequest(uri, body, apiData, dispatch);
    return responseData;
}

export const deleteCamectAPI = async (apiData, DeviceUUID, camectID, dispatch) => {
    const uri = ApiEndpoints.deleteCamect + DeviceUUID + "/" + camectID;
    const responseData = await getRequest(uri, apiData, dispatch)
    return responseData;
}

export const updateCamectAPI = async (apiData, body, dispatch) => {
    const uri = ApiEndpoints.updateCamect;
    const responseData = await postRequest(uri, body, apiData, dispatch)
    return responseData;
}

export const updateCamectRelationshipAPI = async (apiData, body, dispatch) => {
    const uri = ApiEndpoints.updateCamectRelationship;
    const responseData = await postRequest(uri, body, apiData, dispatch)
    return responseData;
}

export const getCamectCameras = async (apiData, deviceUUID, camectID) => {
    const uri = ApiEndpoints.getCamectCameras + deviceUUID + "/" + camectID
    const responseData = await getRequest(uri, apiData)
    return responseData;
}

export const getVirtualRelationshipsAPI = async (apiData, deviceUUID) => {
    const uri = ApiEndpoints.getVirtualRelationships + deviceUUID;
    const responseData = await getRequest(uri, apiData);
    return responseData.data;
}

export const addSigfoxAPI = async (apiData, body, dispatch) => {
    const uri = ApiEndpoints.addSigfox;
    const responseData = await postRequest(uri, body, apiData, dispatch)
    return responseData;
}

export const unlinkSigfoxAPI = async (apiData, deviceUUID, pin, dispatch) => {
    const uri = ApiEndpoints.unlinkSigfox + deviceUUID + "/" + pin;
    const responseData = await getRequest(uri, apiData, dispatch);
    return responseData.data;
}

export const getGroups = async (apiData, dispatch) => {
    const uri = ApiEndpoints.getGroups;
    const response = await getRequest(uri, apiData, dispatch);
    console.log(response.data[0].groups)
    return response.data[0].groups;
}

export const addGroup = async (apiData, body, dispatch) => {
    const uri = ApiEndpoints.addGroup;
    const response = await postRequest(uri, body, apiData, dispatch)
    return response;
}

export const deleteGroup = async (apiData, groupID, dispatch) => {
    const uri = ApiEndpoints.deleteGroup + groupID;
    const response = await deleteRequest(uri, apiData, dispatch);
    return response;
}

export const renameGroup = async (apiData, body, dispatch) => {
    const uri = ApiEndpoints.renameGroup;
    const response = await putRequest(uri, body, apiData, dispatch)
    return response;
}

export const getGroupSites = async (apiData, groupName, dispatch) => {
    const uri = ApiEndpoints.getGroupSites + groupName
    const response = await getRequest(uri, apiData, dispatch);
    return response.data[0];
}

export const addSitesToGroup = async (apiData, body, dispatch) => {
    const uri = ApiEndpoints.addSitesToGroup;
    const response = await postRequest(uri, body, apiData, dispatch);
    return response;
}

export const removeSitesFromGroup = async (apiData, body, dispatch) => {
    const uri = ApiEndpoints.removeSitesFromGroup;
    const response = deleteRequestWithBody(uri, body, apiData, dispatch);
    return response;
}

export const addUserToGroup = async (apiData, body, dispatch) => {
    const uri = ApiEndpoints.addUserToGroup;
    const response = postRequest(uri, body, apiData, dispatch);
    return response;
}

export const getGroupUsers = async (apiData, groupID, dispatch) => {
    const uri = ApiEndpoints.getGroupUsers + groupID;
    const response = getRequest(uri, apiData, dispatch);
    return response;
}

export const deleteGroupUser = async (apiData, userID, dispatch) => {
    const uri = ApiEndpoints.deleteGroupUser + userID;
    const response = deleteRequest(uri, apiData, dispatch);
    return response;
}

export const getGroupStats = async (apiData, groupID, dispatch) => {
    const uri = ApiEndpoints.getGroupStats + groupID;
    const response = await getRequest(uri, apiData, dispatch);
    return response;
}

export const getIglooLocks = async (apiData, dispatch) => {
    const uri = ApiEndpoints.getPadlocks;
    const response = getRequest(uri, apiData, dispatch);
    return response;
}

export const getKeyboxes = async (apiData, dispatch) => {
    const uri = ApiEndpoints.getKeyboxes;
    const response = getRequest(uri, apiData, dispatch);
    return response;
}

export const getSensors = async (apiData, dispatch) => {
    const uri = ApiEndpoints.getSensors;
    const response = getRequest(uri, apiData, dispatch);
    return response;
}

export const getAllUsersPaged = async (apiData, pageSize, pageNumber, dispatch) => {
    const uri = ApiEndpoints.getAllUsersPaged;
    const body = {
        pageSize,
        pageNumber
    }
    const responseData = await postRequest(uri, body, apiData, dispatch)
    return responseData.data
}

export const getRandomUIPIOID = async (apiData, dispatch) => {
    const uri = ApiEndpoints.getRandomUIPIOID;
    const response = getRequest(uri, apiData, dispatch);
    return response;
}

export const getDashboardStats = async (apiData, dispatch) => {
    const uri = ApiEndpoints.getDashboardStats;
    const response = getRequest(uri, apiData, dispatch);
    return response;
}

export const getPadlockPincodes = async (apiData, lockID, dispatch) => {
    const uri = `https://ipio.m2mservices.mobi/api/v3.0/igloo/lock/${lockID}/pincodes`
    const response = getRequest(uri, apiData, dispatch)
    return response;
}

export const deleteUserAccount = async () => {
    
}

export const linkGateway = async (apiData, gatewayDetails, name, dispatch) => {
    const uri = ApiEndpoints.linkGateway
    const body = {
        siteUUID: gatewayDetails.siteUUID, 
        gatewayIMEI: gatewayDetails.gatewayIMEI, 
        name: gatewayDetails.name
    }
    const response = postRequest(uri, body, apiData, dispatch)
    return response;
}

export const unlinkGateway = async (apiData, siteUUID, gatewayID, dispatch) => {
    const uri = ApiEndpoints.unlinkGateway
    const body = {
        siteUUID: siteUUID, 
        gatewayID: gatewayID
    }
    const response = postRequest(uri, body, apiData, dispatch)
    return response;
}

export const getGateways = async (apiData, dispatch) => {
    const uri = ApiEndpoints.getGateways
    const response = getRequest(uri, apiData, dispatch)
    //console.log(response.data[0])
    return response;
}

export const createNewPin = async (body, dispatch) => {
    const uri = ApiEndpoints.createNewPin
    console.log(body)
    const response = postRequestNoAuth(uri, body)
    return response;
} 

export const acceptInvite = async (body) => {
    const uri = ApiEndpoints.acceptInvite
    const response = postRequestNoAuth(uri, body)
    return response //true/false + appName
}

export const changePortalPassword = async (body) => {
    const uri = ApiEndpoints.changePortalPassword
    const response = postRequestNoAuth(uri, body)
    return response
}



