import { useDispatch, useSelector } from "react-redux";
import { getGateways, getSensors } from "../../Api Methods/Api";
import { useEffect, useState } from "react";
import { Await } from "react-router-dom";
import PadlocksList from "../Padlocks/padlocksList";
import DeviceLoadingGrid from "../../components/GridList/DeviceLoadingGrid";
import Stats2 from "../../components/UI/Stats/Stats2";

const Gateways = () => {

    const dispatch = useDispatch();
    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const apiData = {
        token,
        client
    }

    const [gateways, setGateways] = useState([])
    const [update, setUpdate] = useState(0)
    const filtering = ""
    const [isLoading, setIsLoading] = useState(true)

    //Pull data on mount
    useEffect(() => {
        const HandleGetGateways = async () => {
            const response = await getGateways(apiData, dispatch);
            
            //setGateways(response.data[0])
            //setIsLoading(false)
        }

        HandleGetGateways();
    }, [update])


    return (
        <Await resolve={gateways}>
                <div style={{ width: "100%" }}>
                    <Stats2 title={"Total gateways"} value={gateways.length}  isLoadingStats={isLoading} isStatic={true} />
                    <br></br>
                    {isLoading ? (
                        <div style={{ display: "flex", width: "100%" }}>
                            <DeviceLoadingGrid
                                CardCount={20}
                            />
                        </div>
                        ) : (
                            <PadlocksList 
                                locks={gateways}
                                filtering={filtering}
                                update={update}
                                setUpdate={setUpdate}
                                isGateways={true}
                            />
                        )
                    }
                </div>
           
        </Await>
    );
}


export default Gateways;