import React, { useEffect, useState } from "react";
import { getKeyboxes } from "../../Api Methods/Api";
import { useDispatch, useSelector } from "react-redux";
import { useScrollTrigger } from "@mui/material";
import { Await } from "react-router-dom";
import PadlocksList from "../Padlocks/padlocksList";
import DeviceLoadingGrid from "../../components/GridList/DeviceLoadingGrid";
import Stats2 from "../../components/UI/Stats/Stats2";

const Keyboxes = () => {

    const dispatch = useDispatch();
    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const apiData = {
        token,
        client
    }

    const [keyboxes, setKeyboxes] = useState([])
    const [update, setUpdate] = useState(0)
    const filtering = ""
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const HandleGetKeyboxes = async () => {
            const response = await getKeyboxes(apiData, dispatch);
            setKeyboxes(response.data[0]);
            setIsLoading(false)
        }

        HandleGetKeyboxes();

    }, [update]) 

    return (
        
            <Await resolve={keyboxes}>
                {}
                <div style={{ width: "100%" }}>
                    <Stats2 title={"Total keyboxes"} value={keyboxes.length}  isLoadingStats={isLoading} isStatic={true} />
                    <br></br>
                    {isLoading ? (
                        <div style={{ display: "flex", width: "100%" }}>
                            <DeviceLoadingGrid
                                CardCount={20}
                            />
                        </div>
                        ) : (
                            <PadlocksList 
                                locks={keyboxes}
                                filtering={filtering}
                                update={update}
                                setUpdate={setUpdate}
                                isKeyboxes={true}
                            />
                        )
                    }
                </div>
        </Await>
        
    )
}

export default Keyboxes;