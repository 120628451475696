import React, { useState, useReducer, useRef, useEffect } from "react";

import FormButton from "../../Button/FormButton/FormButton";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import classes from "./Register2.module.css"
import classes2 from './Login.module.css';

import { LoginHandler } from "../../../../store/auth-actions";
import FormCard from "./FormCard/FormCard";
import Input1 from "../../Inputs/Input1";
import { createAppAccount, createPortalPassword } from "../../../../Api Methods/Api";
import Modal from "../../../Modal/Modal";
import check from '../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/check.png'
import cross from '../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/cross.png'
import Button1 from "../../Button/Button1";

const emailReducer = (state, action) => {

    if (action.type === 'USER_INPUT') {
        return { value: action.val, isValid: action.val.includes('@') };
    }

    if (action.type === 'INPUT_BLUR') {
        return { value: state.value, isValid: state.value.includes('@') };
    }

    if (action.type === 'INPUT_CLEAR') {
        return { value: action.val, isValid: state.value.includes('@') };
    }
    //otherwise 
    return { value: '', isValid: false };
}

const appPinReducer = (state, action) => {
    if (action.type === 'USER_INPUT') {
        return { value: action.val, isValid: action.val.trim().length >= 4 };
    }

    if (action.type === 'INPUT_BLUR') {
        return { value: state.value, isValid: state.value.trim().length >= 4 };
    }

    if (action.type === 'INPUT_CLEAR') {
        return { value: action.val, isValid: state.value.includes('@') };
    }
    return { value: '', isValid: false };
};



const passwordReducer = (state, action) => {
    if (action.type === 'USER_INPUT') {
        return { value: action.val, isValid: action.val.trim().length >= 4 };
    }

    if (action.type === 'INPUT_BLUR') {
        return { value: state.value, isValid: state.value.trim().length >= 4 };
    }

    if (action.type === 'INPUT_CLEAR') {
        return { value: action.val, isValid: state.value.includes('@') };
    }
    return { value: '', isValid: false };
};

const reEnterpasswordReducer = (state, action) => {
    if (action.type === 'USER_INPUT') {
        return { value: action.val, isValid: action.val.trim().length >= 4 };
    }

    if (action.type === 'INPUT_BLUR') {
        return { value: state.value, isValid: state.value.trim().length >= 4 };
    }

    if (action.type === 'INPUT_CLEAR') {
        return { value: action.val, isValid: state.value.includes('@') };
    }
    return { value: '', isValid: false };
};

const Register2 = (props) => {
    const [formIsValid, setFormIsValid] = useState(false);


    const [emailState, dispatchEmail] = useReducer(emailReducer, {
        value: '',
        isValid: null
    });

    const [appPinState, dispatchAppPin] = useReducer(appPinReducer, {
        value: '',
        isValid: null
    });

    const [passwordState, dispatchPassword] = useReducer(passwordReducer, {
        value: '',
        isValid: null
    });

    const [reEnterPasswordState, dispatchReEnterPassword] = useReducer(reEnterpasswordReducer, {
        value: '',
        isValid: null
    });

    const emailInputRef = useRef()
    const appPinInputRef = useRef();
    const passwordInputRef = useRef();
    const reEnterpasswordInputRef = useRef();


    const { isValid: emailIsValid } = emailState;
    const { isValid: appPinIsValid } = appPinState;
    const { isValid: passwordIsValid } = passwordState;
    const { isValid: reEnterPasswordIsValid } = reEnterPasswordState;


    const ModalDialog = useRef();
    const [title, setTitle] = useState('');
    const [modalComponent, setModalComponent] = useState();

    const HandleShowModal = () => {
        ModalDialog.current.open();
    };

    const HandleCloseModal = () => {
        ModalDialog.current.close();
    };

    useEffect(() => {
        const identifier = setTimeout(() => {
            setFormIsValid((emailIsValid && appPinIsValid && passwordIsValid && reEnterPasswordIsValid) && (passwordState.value === reEnterPasswordState.value))
        }, 500);

        return () => {
            // console.log('CLEANUP');
            clearTimeout(identifier);
        };
    }, [emailIsValid, appPinIsValid, passwordIsValid, reEnterPasswordIsValid, passwordState, reEnterPasswordState]);


    const emailChangeHandler = (event) => {
        dispatchEmail({ type: 'USER_INPUT', val: event.target.value });
    }

    const appPinChangeHandler = (event) => {
        dispatchAppPin({ type: 'USER_INPUT', val: event.target.value });
    }

    const passwordChangeHandler = (event) => {
        dispatchPassword({ type: 'USER_INPUT', val: event.target.value });
    }

    const reEnterpasswordChangeHandler = (event) => {
        dispatchReEnterPassword({ type: 'USER_INPUT', val: event.target.value });
    }


    const validateEmailHanlder = () => {
        dispatchEmail({ type: 'INPUT_BLUR' });
    }

    const validateAppPindHanlder = () => {
        dispatchAppPin({ type: 'INPUT_BLUR' });
    }


    const validatePasswordHanlder = () => {
        dispatchPassword({ type: 'INPUT_BLUR' });
    }

    const validatereEnterPasswordHandler = (event) => {
        dispatchReEnterPassword({ type: 'INPUT_BLUR' });
    }

    const onClearHandler = () => {
        dispatchEmail({ type: 'INPUT_CLEAR', val: "" });
        dispatchAppPin({ type: 'INPUT_CLEAR', val: "" });
        dispatchPassword({ type: 'INPUT_CLEAR', val: "" });
        dispatchReEnterPassword({ type: 'INPUT_CLEAR', val: "" });
    }

    const navigate = useNavigate();
    const dispatch = useDispatch();


    const submitHandler = (event) => {
        event.preventDefault();

        //if (formIsValid) {

            const Email = emailState.value;
            const AppPin = appPinState.value;
            const Password = passwordState.value;
            const ReEnterPassword = reEnterPasswordState.value;

            if (Email !== "" && AppPin !== "" && Password !== "" && ReEnterPassword !== ""){
                if (Password == ReEnterPassword){

                    createPortalPassword(Email, AppPin, Password).then((response) => {
                        if (response.data.success) {
                            onClearHandler();
                            showPopup("Success", false, "")
                            navigate("/");
                        } else {
                            let newMessage = response.data.msg
                            if (response.data.msg == "Portal password already created"){
                                newMessage = "Portal account already created, please log in."
                            } else if (response.data.msg == "Incorrect PIN"){
                                newMessage = "Incorrect app pin entered, please try again"
                            } else if (response.data.msg = "Email not found"){
                                newMessage = "Could not find an app account associated with that email address, please check your details and try again."
                            } else if (response.data.msg == "Account does not have portal access"){
                                newMessage = "Account not authorised to access Portal. Please contact your administrator."
                            }

                            showPopup("Error", true, newMessage)
                            
                        }
                    })
                    return;
                } else {
                    showPopup("Error", true, "Passwords do not match, please check and try again")
                }
            } else {
                showPopup("Error", true, "Please complete all required fields")
            }

        // } else if (!emailIsValid) {
        //     emailInputRef.current.focus();
        // }
        // else if (!passwordIsValid) {
        //     passwordInputRef.current.focus();
        // } else {
        //     reEnterpasswordInputRef.current.focus();
        // }
    };

    const navigateToLogin = () => {
        HandleCloseModal()
        navigate('/')
    }

    const showPopup = (title, fail, message) => {
        setTitle(title)
        setModalComponent(
            <div style={{ 
                display: "flex", 
                flexDirection: "column", 
                alignItems: "center", 
                justifyContent: "center", 
                maxWidth: "800px", 
                padding: "20px"
            }}>
              {fail && <img className={classes.ModalIcon} src={cross}/>}
              {!fail && <img className={classes.ModalIcon} src={check}/>}
    
              <div style={{ textAlign: "center" }}>
                {!fail && <h1 className={classes.title}>Your account has been created! 🎉</h1> }
                {!fail && <p className={classes.subTitle}>You can now access the Portal</p> }
    
                {fail && <h1 className={classes.title}>Error creating your account</h1> }
                {fail && <p className={classes.subTitle}>{message}</p> }
              </div>   

              {(fail && message == "Portal account already created, please log in.") && <Button1 style={{ width: "80%", marginTop: "20px" }} onClick={navigateToLogin}>Continue to login</Button1>}
              {fail && <Button1 style={{ width: "80%", marginTop: "20px" }} onClick={HandleCloseModal}>Close</Button1> }
              {!fail && <Button1 style={{ width: "80%", marginTop: "20px" }} onClick={navigateToLogin}>Continue to login</Button1> }
            </div>
        )
        HandleShowModal()
    }
    

    return (
        <>
        <Modal ref={ModalDialog} title={title} modalName={""} HandleShowModal={HandleShowModal}>
                {modalComponent}
        </Modal>
        
        <div className={`${classes.card} ${props.className}`}>
            <h2 className={classes2.Title}>{props.title || "Activate account"}</h2>

            <p className={classes2.subInfo}>{props.subInfo || "Please enter your details to active your account"}</p>

            <form onSubmit={submitHandler} className={classes2["container"]} >
                <ul className={classes2["inputs-list"]}>

                    <li>
                        <Input1
                            placeholder="Email"
                            ref={emailInputRef}
                            id="email"
                            type={"email"}
                            isValid={emailState.isValid}
                            value={emailState.value}
                            onChange={emailChangeHandler}
                            onBlur={validateEmailHanlder}
                        />
                    </li>

                    <li>
                        <Input1
                            placeholder="Pin used on app"
                            ref={appPinInputRef}
                            id="appPin"
                            type={"text"}
                            isValid={appPinState.isValid}
                            value={appPinState.value}
                            onChange={appPinChangeHandler}
                            onBlur={validateAppPindHanlder}
                        />
                    </li>

                    <li>
                        <Input1
                            placeholder="Create a portal password"
                            ref={passwordInputRef}
                            id="password"
                            type={"password"}
                            isValid={passwordState.isValid}
                            value={passwordState.value}
                            onChange={passwordChangeHandler}
                            onBlur={validatePasswordHanlder}
                        />
                    </li>

                    <li>
                        <Input1
                            placeholder="Confirm password"
                            ref={reEnterpasswordInputRef}
                            id="reEnterpassword"
                            type={"password"}
                            isValid={reEnterPasswordState.isValid}
                            value={reEnterPasswordState.value}
                            onChange={reEnterpasswordChangeHandler}
                            onBlur={validatereEnterPasswordHandler}
                        />
                    </li>
                </ul>


                <FormButton value={props.buttonText || "Activate"} type="submit" />
            </form>

            <div>
                <ul className={classes["extraLinks-list"]}>
                    {(props.extraLinks).map((extraLink, index) => (
                        <li className={classes.listItem} key={index}>
                            <h4 className={classes.extraLink}>{extraLink.LinkText || ""}</h4> <Link to="/" className={classes.link}>{extraLink.LinkTextBold}</Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div >
        </>
    );
}

export default Register2;

