import React, { createContext, useState, useEffect } from "react";

export const VersionContext = createContext();

export const VersionProvider = ({ children }) => {
    const [newVersionAvailable, setNewVersionAvailable] = useState(false);
    const currentBuildNumber = "2.0.0";
    //temp for testing
    const newestBuildNumber = "3.0.0"
    
    useEffect(() => {
        const checkForUpdate = async () => {
            try {
                //Will be API when complete
                //const response = await fetch("/version.json", { cache: "no-store" }); // Prevent browser caching
                //const data = await response.json();

                // if (data.buildNumber !== currentBuildNumber) {
                //     setNewVersionAvailable(true);
                // }

                //To test
                // if (currentBuildNumber !== newestBuildNumber){
                //     setNewVersionAvailable(true)
                // }
                
            } catch (error) {
                console.error("Error checking for new version:", error);
            }
        };

        // Check every 5 minutes (300,000ms)
        const interval = setInterval(checkForUpdate, 300000);
        checkForUpdate(); // Initial check

        return () => clearInterval(interval);
    }, []);

    return (
        <VersionContext.Provider value={{ newVersionAvailable }}>
            {children}
        </VersionContext.Provider>
    );
};
