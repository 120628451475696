import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { Provider } from 'react-redux'
import store from './store/index'
import { VersionProvider } from './VersionControl/VersionContext';

const Root = () => {
  return (
    <Provider store={store}>
      <VersionProvider>
        <MemoizedApp/>
      </VersionProvider>
    </Provider>
  );
}

const MemoizedApp = React.memo(App);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Root />);

